import { useEffect, useReducer, useState } from "react";
import { Link } from "react-router-dom";
import DateTimePicker from 'react-datetime-picker';
import { commonConfirm, commonMsg, EventInput, EventInputCustom, FormReducer, generateKey, GetIcon, getImage } from "../../utils/commons";
import { listShops } from "../../services/admin/Shop";
import { listCategories } from "../../services/admin/Category";
import { searchBrand } from "../../services/admin/brands";
import Accordion from "@mui/material/Accordion";
import { AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { CropImage } from "../../utils/cropImage";
import { addCoupon, editCoupon, getListCoupons, removeCoupon } from "../../services/admin/commons";
import { useForm } from "react-hook-form";
import { InputItem } from "../commons/FormITems/InputItem";
import { DropItem } from "../commons/FormITems/DropItem";
import { TextAreaItem } from "../commons/FormITems/TextAreaItem";
import { start } from "repl";
import { TCategory, TShop } from "../../interfaces/IShop";
import { IMessage, IMessageConfirm } from "../../interfaces/ICommons";
import { ImageItem } from "../commons/FormITems/ImageItem";

type TCoupon = {
    _id: string,
    image:string,
    imageRaw:[],
    applicable:string,
    description: string,
    descriptionEs: string,
    code:string,
    status:boolean,
    typeCoupon:string,
    typeUser:string,
    value:number,
    amountCoupon:number,
    amountProducts?:number
    start:Date,
    end:Date,
    users?:[],
    shops?:[],
    categories?:[],
    brands?:[],
};

export const errorsFormCoupon = {
    code:"You must add a Code",
    value:"You must add a Code",
    description:"You must add a Description",
    imageRaw:"You must add an Image",
}

const listType = ["percent", "amount",]; //free:only delivery
const listapply = ["product", "bundle",]; // delivery

const messageAlert:IMessage={
    icon:'info',
    title:'',
    text:''
}

const messageConfirm:IMessageConfirm={
    icon:'info',
    title:'',
    text:'',
    callback:()=>{}
}

export const CouponsComponents = ()=>{

    const {register,handleSubmit,formState:{errors},setValue, getValues,reset,watch  } = useForm<TCoupon>();
    const {register:regEdit,handleSubmit:handEdit,formState:{errors:errorsEdit},setValue:setValEdit,getValues:getValEdit, reset:rsEdit,watch:wtEdit } = useForm<TCoupon>();
    const [applicable,setApplicable] = useState();
    const [imgUpload,setImgUpload] = useState<any>();
    const [imgPreview,setImgPreview] = useState<string>();
    const [currentCroppedImg,setCurrentCroppedImg] = useState<any>();
    const [textSearch,setTextSearch] = useState<string>("")    
    const [textSearchEdit,setTextSearchEdit] = useState<string>("")    
    const [listShopsData,setListShopsData] = useState<TShop[]>([]);
    const [listShopsDataEdit,setListShopsDataEdit] = useState<TShop[]>([]);
    const [listCategoriesData,setListCategories] = useState<TCategory[]>([]);
    const [listCategoriesDataEdit,setListCategoriesEdit] = useState<TCategory[]>([]);
    const [listCategoryFilter,setListCategoryFilter] = useState<TCategory[]>([]);
    const [listCategoriesBase,setListCategoriesBase] = useState<any>([]);
    const [listBrandsSearch,setListBrandsSearch] = useState<any>([]);
    const [listBrandsSearchEdit,setListBrandsSearchEdit] = useState<any>([]);
    const [listBrandsShow,setListBrandsShow] = useState<any>([]);
    const [listBrandsShowEdit,setListBrandsShowEdit] = useState<any>([]);
    const [expanded,setExpanded ] = useState<string|false>(false);
    const [listCoupons,setListCoupons] = useState<any>([]);
    const [editCouponView,setEditCouponView] = useState<boolean>(false);
    const [imgPreviewEdit,setImgPreviewEdit] = useState<string>();
    const [editCroppedImg,setEditCroppedImg] = useState<any>(null);

    const initData = async ()=>{

        console.log("select type ");
    
        setValue("typeCoupon",listType[0])
        setValue("applicable",listapply[0])
        setValue("typeUser",listType[0])
        setValue("start",new Date())
        setValue("end",new Date())
        setValue("shops",[])
        setValue("categories",[])
        setValue("status",false)

        const rawShops = await listShops();
        const rawCagetories = await listCategories();
        const couponsRaw = await getListCoupons();

        setListShopsData(rawShops.data);
        setListShopsDataEdit(rawShops.data)
        setListCategoriesBase(rawCagetories.data)
        setListCategories(rawCagetories.data)
        setListCategoriesEdit(rawCagetories.data);
        setListCoupons(couponsRaw.data)

        register("imageRaw",{required:true})
    }

    const loadCoupons = async ()=>{
        const couponsRaw = await getListCoupons();
        setListCoupons(couponsRaw.data)

    }

    const changeImages = (data:any)=>{ setValue("imageRaw",data[0]) }
    const changeImagesUpdate = (data:any)=>{ setValEdit("imageRaw",data[0]) }

    const closeModal = ()=>{
        setImgUpload('');
    }

    const changeVisibility = (event:any)=>{ setValue("status",event.target.checked); }

    const updateFormCouponDate = (name:string,date:any)=>{

        if(name==="start"){ setValue("start",date) }
        else if(name==="end"){ setValue("end",date) }
    }

    const changeCategoriesByShop = (event:any)=>{

        let listBase:TShop[] = [...listShopsData];
        let listPro:any = getValues("shops") || [];
        const currentCategories:TCategory[] = [...listCategoriesData];
        const value =  event.target.value;
        const indexShop = listBase.findIndex((iShop:any)=>iShop._id===value); 

        if(event.target.checked){ 
            listPro.push(listBase[indexShop]._id);
            listBase[indexShop].checked = true;
        }else{
            const iShopFilter = listPro.findIndex((itemSel:string)=>itemSel===listBase[indexShop]._id);

            if(iShopFilter>-1){
                listPro.splice(Number(iShopFilter) ,1);
                listBase[indexShop].checked = false;
            }
        }

        let listView:TCategory[] = [];

        for(let indexSh in listBase){
            const itemShop = listBase[indexSh];

            if(itemShop.checked){

                for(let iCat in currentCategories )
                {   
                    const item:TCategory = currentCategories[iCat];
                    const currentIdex = listView.findIndex(lItem=>lItem.name===item.name)

                    if(item.type===itemShop.name && currentIdex === -1 ){ 
                        listView.push({
                            _id: item._id,
                            name: item.name,
                            nameEs: item.nameEs,
                            size: item.size,
                            type: item.type,
                            image: item.image
                    })  }
                }//* /
            }
        }

        setValue("shops",listPro)
        setListShopsData([...listBase]);
        setListCategoryFilter([...listView])
        setValue("categories",[]); 
        /////////
        /*const value = event.target.value;
        //onst itemSelected = listShopsData.filter((itemSh:any)=>itemSh._id===value);

        console.log(formCoupon);

        let listPro = [...formCoupon.shops]
        let listCatPro:any = [];

        let filterSh = listShopsData.findIndex((itemShG:any)=>itemShG._id === value  );

        if(event.target.checked)
        {
            listPro.push(value);
            listShopsData[filterSh].check = true;
        }else{

            for(let index in listPro)
            {
                let item = listPro[index];
                
                if(item===value)
                { 
                    listPro.splice(Number(index) ,1); 
                    listShopsData[filterSh].check = false;
                }
            }
        }

        if(listPro.length>0)
        {
            
            for(let indexSh in listPro)
            {
                const itemSh =  listShopsData.filter((itemShr:any)=>itemShr._id===listPro[indexSh])  ;

                console.log(" item sh algo ",itemSh);
                
                for(let indexCg in listCategoriesData )
                {
                    const itemCg = listCategoriesData[indexCg];
                    
                    if(itemCg.type===itemSh[0].name)
                    {
                        let indexFCg = listCatPro.findIndex((itemfcg:any)=>itemfcg.name===itemCg.name) 
                        
                        if(indexFCg<0){ listCatPro.push(itemCg); }
                    }
                }
            }

            setListCategoriesShow(listCatPro);
        }else{ setListCategoriesShow(listCategoriesData) }



        formCoupon.shops = listPro;//*/
        
    }

    const changeCategoryEvent =(event:any)=>{
        
        const listBase:any =  listCategoryFilter;
        const listPro:any = getValues("categories") || [];
        let value = event.target.value;

        let indexData = listBase.findIndex((item:any)=>item._id===event.target.value);
        
        if(event.target.checked)
        {
            listPro.push(listBase[indexData]._id);
            listBase[indexData].checked = true;
            
        }else{

            const iCatgFilter = listPro.findIndex((itemSel:string)=>itemSel===listBase[indexData].name);

            if(iCatgFilter>-1){
                listPro.splice(Number(iCatgFilter) ,1);
                listBase[iCatgFilter].checked = false;
            }
        }

        setValue("categories",listPro)
        setListCategoryFilter(listBase)
    }    

    const searchBrandWord = async (event:any)=>{

        const word = event.target.value;

        if(word.length>0){
            const result = await searchBrand(word);
            setListBrandsSearch(result);
        }else{ setListBrandsSearch([]); }

        setTextSearch(word);
    } 

    const selectedBrandItem = (item:any) =>{
        
        let brandlistPro:any = listBrandsSearch;
        let brandlistShow:any = listBrandsShow;
        let localBrands:any = getValues("brands") || [];

        const index = brandlistPro.findIndex((itemLocal:any)=>itemLocal._id===item._id);

        console.log(" index::::  ",index);


        if(index>-1)
        { 
            brandlistShow.push(item)
            localBrands.push(item._id)
            setListBrandsShow(brandlistShow)
            setValue("brands",localBrands)
        }

        console.log(" brand list ",{item,brandlistPro});

        setListBrandsSearch([]);
        setTextSearch("");
    }

    const removeBrandItem = (item:any)=>{

        let localBrands:any = listBrandsShow;
        let listBrands:any = getValues("brands");


        const index = localBrands.findIndex((itemLocal:any)=>itemLocal._id===item._id);

        if(index>-1){
            localBrands.splice(index,1);
            listBrands.splice(index,1);
            setListBrandsShow([...localBrands]);
            setValue("brands",listBrands)
        }
    }


    const sendCoupon = async (data:any)=>{

        console.log(" send data form:::::: ",data)
    
        const form = new FormData();
        form.append("image",data.imageRaw.file);
        form.append("code", data.code);
        form.append("description", data.description);
        form.append("descriptionEs", data.descriptionEs);
        form.append("status", data.status);
        form.append("typeCoupon", data.typeCoupon);
        form.append("typeUser", "all");
        form.append("value", data.value);
        form.append("amountCoupon", data.amountCoupon);
        form.append("applicable", data.applicable);
        form.append("shops", JSON.stringify( data.shops));
        form.append("categories", JSON.stringify( data.categories));
        form.append("brands", JSON.stringify( data.brands));
        form.append("start", data.start);
        form.append("end", data.end);

        if(data.applicable==="bundle" ){ form.append("amountProducts", data.amountProducts); }

        const alertMsg = messageAlert;

        addCoupon(form)
        .then(data=>{
            console.log(" add coupon")
            const alertMsg = messageAlert;

            alertMsg.icon = 'success';
            alertMsg.title = 'Coupon Added';
            alertMsg.text = 'Has been successfully add';

            commonMsg(alertMsg);

            loadCoupons();
        })
        .catch(error=>{
            alertMsg.icon = 'error'; 
            alertMsg.title = 'Coupon don´t Added'; 
            alertMsg.text = 'It was not possible to add'; 

            commonMsg(alertMsg);
        });//*/
    }

    const showEditCoupon = (data:any)=>{

        console.log("data",{categories:data.categories});
        const currentCategories = [...listCategoriesBase]; 
        let listView:TCategory[] = [];
        const listShop =  [];
        const listBase:any =  data.shops;
        const listCatBase = data.categories;

        for(let iShop in listShopsData)
        {
            const item = listShopsData[iShop];
            item.checked = false;
            const inShopEdit = listBase.findIndex((IShopE:any)=>IShopE.name===item.name);

            if(inShopEdit>-1){ item.checked = true; }
            
            listShop.push(item);
        }

        for(let indexSh in listBase){

            const itemShop = listBase[indexSh];

            for(let iCat in currentCategories ){   
                    const item:TCategory = currentCategories[iCat];
                    let checked = false
                    const currentIdex = listView.findIndex(lItem=>lItem._id===item._id)

                    const inCat =  listCatBase.findIndex((catItem:any)=>catItem._id===item._id);

                    if(inCat>-1){ checked = true; }

                    if(item.type===itemShop.name && currentIdex === -1 ){ 
                        listView.push({
                            _id: item._id,
                            name: item.name,
                            nameEs: item.nameEs,
                            size: item.size,
                            type: item.type,
                            image: item.image,
                            checked:checked
                        })  
                    }
                }
        }

        setValEdit("_id",data._id)
        setValEdit("description",data.description)
        setValEdit("code",data.code)
        setValEdit("status",data.status)
        setValEdit("typeCoupon",data.typeCoupon)
        setValEdit("typeUser",data.typeUser)
        setValEdit("value",data.value)
        setValEdit("amountCoupon",data.amountCoupon)
        setValEdit("applicable",data.applicable)
        setValEdit("shops",data.shops)
        setValEdit("categories",data.categories)
        setValEdit("brands",data.brands)
        setValEdit("start",new Date(data.start))
        setValEdit("end",new Date(data.end))

        setEditCouponView(!editCouponView);
        setListShopsDataEdit(listShop);
        setListCategoriesEdit([...listView])
        setListBrandsShowEdit([...data.brands])
        //setListBrandsSearchEdit([]);
    };

    const searchBrandWordEdit = async (event:any)=>{

        const word = event.target.value;

        if(word.length>0){
            const result = await searchBrand(word);
            setListBrandsSearchEdit(result);
        }else{ setListBrandsSearchEdit([]); }

        setTextSearchEdit(word);
    } 

    const selectedBrandItemEdit = (item:any) =>{
        
        let brandlistPro:any = listBrandsSearchEdit;
        let brandlistShow:any = listBrandsShowEdit;
        let localBrands:any = getValEdit("brands") || [];

        const index = brandlistPro.findIndex((itemLocal:any)=>itemLocal._id===item._id);

        console.log(" index::::  ",index);


        if(index>-1)
        { 
            brandlistShow.push(item)
            localBrands.push(item._id)
            setListBrandsShowEdit(brandlistShow)
            setValEdit("brands",localBrands)
        }

        setListBrandsSearchEdit([]);
        setTextSearchEdit("");
    }

    const hiddenEditCoupon = ()=>{
        setImgPreviewEdit("");
        setEditCouponView(!editCouponView)
    };

    const changeVisibilityEdit = (event:any)=>{ setValEdit("status",event.target.checked); }

    const updateFormCouponDateEdit = (name:string,date:any)=>{

        if(name==="start"){ setValEdit("start",date) }
        else if(name==="end"){ setValEdit("end",date) }
    }

    const changeCategoriesByShopEdit = (event:any)=>{

        let listBase:TShop[] = [...listShopsDataEdit];
        let listPro:any = getValEdit("shops") || [];
        const currentCategories:TCategory[] = [...listCategoriesDataEdit];
        const value =  event.target.value;
        const indexShop = listBase.findIndex((iShop:any)=>iShop._id===value); 

        if(event.target.checked){ 
            listPro.push(listBase[indexShop]._id);
            listBase[indexShop].checked = true;
        }else{
            const iShopFilter = listPro.findIndex((itemSel:string)=>itemSel===listBase[indexShop]._id);

            if(iShopFilter>-1){
                listPro.splice(Number(iShopFilter) ,1);
                listBase[indexShop].checked = false;
            }
        }

        let listView:TCategory[] = [];

        for(let indexSh in listBase){
            const itemShop = listBase[indexSh];

            if(itemShop.checked){

                for(let iCat in currentCategories )
                {   
                    const item:TCategory = currentCategories[iCat];
                    const currentIdex = listView.findIndex(lItem=>lItem.name===item.name)

                    if(item.type===itemShop.name && currentIdex === -1 ){ 
                        listView.push({
                            _id: item._id,
                            name: item.name,
                            nameEs: item.nameEs,
                            size: item.size,
                            type: item.type,
                            image: item.image
                    })  }
                }//* /
            }
        }

        setValEdit("shops",listPro)
        setListShopsDataEdit([...listBase]);
        //setListCategoryFilter([...listView])
        setValEdit("categories",[]); 
        
        
    }

    const changeCategoryEventEdit =(event:any)=>{
        
        const listBase:any =  listCategoriesDataEdit;
        const listPro:any = getValEdit("categories") || [];
        let value = event.target.value;

        let indexData = listBase.findIndex((item:any)=>item._id===event.target.value);
        
        if(event.target.checked)
        {
            listPro.push(listBase[indexData]._id);
            listBase[indexData].checked = true;
            
        }else{

            const iCatgFilter = listPro.findIndex((itemSel:string)=>itemSel===listBase[indexData].name);

            if(iCatgFilter>-1){
                listPro.splice(Number(iCatgFilter) ,1);
                listBase[iCatgFilter].checked = false;
            }
        }

        setValEdit("categories",listPro)
        setListCategoriesEdit(listBase)
    } 

    const sendUpdateCoupon = (data:any)=>{

        console.log("formEditCountry",data);
        //editCroppedImg

        const form = new FormData();
        form.append("id", data._id || "");
        
        if(data.imageRaw){ form.append("image",data.imageRaw.file); }
        
        form.append("code", data.code);
        form.append("description", data.description);
        form.append("descriptionEs", data.descriptionEs);
        form.append("status", data.status);
        form.append("typeCoupon", data.typeCoupon);
        form.append("typeUser", "all");
        form.append("value", data.value);
        form.append("amountCoupon", data.amountCoupon);
        form.append("applicable", data.applicable);
        form.append("shops", JSON.stringify( data.shops));
        form.append("categories", JSON.stringify( data.categories));
        form.append("brands", JSON.stringify( data.brands));
        form.append("start", data.start);
        form.append("end", data.end);

        if(data.applicable==="bundle" ){ form.append("amountProducts", data.amountProducts); }

        const alertMsg = messageAlert;

        editCoupon(form)
        .then(result=>{

            console.log(data);


            const proList = [...listCoupons];
            const currentPos = proList.findIndex((item)=>item._id===data._id);

            alertMsg.icon = 'success'; 
            alertMsg.title = 'Coupon Edit'; 
            alertMsg.text = 'Has been successfully edit'; 

            commonMsg(alertMsg);

            proList[currentPos].code = data.code;
            proList[currentPos].description = data.description;
            proList[currentPos].descriptionEs = data.descriptionEs;
            proList[currentPos].status = data.status;
            proList[currentPos].typeCoupon = data.typeCoupon;
            proList[currentPos].typeUser = data.typeUser;
            proList[currentPos].value = data.value;
            proList[currentPos].amountCoupon = data.amountCoupon;
            proList[currentPos].applicable = data.applicable;
            proList[currentPos].shops = data.shops;
            proList[currentPos].categories = data.categories;
            proList[currentPos].brands = data.brands;
            proList[currentPos].start = data.start;
            proList[currentPos].end = data.end;

            if(data.image.length>0){ proList[currentPos].image = data.image; }

            rsEdit(
                {_id:"",
                code:"",
                description:"",
                descriptionEs:"",
                status:false,
                typeCoupon:"percent",
                typeUser:"all",
                value:0,
                amountCoupon:0,
                applicable:"product",
                shops:[],
                categories:[],
                brands:[],
                start:new Date(),
                end:new Date()
                }
            )


            setListCoupons(proList)
            setEditCouponView(false);
            setImgPreviewEdit("");
            setEditCroppedImg(null);
        })
        .catch(error=>{ 
            console.log(error);

            alertMsg.icon = 'error'; 
            alertMsg.title = 'Coupon don´t edited'; 
            alertMsg.text = 'It was not possible to edit'; 

            commonMsg(alertMsg);

        });//*/
    }

    const removeCouponAlert = (id:string,name:string)=>{
        const confirm = messageConfirm;

        confirm.icon = 'question';
        confirm.title = 'Remove this coupon';
        confirm.text = 'Are you sure you want to delete '+name+'?';
        confirm.callback = removeCouponEvent;
        confirm.data =  id;

        commonConfirm(confirm)
    }

    const removeCouponEvent = (id:string)=>{
        console.log("remove by id")
        console.log(id)
        removeCoupon(id)
        .then(data=>{

            console.log(data);
            loadCoupons();

        })
        .catch(error=>{

        })//*/
    }

    const handleAccordion = (panel:string)=>(event:React.SyntheticEvent,isExpanded:boolean)=>{
        setExpanded(isExpanded?panel:false);
    }

    useEffect(()=>{

        initData()

    },[])

    useEffect(()=>{ 

        console.log(" cambioa de esta",getValues("status"));

    },[watch("status"),watch("applicable"),watch("start"),watch("end"),watch("shops"),watch("categories"),wtEdit("status"),
        wtEdit("applicable"),wtEdit("start"),wtEdit("end"),wtEdit("shops"),wtEdit("categories")
    ])


    return(
        <div className='cnt-general cnt-cms' >
            <div>
                <button>
                    <Link to="/admin/menu" >regresar</Link>
                    </button>
            </div>
            <h4>Coupons</h4>
            <div>
                <h6 className="h6-form" >Add New Rol</h6>
                <form onSubmit={handleSubmit( sendCoupon)} >
                    <ImageItem
                            title="Add an Image"
                            list={[]}
                            preview={true}
                            cropModal={true}
                            aspect={16/9}
                            onChange={changeImages}
                            name="imageRaw"
                            errors={errors}
                            errorsmsn={errorsFormCoupon["imageRaw"]}
                            />
                    <InputItem
                        title="Code"
                        name="code"
                        required={true}
                        register={register}
                        errors={errors}
                        errorsmsn={errorsFormCoupon["code"]}
                        labelStyle = "input-label-Third"
                        inputStyle = "full-width"
                        />
                    <InputItem
                        checkTitle="Visible"
                        name="status"
                        type="checkbox"
                        labelStyle = "input-label-Third"
                        inputStyle = "full-width"
                        onChange={changeVisibility}
                        check={getValues("status")}
                        />
                    {listapply.length>0 &&
                        <DropItem
                            title="Applicable"
                            name="applicable"
                            keyValue="applicable"
                            value=""
                            required={true}
                            register={register}
                            labelStyle = "input-label-Third"
                            inputStyle = "full-width"
                            list={listapply}
                            iCheck={getValues("applicable")}
                            type="index"
                            />
                    }
                    {listType.length>0 &&
                        <DropItem
                            title="Type Coupon"
                            name="typeCoupon"
                            keyValue="type_coupon"
                            value=""
                            required={true}
                            register={register}
                            labelStyle = "input-label-Third"
                            inputStyle = "full-width"
                            list={listType}
                            iCheck={getValues("typeCoupon")}
                            type="index"
                            />
                    }
                    <InputItem
                        title="Value"
                        name="value"
                        type="number"
                        required={true}
                        register={register}
                        errors={errors}
                        errorsmsn={errorsFormCoupon["value"]}
                        labelStyle = "input-label-Third"
                        inputStyle = "full-width"
                        minValue={0}
                        maxValue={getValues("typeCoupon") ==="percent" ? 100 : 10000000 }
                        />
                    <InputItem
                        title="Amount Coupons"
                        name="amountCoupon"
                        type="number"
                        required={true}
                        register={register}
                        errors={errors}
                        errorsmsn={errorsFormCoupon["value"]}
                        labelStyle = "input-label-Third"
                        inputStyle = "full-width"
                        minValue={0}
                        />
                    <TextAreaItem 
                        title="Description"
                        name="description"
                        required={true}
                        register={register}
                        labelStyle="input-label-Third"
                        errors={errors}
                        errorsmsn={errorsFormCoupon["description"]}
                        />
                    <TextAreaItem 
                        title="Description Spanish"
                        name="descriptionEs"
                        register={register}
                        labelStyle="input-label-Third"
                        />
                    
                    {getValues("applicable") ==="bundle" &&
                        <InputItem
                            title="Amount Products"
                            name="amountProducts"
                            type="number"
                            register={register}
                            labelStyle = "input-label-Third"
                            inputStyle = "full-width"
                            minValue={2}
                            />
                    }
                    <label className="column-left" >
                        <span>Start Day</span><br/>
                        <div>
                            <DateTimePicker  onChange={(item)=>{updateFormCouponDate("start",item)}} value={getValues("start")} />
                        </div>
                    </label>
                    <label className="column-left" >
                        <span>End Day</span><br/>
                        <div>
                            <DateTimePicker  onChange={(item)=>{ updateFormCouponDate("end",item)}} value={getValues("end")}  />
                        </div>
                    </label><br/><br/>
                    {getValues("applicable") ==="product" &&
                        <div>
                            <Accordion expanded={expanded==='panel1'} onChange={handleAccordion('panel1')} >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                                >
                                    <h4>List Shops    </h4>
                                    <div className="title-acco" >Selected:<span>{ getValues("shops")?.length }</span></div>
                                </AccordionSummary><br/><br/>
                                <AccordionDetails>
                                    <div className="module-list" >
                                    {listShopsData.length>0 &&(
                                        listShopsData.map((item:any)=>{
                                            
                                            return(
                                                <div key={generateKey(item._id)}  className="item" >
                                                <InputItem
                                                    name={"shop_list"}
                                                    checkTitle={item.name+" / "+item.nameEs}
                                                    type="checkbox"
                                                    labelStyle = "input-label"
                                                    inputStyle = "full-width"
                                                    value={item._id}
                                                    onChange={changeCategoriesByShop}
                                                    check={item.checked}
                                                    />
                                                </div>
                                            )
                                        })
                                        )
                                    }
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded==='panel2'} onChange={handleAccordion('panel2')} >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                                >
                                    <h4>List Categories    </h4>
                                    <div className="title-acco" >Selected:<span>{getValues("categories")?.length}</span></div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="module-list" >
                                    {listCategoryFilter.length>0 &&(
                                        listCategoryFilter.map((item:any)=>{

                                            return(
                                                <div key={generateKey(item._id)}  className="item" >
                                                    <InputItem
                                                        name={item.name}
                                                        checkTitle={item.name+" / "+item.nameEs+"\n \n"+"Shop: "+item.type}
                                                        type="checkbox"
                                                        labelStyle = "input-label"
                                                        inputStyle = "full-width"
                                                        value={item._id}
                                                        onChange={changeCategoryEvent}
                                                        check={item.checked}
                                                    />
                                                </div>
                                            )
                                        })
                                        )

                                    }
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            <div className="parent-search"  >
                                <InputItem
                                    title="Search Brand"
                                    name="brand"
                                    onChange={searchBrandWord}
                                    labelStyle = "input-label-Third"
                                    inputStyle = "full-width"
                                    value={textSearch}
                                    />
                                <div className="brand-list" >
                                {listBrandsSearch.length > 0 &&
                                    <div className="drop-custom" >
                                        {listBrandsSearch.map((itemBr:any)=>{

                                            return(
                                                <div className="item-drop" key={"brands_"+generateKey(itemBr._id)}  onClick={()=>{selectedBrandItem(itemBr)}} >{itemBr.name}</div>
                                            )
                                        })
                                        }
                                    </div>
                                }
                                </div>
                            </div>
                            {listBrandsShow.length > 0 &&
                                <div className="cnt-brands"  >
                                        {listBrandsShow.map((itmemBrSel:any)=>{

                                            return(
                                                <div className="item" >
                                                    <span>{itmemBrSel.name}</span>
                                                    <div className="remove" onClick={()=>{removeBrandItem(itmemBrSel)}}  >x</div>   
                                                </div>
                                            )
                                        })
                                        }
                                </div>
                            }
                        </div>
                    }
                    
                    <button>Add Coupon</button>
                </form>
                <hr/>
            </div>
            <h6 className="h6-form" >List Coupons</h6>
            <div className="parent-table" >
                <table className="table" >
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Data</th>
                            <th>Detail</th>
                            <th>Shops</th>
                            <th>Categories</th>
                            <th>Brands</th>
                            <th>Status</th>
                            <th>Dates</th>
                            <th className="controls" ></th>
                        </tr>
                    </thead>
                    <tbody>
                    {listCoupons.length>0 &&
                            listCoupons.map((itemCoupon:any)=>{
                                    let check = false;

                                return(
                                    <tr key={generateKey(itemCoupon._id)}   >
                                        <td> <img src={getImage(itemCoupon.image)} alt={"delujo"} /> </td>
                                        <td>
                                            <div><label className="sub-title" >Code:&nbsp;</label> {itemCoupon.code}</div>
                                            <div><label className="sub-title" >Applicable:&nbsp;</label> {itemCoupon.applicable}</div>
                                            <div><label className="sub-title" >Type:&nbsp;</label> {itemCoupon.typeCoupon}</div>
                                            <div><label className="sub-title" >value:&nbsp;</label> {itemCoupon.value}</div>
                                            <div><label className="sub-title" >Amount coupons:&nbsp;</label> {itemCoupon.amountCoupon}</div>
                                        </td>
                                        <td> {itemCoupon.description}  </td>
                                        <td>
                                            {itemCoupon.shops.length > 0  &&
                                                itemCoupon.shops.map((itemShPr:any)=>{
                                                    return(
                                                        <div key={generateKey("party_shop_"+itemShPr._id)}  >
                                                            {itemShPr.name}
                                                        </div>
                                                    )
                                                })
                                            }
                                        </td>
                                        <td>
                                            {itemCoupon.categories.length > 0 &&
                                                itemCoupon.categories.map((itemCat:any)=>{
                                                    return(
                                                        <div key={generateKey("party_cat_"+itemCat._id)} >
                                                            {itemCat.name}
                                                        </div>
                                                    )
                                                })
                                            }
                                        </td>
                                        <td>
                                            {itemCoupon.brands.length > 0 &&
                                                itemCoupon.brands.map((itemBrand:any)=>{
                                                    return(
                                                        <div key={generateKey("party_bra_"+itemBrand._id)} >
                                                            {itemBrand.value}
                                                        </div>
                                                    )
                                                })
                                            }
                                        </td>
                                        <td> {itemCoupon.status?"Visible":"No Visible"  } </td>
                                        <td> 
                                            <div><label className="sub-title" >Start:&nbsp;</label> {itemCoupon.start}</div>
                                            <div><label className="sub-title" >End:&nbsp;</label> {itemCoupon.end}</div>
                                        </td>

                                        <td>
                                            <button className="edit" onClick={()=>{showEditCoupon(itemCoupon)}}  >
                                                <GetIcon type="fas" icon="pen" color="#FFFFFF"/>
                                                Edit
                                            </button>
                                            <button className="remove" onClick={()=>{removeCouponAlert(itemCoupon._id,itemCoupon.code)}}  >x</button>
                                        </td>
                                    </tr>
                                )
                            })
                    }

                    </tbody>
                </table>
            </div>
            {/*edit country */ }
            {editCouponView && <div className="modal-base" >
                <div className='background' ></div>
                <div className="view-edit" >
                <form onSubmit={handEdit(sendUpdateCoupon)}>
                    <ImageItem
                            title="Add an Image"
                            list={[]}
                            preview={true}
                            cropModal={true}
                            aspect={16/9}
                            onChange={changeImagesUpdate}
                            name="imageRaw"
                            />
                        <InputItem
                            title="Code"
                            name="code"
                            required={true}
                            register={regEdit}
                            errors={errorsEdit}
                            errorsmsn={errorsFormCoupon["code"]}
                            labelStyle = "input-label-Third"
                            inputStyle = "full-width"
                            />
                        <InputItem
                            checkTitle="Visible"
                            name="status"
                            type="checkbox"
                            labelStyle = "input-label-Third"
                            inputStyle = "full-width"
                            onChange={changeVisibilityEdit}
                            check={getValEdit("status")}
                            />
                        {listapply.length>0 &&
                            <DropItem
                                title="Applicable"
                                name="applicable"
                                keyValue="applicable"
                                value=""
                                required={true}
                                register={regEdit}
                                labelStyle = "input-label-Third"
                                inputStyle = "full-width"
                                list={listapply}
                                iCheck={getValEdit("applicable")}
                                type="index"
                                />
                        }
                        {listType.length>0 &&
                            <DropItem
                                title="Type Coupon"
                                name="typeCoupon"
                                keyValue="type_coupon"
                                value=""
                                required={true}
                                register={regEdit}
                                labelStyle = "input-label-Third"
                                inputStyle = "full-width"
                                list={listType}
                                iCheck={getValEdit("typeCoupon")}
                                type="index"
                                />
                        }
                        <InputItem
                            title="Value"
                            name="value"
                            type="number"
                            required={true}
                            register={regEdit}
                            errors={errorsEdit}
                            errorsmsn={errorsFormCoupon["value"]}
                            labelStyle = "input-label-Third"
                            inputStyle = "full-width"
                            minValue={0}
                            maxValue={getValEdit("typeCoupon") ==="percent" ? 100 : 10000000 }
                            />
                        <InputItem
                            title="Amount Coupons"
                            name="amountCoupon"
                            type="number"
                            required={true}
                            register={regEdit}
                            errors={errorsEdit}
                            errorsmsn={errorsFormCoupon["value"]}
                            labelStyle = "input-label-Third"
                            inputStyle = "full-width"
                            minValue={0}
                            />
                        <TextAreaItem 
                            title="Description"
                            name="description"
                            required={true}
                            register={regEdit}
                            labelStyle="input-label-Third"
                            errors={errorsEdit}
                            errorsmsn={errorsFormCoupon["description"]}
                            />
                        <TextAreaItem 
                            title="Description Spanish"
                            name="descriptionEs"
                            register={regEdit}
                            labelStyle="input-label-Third"
                            />
                        {getValEdit("applicable") ==="bundle" &&
                        <InputItem
                            title="Amount Products"
                            name="amountProducts"
                            type="number"
                            register={regEdit}
                            labelStyle = "input-label-Third"
                            inputStyle = "full-width"
                            minValue={2}
                            />
                        }
                        <label className="column-left" >
                            <span>Start Day</span><br/>
                            <div>
                                <DateTimePicker  onChange={(item)=>{updateFormCouponDateEdit("start",item)}} value={getValEdit("start")} />
                            </div>
                        </label>
                        <label className="column-left" >
                            <span>End Day</span><br/>
                            <div>
                                <DateTimePicker  onChange={(item)=>{ updateFormCouponDateEdit("end",item)}} value={getValEdit("end")}  />
                            </div>
                        </label><br/><br/>
                        {getValEdit("applicable") ==="product" &&
                            <div>
                                <Accordion expanded={expanded==='panel1'} onChange={handleAccordion('panel1')} >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                    >
                                        <h4>List Shops    </h4>
                                        <div className="title-acco" >Selected:<span>{getValEdit("shops")?.length}</span></div>
                                    </AccordionSummary><br/><br/>
                                    <AccordionDetails>
                                        <div className="module-list" >
                                        {listShopsDataEdit.length>0 &&(
                                            listShopsDataEdit.map((item:any)=>{
                                                
                                                return(
                                                    <div key={generateKey("shop_edit_"+item._id)}  className="item" >
                                                    <InputItem
                                                        name={"shop_list"}
                                                        checkTitle={item.name+" / "+item.nameEs}
                                                        type="checkbox"
                                                        labelStyle = "input-label"
                                                        inputStyle = "full-width"
                                                        value={item._id}
                                                        onChange={changeCategoriesByShopEdit}
                                                        check={item.checked}
                                                        />
                                                    </div>
                                                )
                                            })
                                            )
                                        }
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded==='panel2'} onChange={handleAccordion('panel2')} >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                    >
                                        <h4>List Categories    </h4>
                                        <div className="title-acco" >Selected:<span>{getValEdit("categories")?.length}</span></div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className="module-list" >
                                                {listCategoriesDataEdit.length>0 &&(
                                                    listCategoriesDataEdit.map((item:any)=>{

                                                        return(
                                                            <div key={generateKey(item._id)}  className="item" >
                                                                <InputItem
                                                                    name={item.name}
                                                                    checkTitle={item.name+" / "+item.nameEs+"\n \n"+"Shop: "+item.type}
                                                                    type="checkbox"
                                                                    labelStyle = "input-label"
                                                                    inputStyle = "full-width"
                                                                    value={item._id}
                                                                    onChange={changeCategoryEventEdit}
                                                                    check={item.checked}
                                                                />
                                                            </div>
                                                        )
                                                    })
                                                    )

                                                }
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                                <div className="parent-search"  >
                                <InputItem
                                    title="Search Brand"
                                    name="brand"
                                    onChange={searchBrandWordEdit}
                                    labelStyle = "input-label-Third"
                                    inputStyle = "full-width"
                                    value={textSearchEdit}
                                    />
                                <div className="brand-list" >
                                {listBrandsSearchEdit.length > 0 &&
                                    <div className="drop-custom" >
                                        {listBrandsSearchEdit.map((itemBr:any)=>{

                                            return(
                                                <div className="item-drop" key={"brands_"+generateKey(itemBr._id)}  onClick={()=>{selectedBrandItemEdit(itemBr)}} >{itemBr.name}</div>
                                            )
                                        })
                                        }
                                    </div>
                                }
                                </div>
                            </div>
                            {listBrandsShowEdit.length > 0 &&
                                <div className="cnt-brands"  >
                                        {listBrandsShowEdit.map((itmemBrSel:any)=>{

                                            return(
                                                <div className="item" >
                                                    <span>{itmemBrSel.name}</span>
                                                    <div className="remove" onClick={()=>{removeBrandItem(itmemBrSel)}}  >x</div>   
                                                </div>
                                            )
                                        })
                                        }
                                </div>
                            }
                            </div>
                        }
                        
                            <div>
                                <button>Edit Coupon</button>
                            </div>
                        </form>
                    <hr/>
                    <button onClick={hiddenEditCoupon} >Cancel Edit</button>
                </div>
            </div>}
        </div>
    );

}