import axios from 'axios';
import { url_server,header_token } from '../../constants/global';
import { getTokenUser } from '../helper/storage';


export const addParty = async (data:any)=>{
    
    header_token.headers['x-access-token'] = getTokenUser() || "";
    const result = await axios.post(url_server+"/api/common/party/add",data,header_token);

    return result.data;
}

export const getListParties = async ()=>{

    header_token.headers['x-access-token'] = getTokenUser() || "";
    const result = await axios.get(url_server+"/api/common/parties/list",header_token);

    return result.data;
}

export const editParties = async (data:any)=>{

    header_token.headers['x-access-token'] = getTokenUser() || "";
    const result = await axios.post(url_server+"/api/common/party/edit",data,header_token);

    return result.data;
}

export const removeParty = async (data:any)=>{

    header_token.headers['x-access-token'] = getTokenUser() || "";
    const result = await axios.delete(url_server+"/api/common/party/remove/"+data,header_token);

    return result.data;
}
