import { useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { admin } from '../../constants/menusOptions';
import { generateKey } from '../../utils/commons';

export const MenuComponent = () =>{

    const [listOptions,setListOptions] =useState([]);


    useEffect(()=>{

        console.log(admin);

        setListOptions(admin as []);

    },[])


    return(
        <div className='cnt-general' >
            <h3>Selected Option</h3>
            <div className='cnt-cms-menu' >
                { listOptions.map((item:any)=>{
                    return (
                        <div className='module'  key={generateKey("module_"+item.module)} >
                            <h4 className='title' >{item.module}</h4>
                            <div className='cnt-btn' >
                            {item.items.map((button:any)=>{
                                return(
                                    <Link key={generateKey("mbutton_"+button.name)} to={`${button.path}`} >
                                    <button  >
                                        {button.name}
                                    </button>
                                </Link>
                                )
                            })}
                            </div>
                        </div>
                    )
                })

                }
            </div>
        </div>
    )


}