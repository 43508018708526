import {useEffect, useState} from 'react'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'

export interface IImageCrop {
    data?:{
        img:string,
        name:string,
        custom?:string
        id:number
    },
    imageUpload?:string;
    aspect?:number;
    viewMode?:string;
    style?:any;
    cropModal:(img:any)=>void;
    closeModal:()=>void;
    id?:string | "";
    imageName?:string
}//*/


export const CropImage = (props:IImageCrop) =>{
    const [imgUploadId,setImgUploadId] = useState<any>("");
    const [imgUpload,setImgUpload] = useState<string>("");
    const [imgUploadName,setImgUploadName] = useState<string>("");
    const [croppedArea,setCroppedArea] = useState<any>();

    const croppedImage = async ()=>{

        const dataUrl = croppedArea.getCroppedCanvas().toDataURL();
    
            const arr = dataUrl.split(',');
            const mine = arr[0].match(/:(.*?);/)[1];
            const bstr = atob(arr[1]);
            let n = bstr.length;
            let u8Arr = new Uint8Array(n);
    
            while(n--){ u8Arr[n] = bstr.charCodeAt(n); }
    
            props.cropModal({id:imgUploadId,url:dataUrl,file:new File([u8Arr],imgUploadName?imgUploadName:"image",{type:mine})});
    }

    useEffect(()=>{

        if(props.data!==undefined){

            const img  = props.data.img;
            const name = props.data.name;
            
            setImgUpload(img);
            setImgUploadName(name);
            setImgUploadId(props.data.id)

        }else{

            const img = props.imageUpload || "";
            setImgUpload(img);
        }




        // eslint-disable-next-line
    },[])

    return(
        <div className="crop-modal" >
            <div className='background' ></div>
            <div className='view-crop' >
                <Cropper 
                        src={imgUpload}
                        aspectRatio={props.aspect?props.aspect: 1 / 1}
                        className='content-canvas'
                        viewMode={2}
                                    onInitialized={
                                        (instance)=>{
                                            setCroppedArea(instance)
                                        }

                                    }
                />
                    <div className="crop-controls" >
                        <button onClick={croppedImage} >Send</button>
                        <button className='cancel'  onClick={()=>props.closeModal()} >  Cancel</button>
                    </div>
            </div>
        </div>
    )

} 