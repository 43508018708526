import {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import {  listUsers } from '../../services/admin/Users'
import { generateKey } from '../../utils/commons';

export const UsersComponents = () =>{

    const [listUsersView,setListUsersView] = useState([]);


    useEffect(()=>{

        //setListUsersView([]);

        console.log("Use efect users ")
        console.log(listUsersView)
        console.log("Use efect users ")


        listUsers()
        .then((data)=>{
            console.log(" --- data --- ");
            console.log(data);

            if(data.code==='403')
                return;

            setListUsersView(data.users.docs);
        })
        .catch(error=>{
            console.log(" --- error --- ")
            console.log(error)

        })//*/

        // eslint-disable-next-line
    },[]);


    return(
        <div className='cnt-general cnt-cms' >
             <div>
                <button>
                    <Link to="/admin/menu" >regresar</Link>
                    </button>
            </div>
            <h2> Users </h2>
            <div>
            { listUsersView.map((item)=>{

                const key = generateKey(item["username"])

                return(
                    <div key={key} >
                        {item["username"]}
                    </div>
                )


            })



            }


            </div>
        </div>
    )

}