export const admin = 
[
    {
        module:"Globals",
        items:[
            {name:'Nationalities',path:'/admin/nationality'},
        ]      
        },
    {
        module:"Commons",
        items:[
            {name:'Shops',path:'/admin/shops'},
            {name:'Categories',path:'/admin/categories'},
            {name:'Brands',path:'/admin/brands'},
            {name:'Parties',path:'/admin/parties'},

        ]    
    },
    {
        module:"Users",
        items:[
            {name:'Roles',path:'/admin/roles'},
            {name:'Users',path:'/admin/users'},
            {name:'Scores',path:'/admin/scores'},
        ]
    },
    {
        module:'Products',
        items:[
            {name:'Products',path:'/admin/products'},
            {name:'Coupons',path:'/admin/coupons'},
        ]
    },
    {
        module:'Others',
        items:[
            {name:'carrier',path:'/admin/carrier'}
        ]
    }
];