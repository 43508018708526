import axios from 'axios';
import { url_server,header_token } from '../../constants/global';
import { getTokenUser } from '../helper/storage';

export const listShops = async () =>{
    header_token.headers['x-access-token'] = getTokenUser() || "";

    const result = await axios.get(url_server+"/api/common/shops",header_token);
    return result.data;
}

export const addShop = async (data:any) =>{

    //header_token.headers['x-access-token'] = getTokenUser() || "";
    const result = await axios.post(url_server+"/api/common/shop/add",data,header_token);

    return result.data;
}

export const editShop = async (data:any) =>{

    header_token.headers['x-access-token'] = getTokenUser() || "";
    const result = await axios.post(url_server+"/api/common/shop/edit/",data,header_token);

    return result.data;
}

export const removeShopData =async (data:string) => {

    header_token.headers['x-access-token'] = getTokenUser() || "";
    const result =  await axios.delete(url_server+"/api/common/shop/remove/"+data,header_token);

    return result.data;
}