import axios from 'axios';
import { url_server,header_token } from '../../constants/global';

export const listCategories = async () =>{
    const result = await axios.get(url_server+"/api/categories/list",header_token);

    return result.data;
}

export const add = async (data:any) =>{

    const result = await axios.post(url_server+"/api/category/create",data,header_token);

    return result.data;
}

export const edit = async (data:any) =>{

    const result = await axios.post(url_server+"/api/category/edit/",data,header_token);

    return result.data;

}

export const removeItem =async (data:string) => {

    const result =  await axios.delete(url_server+"/api/categories/delete/"+data,header_token);

    return result.data;
    ///api/common/shop/remove/

}