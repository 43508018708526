import { getTokenUser } from '../services/helper/storage';

const url_server = "https://delujoapp.com";

const header_token = {
    headers:{'x-access-token':getTokenUser() || "",'refresh-token':"" }
}


export{
    url_server,
    header_token 
}