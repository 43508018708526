import React,{ useReducer} from 'react';


import { FormReducer,EventInput } from '../../utils/commons';
import { ILoginUser } from '../../interfaces/IUser';
import { singin } from '../../services/auth/Auth';
import { setTokenRefreshUser, setTokenUser } from '../../services/helper/storage'

const loginUser:ILoginUser = {
    identifier:"",
    password:""
};

export const LoginComponent = ()=>{

    const [formLogin,setFormLogin] = useReducer(FormReducer,loginUser);

    //#region funtions

    const updateForm = (event:object)=>{
        setFormLogin(EventInput(event));
    }
    
    const sendForm = async (event:any)=>{

        event.preventDefault();
        console.log("validate data ")
        //console.log(event)
        console.log(formLogin)

        singin(formLogin)
        .then(data=>{
            console.log(data)

            setTokenUser(data.accessToken);
            setTokenRefreshUser(data.refreshToken);

        })
        .catch(error=>{
            console.log(error);
        })

        return false;
    }

    //#endregion

    return(
        <div className="login-container">
      <div className="account section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="login-form border p-5">
                <div className="text-center heading">
                  <h2 className="mb-2">Ingresar</h2>
                  <p className="lead">
                    No tienes una cuenta? <a href="#">Crear una cuenta</a>
                  </p>
                </div>

                <div className="ctn-login" >
                    <div className="modal-login" >
                        <button className="btn-facebook" >
                            <i className="fab fa-facebook-square "></i>
                            <div className="btn-txt" >Ingresar con Facebook</div>
                        </button>
                        <button className="btn-google" >
                            <i className="fab fa-google "></i>
                            <div className="btn-txt" >Ingresar con Google</div>
                        </button>
                    </div>
                </div>

                <form action="#">
                  <div className="form-group mb-4">
                    <label>Usuario</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Username"
                    />
                  </div>
                  <div className="form-group">
                    <label>Contraseña</label>
                    <a className="float-right" href="">
                      Olvide contraseña?
                    </a>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Password"
                    />
                  </div>

                  <a href="#" className="btn btn-main mt-3 btn-block">
                    Ingresar
                  </a>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
        // <div className="ctn-login" >
        //     <div className="modal-login" >
        //         <h3>Log In</h3>
                // <button className="btn-facebook" >
                //     <i className="fab fa-facebook-square "></i>
                //     <div className="btn-txt" >Sign up with Facebook</div>
                // </button>
                // <button className="btn-google" >
                //     <i className="fab fa-google "></i>
                //     <div className="btn-txt" >Sign up with Google</div>
                // </button>
        //         <div className="cnt-txt-fill" >
        //             <div className="txt">or, fill out with</div> 
        //             <hr/>
        //         </div>
        //         <form onSubmit={sendForm} >
        //             <input type="text" name="identifier" placeholder="Username or Email" onChange={updateForm}  />
        //             <input type="password" name="password" placeholder="Password" onChange={updateForm}/>
        //             <div className="form-bottom" >
        //                 <span>
        //                     Forgot Password?
        //                 </span>
        //                 <button type="submit" >Log In</button>
        //             </div>
        //         </form>
        //         <div className="go-to-signup " >
        //             Don't have an account? Sign Up »
        //         </div>
        //     </div>
        // </div>
    );

}



