import React from 'react';
import { BrowserRouter as Router, Routes ,Route } from 'react-router-dom';

//
import {interceptor} from './services/helper/interceptor';
import { HeaderComponent } from './components/commons/HeaderComponent';
import SingleProduct from './components/SingleProduct';
import Feed from './components/Feed';
import { IndexComponent } from './components/IndexComponent';
import { LoginComponent } from './components/auth/LoginComponent'
import { SigninComponent } from './components/cms/SigninComponent';
import { MenuComponent} from './components/cms/MenuComponent';
import { RolesComponent } from './components/cms/RolesComponent';
import { UsersComponents} from './components/cms/UsersComponents';
import { ShopsComponent} from './components/cms/ShopsComponent';
import { CategoriesComponent } from './components/cms/CategoriesComponent';
import { BrandsComponent } from './components/cms/BrandsComponent';
import { CarrierComponent } from './components/cms/CarriersComponent';
import { NationalityComponent } from './components/cms/NationalityComponent';
import { PartyComponent } from './components/cms/PartyComponent';
import { LinkComponent } from './components/web/LinkComponent';
import { ProtectedRouteComponen } from './components/protected/ProtectedRouteComponents';
import { ShowcaseComponent } from './components/web/ShowcaseComponent';
import { ConfirmComponent } from './components/web/ConfirmComponent';
import { CouponsComponents } from './components/cms/CouponsComponent';
import { ScoresComponents } from './components/cms/ScoresComponents';
import { PrivacyComponent } from './components/web/PrivacyComponent';

function App(): JSX.Element {

  interceptor();

  return (

    <div className={'container-fluid'} >
      <Router basename='/' >
        <HeaderComponent />
        <Routes>
          {/*<ProtectedRouteComponen path={'/showcase'} component={ <ShowcaseComponent />}  />*/}
          <Route path="/admin/menu" element={<MenuComponent/>} />
          <Route path="/admin/roles" element={<RolesComponent/>} />
          <Route path="/admin/nationality" element={<NationalityComponent/>} />
          <Route path="/admin/users" element={<UsersComponents/>} />
          <Route path="/admin/scores" element={<ScoresComponents/>} />
          <Route path="/admin/shops" element={<ShopsComponent/>} />
          
          <Route path="/admin/categories" element={<CategoriesComponent/>} />
          <Route path="/admin/brands" element={<BrandsComponent/>} />
          <Route path="/admin/parties" element={<PartyComponent/>} />
          <Route path="/admin/carriers" element={<CarrierComponent/>} />
          <Route path="/admin/coupons" element={<CouponsComponents/>} />

          <Route path="/admin" element={<SigninComponent/>} />
          <Route path="/login" element={<LoginComponent/>} />
          <Route path="/link" element={<LinkComponent/>} />
          <Route path="/confirm" element={<ConfirmComponent/>} />
          <Route path="/confirm/:data" element={<ConfirmComponent/>} />
          <Route path="/link/:type/:url" element={<LinkComponent/>} />

          <Route path="/product-single" element={<SingleProduct />} />
          <Route path="/feed" element={<Feed />} />
          <Route path="/privacy" element={<PrivacyComponent />} />

          <Route path="/" element={<IndexComponent/>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;


