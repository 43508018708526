import { useEffect, useState } from "react"
import { generateKey } from "../../../utils/commons"

interface IInput{
    title?:string,
    name?:string,
    list:any,
    type?:string,
    value:string,
    view?:any,
    separactor?:string,
    required?:boolean,
    register?:any,
    /*errors?:any
    errorsmsn?:string,*/
    labelStyle?:string,
    inputStyle?:string,
    iCheck?:string,
    keyValue:string
}

export const DropItem = (props:IInput)=>{
    
    const [classLabel,setClassLabel] = useState<string>('input-label')

    useEffect(()=>{

        if(props.labelStyle){  
            let classL = classLabel+" "+props.labelStyle
            setClassLabel(classL);
        }
    },[])


    return(
        <label className={classLabel} >
            {props.title && <span>{props.title} </span> }
            {props.required && <span className="requiere" >*</span> }
            <select 
                className={props.inputStyle}
                {...props.register && props.register(props.name,{required:props.required })}
            >
                {/*props.list.length>0 &&
                    props.list.map((option:any,index:any)=>{
                        
                        let value = ""
                        let opt = ""  
                        let check = false;

                        if(props.type==="index"){
                            
                            value = props.value !== "" ? index : option;
                            if(props.iCheck===value){ check= true; }
                            opt = option;
                        }else{

                            if(props.iCheck===option[props.value]){ check= true; }
                            value = option[props.value];
                            if(props.view !==undefined && props.view.length>0){

                                for(let i=0; i<props.view.length;i++)
                                {
                                    let item = props.view[i];
                                    opt += option[item]
    
                                    if(props.separactor && (i+1) < props.view.length){ opt+=props.separactor }
                                }
                            }
                        }

                    return(
                        <option 
                            key={generateKey("option_"+props.keyValue+"_"+value)}  
                            selected={check} 
                            defaultValue={value} 
                            > 
                                {opt}
                            </option>
                        )
                    })
                */}

            </select>
        </label>
    )

}