import { useEffect, useState } from "react";
import { TBrand, TCategory, TShop } from "../../interfaces/IShop";
import { listShops } from "../../services/admin/Shop";
import { listCategories } from '../../services/admin/Category';
import { commonMsg,   GetIcon } from "../../utils/commons";
import { getImage, generateKey } from '../../utils/commons';

import { CropImage } from "../../utils/cropImage";
import { addBrand, editBrand, listBrands, /*listBrands,*/ removeBrand } from "../../services/admin/brands";
import { IMessage } from "../../interfaces/ICommons";
import { Link } from "react-router-dom";
import { InputItem } from "../commons/FormITems/InputItem";
import { useForm } from "react-hook-form";
import { errorsFormBrands } from "../../errors/errorsShops";
import { ImageItem } from "../commons/FormITems/ImageItem";

const messageAlert:IMessage={
    icon:'info',
    title:'',
    text:''
}

export const BrandsComponent = () =>{

    const {register,handleSubmit,formState:{errors},setValue, getValues,reset  } = useForm<TBrand>();
    const {register:regEdit,handleSubmit:handEdit,formState:{errors:errorsEdit},setValue:setValEdit,getValues:getValEdit, reset:rsEdit } = useForm<TBrand>();
    const [imgUpload,setImgUpload] = useState<any>();
    const [imgPreview,setImgPreview] = useState<string>();
    const [listShopsData,setListShopsData ] = useState<TShop[]>([]);

    const [listCategoryData,setListCategoryData] = useState<TCategory[]>([]);
    const [listCategoryFilter,setListCategoryFilter] = useState<TCategory[]>([]);
    const [listCategoryFilterEdit,setListCategoryFilterEdit] = useState<TCategory[]>([]);
    const [listBrandsData,setListBrandsData] = useState<TBrand[]>([]);
    const [pageBrands,setPageBrands] = useState(1);
    const [moreBrands,setMoreBrands] = useState(false);

    const [imgPreviewEdit,setImgPreviewEdit] = useState<string>();
    const [editCroppedImg,setEditCroppedImg] = useState<any>(null);
    const [currentCroppedImg,setCurrentCroppedImg] = useState<any>();
    const [stateEdit,setStateEdit] = useState(false);
    const [listShopsEdit,setListShopsEdit ] = useState<TShop[]>([]);

    const getShops = () => {

        listShops()
        .then(data=>{

            setListShopsData(data.data);

        }).catch(error=>{

        })//*/

    }

    const getCategories = () =>{

        listCategories()
        .then(data=>{

            const dataBase:[] = data.data;

            setListCategoryData(dataBase);
        })
        .catch(error=>{ })
    }

    const getBrands = () =>{

        listBrands(pageBrands)
        .then(data=>{

            console.log(" --- result brands --- ")
            console.log(data)

            setListBrandsData([...listBrandsData,...data.data.docs]);
            setMoreBrands(data.data.hasNextPage);

            if(data.data.hasNextPage)
            {
                setPageBrands(data.data.nextPage);
            }//*/


        })
        .catch(error=>{

        });//*/
    }

    const moreBrandsLoad = () =>{
        getBrands();
    }//*/

    const changeImages = (data:any)=>{ setValue("imageRaw",data[0]) }
    const changeImagesUpdate = (data:any)=>{ setValEdit("imageRaw",data[0]) }

    const selectShop = (event:any)=>{

        let listBase:TShop[] = [...listShopsData];
        let listPro:any = getValues("shop") || [];
        const currentCategories:TCategory[] = [...listCategoryData];
        const value =  event.target.value;

        const indexShop = listBase.findIndex((iShop:any)=>iShop._id===value); 

        if(event.target.checked){ 
            listPro.push(listBase[indexShop].name);
            listBase[indexShop].checked = true;
        }else{
            const iShopFilter = listPro.findIndex((itemSel:string)=>itemSel===listBase[indexShop].name);

            if(iShopFilter>-1){
                listPro.splice(Number(iShopFilter) ,1);
                listBase[indexShop].checked = false;
            }
        }

        let listView:TCategory[] = [];

        for(let indexSh in listBase){
            const itemShop = listBase[indexSh];

            if(itemShop.checked){

                for(let iCat in currentCategories )
                {   
                    const item:TCategory = currentCategories[iCat];
                    const currentIdex = listView.findIndex(lItem=>lItem.name===item.name)

                    console.log(" data name::: ",{sName:itemShop.name,cName:item.name})

                    if(item.type===itemShop.name && currentIdex === -1 ){ 
                        listView.push({
                            _id: item._id,
                            name: item.name,
                            nameEs: item.nameEs,
                            size: item.size,
                            type: item.type,
                            image: item.image
                    })  }
                }//*/
            }
        }

        setValue("shop",listPro)
        setListShopsData(listBase);
        setListCategoryFilter([...listView])
        setValue("categories",[]);
    }

    const selectShopEdit = (event:any)=>{

        let listBase:any = [...listShopsEdit];
        let listPro:any = getValEdit("shop") || [];
        const currentCategories:TCategory[] = [...listCategoryData];
        const value =  event.target.value;

        const indexShop = listBase.findIndex((iShop:any)=>iShop._id===value);

        console.log(" edit shop:::  ",{listBase,indexShop,value});


        if(event.target.checked){
            listPro.push(listBase[indexShop].name);
            listBase[indexShop].checked = true;
        }else{

            const iShopFilter = listPro.findIndex((itemSel:string)=>itemSel===listBase[indexShop].name);

            if(iShopFilter>-1){
                listPro.splice(Number(iShopFilter) ,1);
                listBase[indexShop].checked = false;
            }
        }

        let listView:TCategory[] = [];

        for(let indexSh in listBase){
            const itemShop = listBase[indexSh];

            if(itemShop.checked){

                for(let iCat in currentCategories )
                {   
                    const item:TCategory = currentCategories[iCat];
                    const currentIdex = listView.findIndex(lItem=>lItem.name===item.name)

                    if(item.type===itemShop.name && currentIdex === -1 ){ 
                        listView.push({
                            _id: item._id,
                            name: item.name,
                            nameEs: item.nameEs,
                            size: item.size,
                            type: item.type,
                            image: item.image
                    })  }
                }// */
            }
        }

        setValEdit("shop",listPro)
        setListShopsData(listBase);
        setListCategoryFilterEdit([...listView])
        setValEdit("categories",[]); 
    }

    const selectCategory = (event:any)=>{

        const listBase:any = listCategoryFilter;
        const listPro = getValues("categories") || [];
        const value =  event.target.value;

        const indexCat = listBase.findIndex((iShop:any)=>iShop._id===value); 

        if(event.target.checked){
            listPro.push(listBase[indexCat].name);
            listBase[indexCat].checked = true;
        }else{

            const iCatgFilter = listPro.findIndex((itemSel:string)=>itemSel===listBase[indexCat].name);

            if(iCatgFilter>-1){
                listPro.splice(Number(iCatgFilter) ,1);
                listBase[iCatgFilter].checked = false;
            }
        }

        setValue("categories",listPro)
        setListCategoryFilter(listBase)
    }

    const selectCategoryEdit = (event:any)=>{

        const listBase:any = listCategoryFilterEdit;
        let listPro:any = getValEdit("categories") || [];
        const value =  event.target.value;

        const indexCat = listBase.findIndex((iShop:any)=>iShop._id===value);

        console.log(" Edit category:: ", {listPro,indexCat,value});

        if(event.target.checked){
            listPro.push(listBase[indexCat].name);
            listBase[indexCat].checked = true;
        }else{

            const iCatgFilter = listPro.findIndex((itemSel:string)=>itemSel===listBase[indexCat].name);

            if(iCatgFilter>-1){
                listPro.splice(Number(iCatgFilter) ,1);
                listBase[iCatgFilter].checked = false;
            }
        }

        setValEdit("categories",listPro);
        setListCategoryFilterEdit(listBase)
        
    }

    const closeModal = ()=>{
        setImgUpload('');
    }

    const showEdit = (data:TBrand) =>{
        
        let listView:TCategory[] = [];
        const listShop =  [];
        const listBase =  data.shop;
        const listCatBase = data.categories;
        const currentCategories:TCategory[] = [...listCategoryData];

        for(let iShop in listShopsData)
        {
            const item = listShopsData[iShop];
            item.checked = false;
            const inShopEdit = listBase.findIndex(IShopE=>IShopE===item.name);

            if(inShopEdit>-1){ item.checked = true; }
            
            listShop.push(item);
        }

        for(let indexSh in listBase){

            const itemShop = listBase[indexSh];

            for(let iCat in currentCategories ){   
                    const item:TCategory = currentCategories[iCat];
                    let checked = false
                    const currentIdex = listView.findIndex(lItem=>lItem.name===item.name)

                    const inCat =  listCatBase.findIndex(catItem=>catItem===item.name);

                    if(inCat>-1){ checked = true; }

                    if(item.type===itemShop && currentIdex === -1 ){ 
                        listView.push({
                            _id: item._id,
                            name: item.name,
                            nameEs: item.nameEs,
                            size: item.size,
                            type: item.type,
                            image: item.image,
                            checked:checked
                        })  
                    }
                }
        }

        setValEdit("_id",data._id);
        setValEdit("name",data.name);
        setValEdit("shop",data.shop);
        setValEdit("categories",data.categories);
        setListShopsEdit(listShop);
        setListCategoryFilterEdit(listView)
        setStateEdit(true);
    }

    const removeItem = (id:string)=>{
        console.log("remove by id")
        console.log(id)
        removeBrand(id)
        .then(data=>{

            let dataList = listBrandsData;  

            var indexitem = dataList.findIndex((item)=>item._id===id);
            dataList.slice(indexitem,1) ;

            //setListBrandsData(dataList);
        })
        .catch(error=>{

        })//*/

    }

    const sendForm = (data:any) =>
    {
        const form = new FormData();

        let tag = data.name.toString().toLowerCase();
        tag = tag.replace(" ","");

        form.append("image",data.imageRaw.file);
        form.append("name", data.name);
        form.append("shop",JSON.stringify( data.shop));
        form.append("categories", JSON.stringify(data.categories));
        form.append("tag", tag);

        const alertMsg = messageAlert;

        addBrand(form)
        .then(data=>{
            
            alertMsg.icon = 'success'; 
            alertMsg.title = 'Shop Added'; 
            alertMsg.text = 'Has been successfully added'; 

            commonMsg(alertMsg);

            reset({
                name:"",
                shop:[],
                categories:[]
            })

            setCurrentCroppedImg(null);
            setImgPreview("");
            setPageBrands(1);
            getBrands();
        })
        .catch(error=>{
            alertMsg.icon = 'error'; 
            alertMsg.title = 'Brand don´t Added'; 
            alertMsg.text = 'It was not possible to add'; 

            commonMsg(alertMsg);
        });
    }

    const updateBrand = (data:any)=>{

        console.log(" update form ",data);

       
        let tag = data.name.toString().toLowerCase();

        tag = tag.replace(" ","");

        const form = new FormData();
        form.append("id", data._id);

        if(data.imageRaw){ form.append("image",data.imageRaw.file); }

        form.append("image",currentCroppedImg);
        form.append("name",data.name );
        form.append("shop",JSON.stringify( data.shop));
        form.append("categories", JSON.stringify(data.categories));
        form.append("tag", tag);

        const alertMsg = messageAlert;

        editBrand(form)
        .then(result=>{
            
            const proList = [...listBrandsData];
            const currentPos = proList.findIndex((item)=>item._id===data._id);
            
            alertMsg.icon = 'success'; 
            alertMsg.title = 'Shop Edited'; 
            alertMsg.text = 'Has been successfully Edited'; 
            commonMsg(alertMsg);

            proList[currentPos].name = data.name;
            proList[currentPos].shop = data.shop;
            proList[currentPos].categories = data.categories;

            if(result.image.length>0){ proList[currentPos].image = data.image; }

            setListBrandsData(proList);

            setStateEdit(false);

            rsEdit({
                _id:"",
                name:"",
                shop:[],
                categories:[]
            })
            
            setImgPreviewEdit("");
            setEditCroppedImg(null);
        })
        .catch(error=>{

            console.log(error);

            alertMsg.icon = 'error'; 
            alertMsg.title = 'Brand don´t edited'; 
            alertMsg.text = 'It was not possible to edited'; 

            commonMsg(alertMsg);
        });//*/
    }

    const closeEdit = () =>{
        setStateEdit(false);
        setValue("shop",[]);

        rsEdit({
            _id:"",
            name:"",
            shop:[],
            categories:[]
        })

        setImgPreviewEdit("");
        setEditCroppedImg(null);
    }

    useEffect(()=>{
        console.log("load data shops")

        setPageBrands(1);
        getShops();
        getCategories();
        getBrands();
        register("imageRaw",{required:true})

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return(
        <div className='cnt-general cnt-cms' >
            <div>
                <button>
                    <Link to="/admin/menu" >regresar</Link>
                </button>
            </div>
            <h4>Brands</h4>
            <div>
                <form onSubmit={handleSubmit(sendForm)}>
                    <ImageItem
                        title="Add an Image"
                        list={[]}
                        preview={true}
                        cropModal={true}
                        aspect={4/3}
                        onChange={changeImages}
                        name="imageRaw"
                        errors={errors}
                        errorsmsn={errorsFormBrands["imageRaw"]}
                        />
                    <InputItem
                            title="Name"
                            name="name"
                            required={true}
                            register={register}
                            errors={errors}
                            errorsmsn={errorsFormBrands["name"]}
                            labelStyle = "input-label-Third"
                            inputStyle = "full-width"
                        />
                    <h6 className="h6-form" >List Shops</h6>
                    <div className="module-list" >
                        {listShopsData.length>0 &&(
                            listShopsData.map(item=>{

                                return(
                                    <div key={generateKey("shops_"+item._id)}  className="item" >
                                        <InputItem
                                            name={item.name}
                                            checkTitle={item.name}
                                            type="checkbox"
                                            labelStyle = "input-label"
                                            inputStyle = "full-width"
                                            value={item._id}
                                            onChange={selectShop}
                                            check={item.checked}
                                        />
                                    </div>
                                )
                            })
                            )
                        }
                    </div>
                    <h6 className="h6-form" >List Categories</h6>
                    <div className="module-list" >
                        {listCategoryFilter.length > 0 && (
                                listCategoryFilter.map(item=>{
                                        
                                        return(
                                            <div key={generateKey("categories_"+item._id)}  className="item" >
                                                <InputItem
                                                    name={item.name}
                                                    checkTitle={item.name+" / "+item.nameEs}
                                                    type="checkbox"
                                                    labelStyle = "input-label"
                                                    inputStyle = "full-width"
                                                    value={item._id}
                                                    onChange={selectCategory}
                                                    check={item.checked}
                                                />
                                            </div>
                                        )
                                })
                            )
                        }
                    </div>
                    <button>Add Brand</button>
                </form>
                <hr/>
            </div>
            <div>
                <div className="title-module" >
                    <h3>List</h3>
                </div>
                <div className="parent-table" >
                    <table className="table" >
                        <thead>
                            <tr>
                                <th>Image</th>
                                <th>Name</th>
                                <th>Shops</th>
                                <th>Categories</th>
                                <th className="controls" ></th>
                            </tr>
                        </thead>
                        <tbody>
                        {listBrandsData.length>0 &&
                            (
                                listBrandsData.map((item)=>
                                {


                                    return(
                                        <tr key={generateKey("brand_"+item._id)} >
                                            <td>
                                                <img src={getImage(item.image)} className="img-custom" alt={"delujo"} />
                                            </td>
                                            <td>{item.name}</td>
                                            <td>
                                                {item.shop.length > 0 &&

                                                    item.shop.map((iShop:any)=>{
                                                        return(
                                                            <div key={generateKey("shop_l_"+item._id+"_"+iShop)} >{iShop}</div>
                                                        )
                                                    })

                                                }
                                            </td>
                                            <td>
                                                {item.categories.length > 0 &&

                                                        item.categories.map((iCag:any)=>{
                                                            return(
                                                                <div key={generateKey("catg_l_"+item._id+"_"+iCag)} >{iCag}</div>
                                                            )
                                                        })
                                                }
                                            </td>
                                            <td>
                                                <button className="edit"   onClick={()=>{showEdit(item)}} >
                                                    <GetIcon type="fas" icon="pen" color="#FFFFFF"/>
                                                    Edit
                                                </button>
                                                <button className="remove" onClick={()=>{removeItem(item._id)}}  >x</button>

                                                
                                            </td>
                                        </tr>
                                    )
                                })
                            )
                        }

                        </tbody>
                    </table>
                </div>
                {moreBrands &&
                    <button onClick={moreBrandsLoad} >Load More</button>
                }
                <br />
                <br />
                <br />
            </div>
            { stateEdit &&
                <div className="modal-base"  >
                    <div className='background' ></div>
                    <div className="view-edit" >
                        <form onSubmit={handEdit( updateBrand)}>
                            <ImageItem
                                title="Add an Image"
                                list={[]}
                                preview={true}
                                cropModal={true}
                                aspect={4/3}
                                onChange={changeImagesUpdate}
                                name="imageRaw"
                                />
                            <InputItem
                                title="Name"
                                name="name"
                                required={true}
                                register={regEdit}
                                errors={errorsEdit}
                                errorsmsn={errorsFormBrands["name"]}
                                labelStyle = "input-label-Third"
                                inputStyle = "full-width"
                            />
                            <h6 className="h6-form" >List Shops</h6>
                            <div className="module-list" >
                            {listShopsEdit.length>0 &&(
                                listShopsEdit.map(item=>{

                                    return(
                                        <div key={generateKey(item._id)}  className="item" >
                                            <InputItem
                                                name={item.name}
                                                checkTitle={item.name}
                                                type="checkbox"
                                                labelStyle = "input-label"
                                                inputStyle = "full-width"
                                                value={item._id}
                                                onChange={selectShopEdit}
                                                check={item.checked}
                                                
                                            />
                                        </div>
                                    )
                                })
                                )
                            }
                            </div>
                            <h6 className="h6-form" >List Categories</h6>
                            <div className="module-list" >
                                {listCategoryFilterEdit.length > 0 && (
                                    listCategoryFilterEdit.map(item=>{
                                        
                                            return(
                                                <div key={generateKey(item._id)}  className="item" >
                                                    <InputItem
                                                        name={item.name}
                                                        checkTitle={item.name+" / "+item.nameEs}
                                                        type="checkbox"
                                                        labelStyle = "input-label"
                                                        inputStyle = "full-width"
                                                        value={item._id}
                                                        onChange={selectCategoryEdit}
                                                        check={item.checked}
                                                    />
                                                </div>
                                            )//*/
                                    })
                                )
                                }
                            </div>
                            <button>Edit</button>
                        </form>
                        <hr/>
                        <button onClick={closeEdit} >Cancel Edit</button>
                    </div>
                </div>
            }

        </div>
    )

}