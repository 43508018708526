import axios from 'axios';
import { url_server,header_token } from '../../constants/global';

export const listBrands = async (page:Number) =>{
    const result =  await axios.get(url_server+"/api/brands/list?page="+page,header_token);
    //const result =  await axios.get(url_server+"/api/brands/list?page="+page,header_token);

    return result.data;
}

export const addBrand = async (data:any) =>{

    const result = await axios.post(url_server+"/api/brands/create",data,header_token);

    return result.data;
}

export const editBrand = async (data:any) =>{

    const result = await axios.post(url_server+"/api/brands/edit",data,header_token);

    return result.data;
}

export const removeBrand = async (data:string) =>{
    const result =  await axios.delete(url_server+"/api/brands/delete/"+data,header_token);

    return result.data;
}

export const searchBrand = async (data:string)=>{
    const result =  await axios.get(url_server+"/api/brands/search/"+data,header_token);

    return result.data;
}