import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useNavigate  } from 'react-router-dom';

export const LinkComponent = ()=>{

    const parmansData = useParams(); 
    const [type,setType] = useState("");
    const [value,setValue] = useState("");
    const [isMobile,setIsMobile] = useState(false);
    const navigate = useNavigate();

    const checkBrowser = () =>{

        setType(parmansData.type || "");
        setValue(parmansData.url || "");
        
        if(navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i))
        {
            console.log(" chek browser si entra---");
            console.log("paramenter ",parmansData);
            setIsMobile(true);

            window.location.replace('delujo://link/'+parmansData.type+"/"+parmansData.url);   

        }else{
            console.log("paramenter ",parmansData);


        }
    }

    
    useEffect(()=>{
        
        checkBrowser();

    },[])



    return (
        <div className='cnt-general' >
            {type==="product"?
                <div className="top-separate" >  
                    {isMobile?
                        <a href={`delujo://link/${type}/${value}`} >Ir al Producto {value}</a>
                    :
                        <a href={`/product/${value}`} >Ir al Producto {value}</a>
                    }
                </div>

                :
                type==="user"?

                <div className="top-separate" >ir al Usuario</div>
                :
                <div className="top-separate" >ir al inicio</div>
            }
            

        </div>






    )
}