const SET_TOKEN = "set_token";
const SET_TOKEN_REFRESH = "set_token_refresh";

export const setTokenUser = (data:string) => {

    if(data)
        localStorage.setItem(SET_TOKEN,data);
    else
        localStorage.removeItem(SET_TOKEN);

};

export const setTokenRefreshUser = (data:string) => {

    if(data)
        localStorage.setItem(SET_TOKEN_REFRESH,data);
    else
        localStorage.removeItem(SET_TOKEN_REFRESH);
};

export const  getTokenUser = () =>{

    return localStorage.getItem(SET_TOKEN);
}

export const  getTokenRefreshUser = () =>{

    return localStorage.getItem(SET_TOKEN_REFRESH);
}