import { Link  } from 'react-router-dom';
import React from 'react'
import { GetFeed } from '../services/feed.service';
import { handle } from '../utils/PromiseHandler';
import icon from './../assets/img/iconov1.png';

function Feed() {
  React.useEffect(()=>{
    obtenerFeed()
  },[])

  const obtenerFeed = async () => {
    const [result, error] = await handle(
      GetFeed (
        0,
        "",
        "",
        "",
        ""
      )
    );

    if (error) {
      console.log("Error GETFEED", error);
      return;
    }

    console.log(result)
  }
  return (
    <div className="ml-5">
      <div className="row">
        <div className="col-md-3 text-center border">.col-md-3</div>
        <div className="col-md-5 border ml-4 mr-4">
          <div className="card m-3">
            <img className="card-img-top img-thumbnail" src={icon} alt="Card image cap" style={{width: "18rem"}} />
            <div className="card-body">
              <h5 className="card-title">Card title</h5>
              <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
              <a href="#" className="btn btn-primary">Go somewhere</a>
            </div>
          </div>
          <div className="card m-3">
            <img className="card-img-top img-thumbnail" src={icon} alt="Card image cap" style={{width: "18rem"}} />
            <div className="card-body">
              <h5 className="card-title">Card title</h5>
              <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
              <a href="#" className="btn btn-primary">Go somewhere</a>
            </div>
          </div>
          <div className="card m-3">
            <img className="card-img-top img-thumbnail" src={icon} alt="Card image cap" style={{width: "18rem"}} />
            <div className="card-body">
              <h5 className="card-title">Card title</h5>
              <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
              <a href="#" className="btn btn-primary">Go somewhere</a>
            </div>
          </div>
          <div className="card m-3">
            <img className="card-img-top img-thumbnail" src={icon} alt="Card image cap" style={{width: "18rem"}} />
            <div className="card-body">
              <h5 className="card-title">Card title</h5>
              <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
              <a href="#" className="btn btn-primary">Go somewhere</a>
            </div>
          </div>
        </div>
        <div className="col-md-3 text-center border">.col-md-3</div>
      </div>
    </div>
  );
}
export default Feed;
