import { useEffect, useState } from "react";

interface IInput{
    title?:string,
    name?:string,
    register?:any,
    required?:boolean,
    labelStyle?:string,
    errors?:any
    errorsmsn?:string,
}

export const TextAreaItem = (props:IInput)=>{

    const [classLabel,setClassLabel] = useState<string>('input-label');

    useEffect(()=>{

        if(props.labelStyle)
        {  
            let classL = classLabel+" "+props.labelStyle
            setClassLabel(classL);
        }


    },[])

    return(
        <label className={classLabel}  >
            {props.title && <span>{props.title} </span> } 
            {props.required && <span className="requiere" >*</span> }
            <textarea
                {...props.register && props.register(props.name,{required:props.required })} 
            
            ></textarea>
            {props.register &&  props.name && props.name && props.errors && props.errors[props.name]  &&  
                <span className="errorMsn" >* {props.errorsmsn}</span>  
            } 
        </label>
    )

}