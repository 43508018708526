import { Link } from "react-router-dom"

export const CarrierComponent = () =>{

    /*const getListBuyProducts = ()=>{



    }


    const getBuyedBuldens = () =>{

    }//*/

    


return(
    <div className='cnt-general' >
        <div>
                <button>
                    <Link to="/admin/menu" >regresar</Link>
                    </button>
            </div>
        
    </div>
)

}