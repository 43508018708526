import { useEffect, useState } from "react";
import { getImage, generateKey } from '../../utils/commons';
import { GetIcon, commonMsg } from "../../utils/commons";
import { listShops } from "../../services/admin/Shop";
import { TShop, TCategory } from "../../interfaces/IShop";
import { CropImage } from "../../utils/cropImage";
import { add, edit, listCategories, removeItem } from "../../services/admin/Category";
import { IMessage } from "../../interfaces/ICommons";
import { Link } from "react-router-dom";
import { InputItem } from "../commons/FormITems/InputItem";
import { useForm } from "react-hook-form";
import { errorsFormCategory } from "../../errors/errorsShops";
import { DropItem } from "../commons/FormITems/DropItem";
import { ImageItem } from "../commons/FormITems/ImageItem";


const sizeList:any = [
    {index:"ft",value:"Foot wear"},
    {index:"ftk",value:"Fooot Wear Kids"},
    {index:"kd",value:"Kids"},
    {index:"os",value:"Only Size"},
    {index:"un",value:"Universal (x,s,m,l, etc)"},
]

const messageAlert:IMessage={
    icon:'info',
    title:'',
    text:''
}


export const CategoriesComponent = () =>{

    const {register,handleSubmit,formState:{errors},setValue, getValues,reset  } = useForm<TCategory>();
    const {register:regEdit,handleSubmit:handEdit,formState:{errors:errorsEdit},setValue:setValEdit,getValues:getValEdit, reset:rsEdit } = useForm<TCategory>();
    const [imgPreview,setImgPreview] = useState<string>();
    const [listShopsData,setListShopsData ] = useState<TShop[]>([]);
    const [listCategoriesData,setListCategoriesData ] = useState<TCategory[]>([]);
    const [imgUpload,setImgUpload] = useState<any>();
    const [currentCroppedImg,setCurrentCroppedImg] = useState<any>();
    const [imgPreviewEdit,setImgPreviewEdit] = useState<string>();
    const [editCroppedImg,setEditCroppedImg] = useState<any>(null);
    const [stateEdit,setStateEdit] = useState(false);

    const getShops = () => {

        listShops()
        .then(data=>{

            setListShopsData(data.data);
            setValue("type",data.data[0].name)

        }).catch(error=>{

        })//*/

    }

    const getCategories = () =>{

        listCategories()
        .then(data=>{
            setListCategoriesData(data.data);
        })
        .catch(error=>{

        });//*/

    }

    const changeImages = (data:any)=>{ setValue("imageRaw",data[0]) }
    const changeImagesUpdate = (data:any)=>{ setValEdit("imageRaw",data[0]) }

    const closeModal = ()=>{
        setImgUpload('');
    }

    const showEdit = (data:TCategory) =>{
        

        console.log(" show edit ",data)

        setValEdit("_id",data._id)
        setValEdit("name",data.name)
        setValEdit("nameEs",data.nameEs)
        setValEdit("size",data.size)
        setValEdit("type",data.type)

        setStateEdit(!stateEdit);
    }

    const updateCategory = (data:any)=>{

        //event.preventDefault();

        console.log(" update data ",data);

        const form = new FormData();

        if(data.imageRaw){ form.append("image",data.imageRaw.file); }

        form.append("id",data._id );
        form.append("name",data.name );
        form.append("nameEs",data.nameEs );
        form.append("size",data.size );
        form.append("type",data.type );

        const alertMsg = messageAlert;

        edit(form)
        .then(result=>{

            const proList = [...listCategoriesData];
            const currentPos = proList.findIndex((item)=>item._id===data._id);
            
            proList[currentPos].name = data.name;
            proList[currentPos].nameEs = data.nameEs;
            proList[currentPos].size = data.size;
            proList[currentPos].type = data.type;


            alertMsg.icon = 'success'; 
            alertMsg.title = 'Category Edited'; 
            alertMsg.text = 'Has been successfully Edit'; 

            commonMsg(alertMsg);

            if(data.image.length>0){
                
                proList[currentPos].image = data.image;
            }

            setListCategoriesData(proList);

            setStateEdit(false);

            rsEdit({
                _id:"",
                name:"",
                nameEs:"",
                size:"",
                type:""
            })

            setImgPreviewEdit("");
            setEditCroppedImg(null);

        })
        .catch(error=>{

            console.log(error);

            alertMsg.icon = 'error'; 
            alertMsg.title = 'Category don´t edited'; 
            alertMsg.text = 'It was not possible to edit'; 

            commonMsg(alertMsg);

        });//*/

    } 

    const removeCategory = (id:string)=>{
        console.log("remove by id")
        console.log(id)
        removeItem(id)
        .then(data=>{

            console.log(data);
            getCategories();

        })
        .catch(error=>{

        })

    }

    const closeEdit = () =>{
        setStateEdit(false);

        rsEdit({
            _id:"",
            name:"",
            nameEs:"",
            size:"",
            type:""
        })

        setImgPreviewEdit("");
        setEditCroppedImg(null);
    }

    const sendForm = (data:any) =>
    {

        const form = new FormData();
        console.log(" save new brand::::  ",data);

        form.append("image",data.imageRaw.file);
        form.append("name", data.name);
        form.append("nameEs", data.nameEs);
        form.append("size", data.size);
        form.append("type", data.type);
        
        const alertMsg = messageAlert;

        add(form)
        .then(data=>{

            alertMsg.icon = 'success'; 
            alertMsg.title = 'Category Added'; 
            alertMsg.text = 'Has been successfully added'; 

            commonMsg(alertMsg);

            reset({
                name:"",
                nameEs:"",
                size:sizeList[0].index,
                type:listShopsData[0].name
            })

            setCurrentCroppedImg(null);
            setImgPreview("");
            getShops();
        })
        .catch(error=>{

            alertMsg.icon = 'error'; 
            alertMsg.title = 'Category don´t Added'; 
            alertMsg.text = 'It was not possible to add'; 

            commonMsg(alertMsg);

        });    
    }

    useEffect(()=>{
        console.log("load data shops")
        getShops();
        getCategories();
        setValue("size",sizeList[0].index)
        register("imageRaw",{required:true})

    },[]);


    return(
        <div className='cnt-general cnt-cms' >
             <div>
                <button>
                    <Link to="/admin/menu" >regresar</Link>
                    </button>
            </div>
            <h4>Categories</h4> 
            <div>
                <form onSubmit={handleSubmit(sendForm)}>
                    <ImageItem
                        title="Add an Image"
                        list={[]}
                        preview={true}
                        cropModal={true}
                        aspect={4/3}
                        onChange={changeImages}
                        name="imageRaw"
                        errors={errors}
                        errorsmsn={errorsFormCategory["imageRaw"]}
                        />
                    <InputItem
                            title="Name"
                            name="name"
                            required={true}
                            register={register}
                            errors={errors}
                            errorsmsn={errorsFormCategory["name"]}
                            labelStyle = "input-label-Third"
                            inputStyle = "full-width"
                        />
                    <InputItem
                            title="Name Spanish"
                            name="nameEs"
                            register={register}
                            labelStyle = "input-label-Third"
                            inputStyle = "full-width"
                        />
                    {sizeList.length>0 &&

                    <DropItem
                        title="Size"
                        name="size"
                        keyValue="cat"
                        value="index"
                        view={["value"]}
                        required={true}
                        register={register}
                        labelStyle = "input-label-Third"
                        inputStyle = "full-width"
                        list={sizeList}
                        iCheck={getValues("size")}
                        />
                    }
                    {listShopsData.length>0 &&
                    <DropItem
                        title="Type"
                        name="type"
                        keyValue="shop"
                        value="name"
                        view={["name","nameEs"]}
                        separactor=" / "
                        required={true}
                        register={register}
                        labelStyle = "input-label-Third"
                        inputStyle = "full-width"
                        list={listShopsData}
                        iCheck={getValues("type")}
                        />
                    }
                    <button>Add Category</button>
                </form>
                <hr/>
               
            </div>
            <div>
                <div className="title-module" >
                    <h3>List</h3>
                </div>
                <div className="parent-table" >
                    <table className="table" >
                        <thead>
                            <tr>
                                <th>Image</th>
                                <th>Name</th>
                                <th>Size</th>
                                <th>Shop</th>
                                <th className="controls" ></th>

                            </tr>
                        </thead>
                        <tbody>
                        {listCategoriesData.length>0 &&
                            (
                                listCategoriesData.map((item)=>
                                {
                                    return(
                                        <tr key={generateKey(item._id)} >
                                            <td>
                                                {
                                                    item.image !=="" ? <img src={getImage(item.image)} className="img-custom" alt={"delujo"} /> 
                                                    :
                                                    <GetIcon type="fas" icon="file-image" size="3x" color="#aa7845"/>
                                                }   
                                            </td>
                                            <td>
                                                <div>
                                                    <label className="sub-title" >English:</label>  {item.name}
                                                </div>
                                                <div>
                                                    <label className="sub-title" >spanish: </label> {item.nameEs}
                                                </div>
                                                
                                            </td>
                                            <td>{item.size}</td>
                                            <td>{item.type}</td>
                                            <td>
                                                <button className="edit"   onClick={()=>{showEdit(item)}} >
                                                    <GetIcon type="fas" icon="pen" color="#FFFFFF"/>
                                                    Edit
                                                </button>
                                                <button className="remove" onClick={()=>{removeCategory(item._id)}}  >x</button>
                                            </td>
                                        </tr>
                                    )
                                })
                            )
                        }

                        </tbody>
                    </table>
                </div>
            </div>
            { stateEdit &&
                <div className="modal-base"  >
                    <div className='background' ></div>
                    <div className="view-edit" >
                        <form onSubmit={handEdit(updateCategory)}>
                            <ImageItem
                                title="Add an Image"
                                list={[]}
                                preview={true}
                                cropModal={true}
                                aspect={4/3}
                                onChange={changeImagesUpdate}
                                name="imageRaw"
                                />
                            <InputItem
                                title="Name"
                                name="name"
                                required={true}
                                register={regEdit}
                                errors={errors}
                                errorsmsn={errorsFormCategory["name"]}
                                labelStyle = "input-label-Third"
                                inputStyle = "full-width"
                            />
                            <InputItem
                                    title="Name Spanish"
                                    name="nameEs"
                                    register={regEdit}
                                    labelStyle = "input-label-Third"
                                    inputStyle = "full-width"
                                />
                            {sizeList.length>0 &&

                            <DropItem
                                title="Size"
                                name="size"
                                keyValue="size"
                                value="index"
                                view={["value"]}
                                required={true}
                                register={regEdit}
                                labelStyle = "input-label-Third"
                                inputStyle = "full-width"
                                list={sizeList}
                                iCheck={getValEdit("size")}
                                />
                            }
                             
                            {listShopsData.length>0 &&
                            <DropItem
                                title="Type"
                                name="type"
                                keyValue="type"
                                value="name"
                                view={["name","nameEs"]}
                                separactor=" / "
                                required={true}
                                register={register}
                                labelStyle = "input-label-Third"
                                inputStyle = "full-width"
                                list={listShopsData}
                                iCheck={getValEdit("type")}
                                />
                            }
                            <button>Edit</button>
                        </form>
                        <hr/>
                        <button onClick={closeEdit} >Cancel Edit</button>
                    </div>
                </div>
            }
        </div>
    )
} 
