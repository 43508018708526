import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { Link } from "react-router-dom";
import { IMessage, IMessageConfirm } from "../../interfaces/ICommons";
import { addCity, addCountry, addDeparment, addLanguage, editCity, editCountry, editDeparment, editLanguage, getCities, getCitiesAdmin, getCountries, getCountriesAdmin, getDeparments, getDeparmentsAdmin, getlanguages, getListDeparmentByCountry, removeCityItem, removeCountryEvent, removeDeparment, removeLangItem } from "../../services/admin/Nationalities";
import Accordion  from "@mui/material/Accordion";
import AccordionSummary  from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { commonConfirm, commonMsg, generateKey, GetIcon, getImage } from "../../utils/commons";
import { InputItem } from "../commons/FormITems/InputItem";
import { TCity, TCountry, TDeparment, TLanguaje } from "../../interfaces/INationality";
import { errorsFormCity, errorsFormCountry, errorsFormDepart, errorsFormLang } from "../../errors/errorsNationalities";
import { ImageItem } from "../commons/FormITems/ImageItem";
import { DropItem } from "../commons/FormITems/DropItem";

//alert format
const messageAlert:IMessage={
    icon:'info',
    title:'',
    text:''
}

const messageConfirm:IMessageConfirm={
    icon:'info',
    title:'',
    text:'',
    callback:()=>{}
}

//star component
export const NationalityComponent = () => {

    //#region variables commons
    const [expanded,setExpanded ] = useState<string|false>(false);
    const [editCroppedImg,setEditCroppedImg] = useState<any>(null);
    //#endregion
    //#region variables languajes
    const {register:regLang,handleSubmit:handLang,formState:{errors:errorLang},setValue:setLang,reset:rslang  } = useForm<TLanguaje>();
    const [listLanguage,setListLanguage] = useState<TLanguaje[]>([]);
    const [listLanguageSelect,setListLanguageSelect] = useState<TLanguaje[]>([]);
    const [langEditState,setLangEditState] = useState<boolean>(false);
    const {register:regLangEdit,handleSubmit:handLangEdit,formState:{errors:errorLangEdit},setValue:setValLangEdit,reset:rslangEdit  } = useForm<TLanguaje>();

    //#endregion
    //#region variables countries

    const {register:regCountry,handleSubmit:handCountry,formState:{errors:errorCountry},setValue:setValCountry,getValues:getValCountry, reset:rsCountry,watch:wtCountry  } = useForm<TCountry>();
    const {register:regEditCountry,handleSubmit:handEditCountry,formState:{errors:errorEditCountry},setValue:setValEditCountry,getValues:getValEditCountry,reset:rsEditCountry  } = useForm<TCountry>();
    const [listCountries,setListCountries] = useState<TCountry[]>([]);
    const [countryEditState,setCountryEditState] = useState<boolean>(false);
    //deparment
    const {register:regDepar,handleSubmit:handDepar,formState:{errors:errorDepar},setValue:setValDepar,getValues:getValDepart,reset:rsDepart} = useForm<TDeparment>()
    const {register:regEditDepar,handleSubmit:handEditDepar,formState:{errors:errorEditDepar},setValue:setValEditDepar,getValues:getValEditDepart,reset:rsEditDepart} = useForm<TDeparment>()
    const [listDeparments,setListDeparments] = useState<TDeparment[]>([]);
    const [deparmentEditState,setDeparmentEditState] = useState<boolean>(false)
    //city

    const {register:regCity,handleSubmit:handCity,formState:{errors:errorCity},setValue:setValCity,getValues:getValCity,reset:rsCity } = useForm<TCity>();
    const {register:regEditCity,handleSubmit:handEditCity,formState:{errors:errorEditCity},setValue:setValEditCity,getValues:getValEditCity,reset:rsEditCity } = useForm<TCity>();
    const [listCities,setListCities] = useState<TCity[]>([]);
    const [listSelectDeparment,setListSelectDeparment] = useState<TDeparment[]>([]);
    const [listSelectDeparmentEdit,setListSelectDeparmentEdit] = useState<TDeparment[]>([]);
    const [cityEditState,setCityEditState] = useState<boolean>(false);

    //#endregion

    //#region function languajes
    const sendNewLanguage = async (data:any)=>{

        const alertMsg = messageAlert;

        addLanguage(data)
        .then(data=>{

            alertMsg.icon = 'success';
            alertMsg.title = 'Language Added';
            alertMsg.text = data.msn;

            commonMsg(alertMsg);

            rslang({name:"",nameEs:"",iso:""});
            getLanguage();
        })
        .catch(error=>{
            alertMsg.icon = 'error';
            alertMsg.title = 'Language not Added';
            alertMsg.text = error;

            commonMsg(alertMsg);
        });//*/

    }

    const getLanguage = async () =>{
        const listLanguage = await  getlanguages();
        const listLanguageVis = listLanguage.filter((item:any)=>item.visible===1);

        setListLanguage(listLanguage);
        setListLanguageSelect(listLanguageVis)

        if(listLanguageVis.length>-1){ setValCountry("countryLangId",listLanguageVis[0]._id); }
    }

    const showEditLanguage = (data:any)=>{

        setValLangEdit('_id',data._id);
        setValLangEdit('name',data.name);
        setValLangEdit('nameEs',data.nameEs);
        setValLangEdit('iso',data.iso);
        setValLangEdit('visible',true);

        setLangEditState(!langEditState);
    };

    const hiddenEditLanguage = ()=>setLangEditState(!langEditState);

    const sendUpdateLanguaje = (data:any)=>{

        const alertMsg = messageAlert;

        console.log(" SEND UPDATE LANGUAGE");


        editLanguage(data)
        .then(data=>{

            alertMsg.icon = 'success';
            alertMsg.title = 'Language Edit';
            alertMsg.text = data.msn;

            commonMsg(alertMsg);

            const langList = [...listLanguage];
            const currentPos = langList.findIndex((itemLang)=>itemLang._id===data._id);

            langList[currentPos].name = data.name;
            langList[currentPos].nameEs = data.nameEs;
            langList[currentPos].iso = data.iso;
            langList[currentPos].visible = data.visible;

            setListLanguage(langList);

            setLangEditState(false);

            rslangEdit({
                '_id':"",
                'name':"",
                'nameEs':"",
                'iso':"",
                'visible':false
            })
        })
        .catch(error=>{

            console.log(error );

            if(error.data.status === 204){  return;}

            alertMsg.icon = 'error';
            alertMsg.title = 'Language not Added';
            alertMsg.text = error;

            commonMsg(alertMsg);

        });//*/

    }

    const removeLanguage = (id:string,name:string)=>{

        const confirm = messageConfirm;

        confirm.icon = 'question';
        confirm.title = 'Remove language';
        confirm.text = 'Are you sure you want to delete '+name+'?';
        confirm.callback = sendRemoveLang;
        confirm.data =  id;

        commonConfirm(confirm)
    }

    const sendRemoveLang = (item:any)=>{

        console.log(" eliminar el siguiente dato ",item);
        removeLangItem(item)
        .then(data=>{

            //const langList = [...listLanguage];
            //const currentPos = langList.findIndex((itemLang)=>itemLang._id===data._id);
            //langList.splice(currentPos,1)

            getLanguage();
            //setListLanguage(langList);

        })
        .catch(error=>{

        })
    }
    //#endregion

    //#region country
    const sendFormNacionality = (data:any) =>
    {
        //event.preventDefault();
        console.log("form data country",data);

        const form =  new FormData();

        form.append("image",data.imageRaw.file);
//        form.append("image",currentCroppedImg);
        form.append("name",data.name);
        form.append("nameEs",data.nameEs);
        form.append("currency",data.currency);
        form.append("currencyName",data.currencyName);
        form.append("countrylanguage",data.countryLangId);
        form.append("callingCode",data.callingCode);
        form.append("iso",data.iso);

        const alertMsg = messageAlert;

        addCountry(form)
        .then(result=>{
            alertMsg.icon = 'success';
            alertMsg.title = 'Nationality Added';
            alertMsg.text = 'Has been successfully added';

            commonMsg(alertMsg);

            rsCountry({image:"",imageRaw:[],name:"",nameEs:"",currency:"",currencyName:"",
                countrylanguage:{_id:"", name:"",nameEs:"",iso:""},
                callingCode:"",iso:""
                });

            getNationalities();
            
        })
        .catch(error=>{
            alertMsg.icon = 'error';
            alertMsg.title = 'Language not Added';
            alertMsg.text = " failed add country";

            commonMsg(alertMsg);
        })
    }

    const getNationalities = async ()=>{
        const listCountries = await  getCountriesAdmin();
        setListCountries(listCountries);

        if(listCountries.length>-1)
        {
            setValDepar("countryId",listCountries[0]._id)
            setValCity("countryId",listCountries[0]._id)

            const listDepto = await getListDeparmentByCountry(listCountries[0]._id)

            setListSelectDeparment(listDepto);
            setValCity("stateId",listDepto[0]._id);
        }
    }

    const showEditCountry = (data:any)=>{

        console.log("data",{data});

        setValEditCountry("_id",data._id);
        setValEditCountry("name",data.name);
        setValEditCountry("nameEs",data.nameEs);
        setValEditCountry("currency",data.currency);
        setValEditCountry("currencyName",data.currencyName);
        setValEditCountry("countryLangId",data.countryLangId);
        setValEditCountry("iso",data.iso);
        setValEditCountry("callingCode",data.callingCode);
        setValEditCountry("visible",data.visible);

        setCountryEditState(!countryEditState);
    };

    const removeCountry = (id:string,name:string)=>{
        const confirm = messageConfirm;

        confirm.icon = 'question';
        confirm.title = 'Remove this country';
        confirm.text = 'Are you sure you want to delete '+name+'?';
        confirm.callback = sendRemoveCountry;
        confirm.data =  id;

        commonConfirm(confirm)
    }

    const sendRemoveCountry = (item:any)=>{

        console.log(" eliminar el siguiente dato ",item);
        removeCountryEvent(item)
        .then(data=>{

            getNationalities();
        })
        .catch(error=>{

        })//*/
    }

    const hiddenEditCountry = ()=>{ setCountryEditState(!countryEditState) };

    const sendUpdateCountry = (data:any)=>{

        const form = new FormData();
        form.append("id", data._id );

        if(data.imageRaw){ form.append("image",data.imageRaw.file); }

        form.append("name",data.name );
        form.append("nameEs",data.nameEs );
        form.append("currency",data.currency );
        form.append("currencyName",data.currencyName );
        form.append("currencyNameEs",data.currencyNameEs );
        form.append("countrylanguage",data.countryLangId );
        form.append("iso",data.iso );
        form.append("callingCode",data.callingCode+"" );
        form.append("visible",data.visible+"" );

        const alertMsg = messageAlert;

        editCountry(form)
        .then(result=>{

            alertMsg.icon = 'success';
            alertMsg.title = 'Nationality Added';
            alertMsg.text = 'Has been successfully update';

            commonMsg(alertMsg);

            const proList = [...listCountries];
            const currentPos = proList.findIndex((item)=>item._id===data._id);

            proList[currentPos].name = data.name;
            proList[currentPos].nameEs = data.nameEs;
            proList[currentPos].currency = data.currency;
            proList[currentPos].currencyName = data.currencyName;
            //proList[currentPos].currencyNameEs = data.currencyNameEs+"";
            proList[currentPos].countryLangId = data.countryLangId;
            proList[currentPos].countrylanguage = result.countrylanguage;
            proList[currentPos].callingCode = data.callingCode+"";

            if(result.image.length>0){ proList[currentPos].image = data.image; }

            setListCountries(proList)
            setCountryEditState(false);

            rsEditCountry({image:"",imageRaw:[],name:"",nameEs:"",currency:"",currencyName:"",
            countrylanguage:{_id:"", name:"",nameEs:"",iso:""},
            callingCode:"",iso:""
            })

            setEditCroppedImg(null);
        })
        .catch(error=>{

            alertMsg.icon = 'error';
            alertMsg.title = 'Language not Update';
            alertMsg.text = " failed to edit country";

            commonMsg(alertMsg);
        });//*/
    }
    //#endregion

    //#region departaments
    const getDeparmentsData = async () =>{
        const listDepaData = await  getDeparmentsAdmin();
        setListDeparments(listDepaData);

        //if(listCountries.length>-1){ setFormDeparment(EventInputCustom("country",listCountries[0]._id)); }
    }

    const sendFormDeparment = (data:any)=>{

        const alertMsg = messageAlert;

        addDeparment(data)
        .then(result=>{

            alertMsg.icon = 'success';
            alertMsg.title = 'Municipality Added';
            alertMsg.text = 'Has been successfully added';

            commonMsg(alertMsg);

            rsDepart({name:""});

            getDeparmentsData();

        })
        .catch(error=>{
            alertMsg.icon = 'error';
            alertMsg.title = 'Municipality don\'t Added';
            alertMsg.text = 'Has been ocurre an error';

            commonMsg(alertMsg);
        });
    }

    const showEditDeparment = (data:any)=>{

        console.log("data deparment:::  ",{data});
        setValEditDepar("_id",data._id)
        setValEditDepar("name",data.name)
        setValEditDepar("country",data.country)
        setValEditDepar("countryId",data.countryId)
        setValEditDepar("visible",data.visible)

        setDeparmentEditState(!deparmentEditState);
    }

    const sendUpdateDeparment = (data:any)=>{


        const alertMsg = messageAlert;

        editDeparment(data)
        .then(result=>{

            alertMsg.icon = 'success';
            alertMsg.title = 'Municipality Edid';
            alertMsg.text = 'Has been successfully edit';

            commonMsg(alertMsg);

            const proList = [...listDeparments];
            const currentPos = proList.findIndex((item)=>item._id===data._id);

            proList[currentPos].name = data.name;

            rsEditDepart({name:""});

            setDeparmentEditState(false);
        })
        .catch(error=>{
            alertMsg.icon = 'error';
            alertMsg.title = 'Deparment not Update';
            alertMsg.text = " failed to edit states";

            commonMsg(alertMsg);
        })
    }

    const hiddenEditDeparment = ()=>setDeparmentEditState(!deparmentEditState);

    const removeState = (id:string,name:string)=>{
        const confirm = messageConfirm;

        confirm.icon = 'question';
        confirm.title = 'Remove this State';
        confirm.text = 'Are you sure you want to delete '+name+'?';
        confirm.callback = sendRemoveState;
        confirm.data =  id;

        commonConfirm(confirm)
    }

    const sendRemoveState = (item:any)=>{

        console.log(" eliminar el siguiente dato ",item);
        removeDeparment(item)
        .then(data=>{

            getDeparmentsData();
        })
        .catch(error=>{

        })//*/
    }
    //#endregion

    //#region cities
    const getCitiesData = async () =>{
        const listDepaData = await  getCitiesAdmin();
        setListCities(listDepaData);

        //if(listCountries.length>-1){ setFormDeparment(EventInputCustom("country",listCountries[0]._id)); }
    }

    const sendFormCity = (data:any)=>{

        console.log("form data country",data);

        const alertMsg = messageAlert;

        addCity(data)
        .then(result=>{
            
            alertMsg.icon = 'success';
            alertMsg.title = 'City Added';
            alertMsg.text = 'Has been successfully added';

            commonMsg(alertMsg);

            rsCity({name:""});

            getCitiesData();
            
        })
        .catch(error=>{
            alertMsg.icon = 'error';
            alertMsg.title = 'City don\'t Added';
            alertMsg.text = 'Has been ocurre an error';

            commonMsg(alertMsg);
        })

    }

    const showEditCities = (data:any)=>{

        setValEditCity("_id",data._id);
        setValEditCity("name",data.name);
        setValEditCity("country",data.country);
        setValEditCity("countryId",data.countryId);
        setValEditCity("state",data.state);
        setValEditCity("stateId",data.state._id);
        setValEditCity("visible",data.visible);

        getListDeparmentByCountry(data.country._id)
            .then(data=>{

                setListSelectDeparmentEdit(data);
            })
            .catch(error=>{

            });

        setCityEditState(!cityEditState);
    }

    const sendUpdateCity = (data:any)=>{
       
        console.log(" edit data",{data});

        const alertMsg = messageAlert;

        editCity(data)
        .then(data=>{
            alertMsg.icon = 'success';
            alertMsg.title = 'City Edited';
            alertMsg.text = 'Has been successfully edited';

            commonMsg(alertMsg);

            rsEditCity({name:""});

            getCitiesData();

            setCityEditState(!cityEditState)
        })
        .catch(error=>{
            alertMsg.icon = 'error';
            alertMsg.title = 'City don\'t Edited';
            alertMsg.text = 'Has been ocurre an error';

            commonMsg(alertMsg);
        })
    }

    const hiddenEditCity = ()=>setCityEditState(!cityEditState);

    const removeCityState = (id:string,name:string)=>{
        const confirm = messageConfirm;

        confirm.icon = 'question';
        confirm.title = 'Remove this city';
        confirm.text = 'Are you sure you want to delete '+name+'?';
        confirm.callback = sendRemoveCity;
        confirm.data =  id;

        commonConfirm(confirm)
    }

    const sendRemoveCity = (item:any)=>{

        console.log(" eliminar el siguiente dato ",item);
        removeCityItem(item)
        .then(data=>{

            getCitiesData();
        })
        .catch(error=>{

        })//*/
    }

    //#endregion

    //#region general functions

    const initData = ()=>{
        // props.register(props.name,{required:props.required }

        regCountry("imageRaw",{required:true})

    }

    const changeImages = (data:any)=>{ setValCountry("imageRaw",data[0]) }
    const changeImagesUpdate = (data:any)=>{ setValEditCountry("imageRaw",data[0]) }

    const handleAccordion = (panel:string)=>(event:React.SyntheticEvent,isExpanded:boolean)=>{
        setExpanded(isExpanded?panel:false);
    }
    //#endregion

    useEffect(()=>{

        initData()
        getLanguage();
        getNationalities();
        getDeparmentsData();
        getCitiesData();

    },[])

    return(
        <div className='cnt-general cnt-cms' >
            <div>
                <button>
                    <Link to="/admin/menu" >Return</Link>
                    </button>
            </div>
            <Accordion expanded={expanded==='panel1'} onChange={handleAccordion('panel1')}   >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <h4>Languages</h4>
                </AccordionSummary>
                <AccordionDetails>
                <div>
                    <h6 className="h6-form" >Add New Language</h6>
                    <form onSubmit={handLang(sendNewLanguage)} >
                        <InputItem
                            title="Name"
                            name="name"
                            required={true}
                            register={regLang}
                            errors={errorLang}
                            errorsmsn={errorsFormLang["name"]}
                            labelStyle = "input-label-Third"
                            inputStyle = "full-width"
                        />
                        <InputItem
                            title="Name Spanish"
                            name="nameEs"
                            register={regLang}
                            labelStyle = "input-label-Third"
                            inputStyle = "full-width"
                        />
                        <InputItem
                            title="Iso 639-1"
                            name="iso"
                            required={true}
                            register={regLang}
                            errors={errorLang}
                            errorsmsn={errorsFormLang["iso"]}
                            labelStyle = "input-label-Third"
                            inputStyle = "full-width"
                        />
                        <button>Add Language</button>
                    </form>
                </div>
                <h6 className="h6-form" >List Language</h6>
                    <div className="parent-table" >
                        <table className="table" >
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Iso</th>
                                    <th>Visible</th>
                                    <th className="controls" ></th>
                                </tr>
                            </thead>
                            <tbody>
                                {listLanguage.length>0 &&(
                                    listLanguage.map(item=>{
                                            let check = false;

                                        if(item.visible){ check = true; }

                                        return(
                                            <tr key={generateKey(item._id)}   >
                                                <td>
                                                    <div>
                                                        <label className="sub-title" >
                                                            English:&nbsp; 
                                                        </label>
                                                        {item.name}
                                                    </div>
                                                    <div>
                                                        <label className="sub-title" >Spanish:&nbsp;</label>
                                                        {item.nameEs}
                                                    </div>
                                                </td>
                                                <td> {item.iso}  </td>
                                                <td> 
                                                    {check ? <label className="visible" >Visible</label>:<label className="no-visible" >No Visible</label>}    
                                                </td>
                                                <td>
                                                    <button className="edit" onClick={()=>{showEditLanguage(item)}}  >
                                                        <GetIcon type="fas" icon="pen" color="#FFFFFF"/>
                                                        Edit</button>
                                                    <button className="remove" onClick={()=>{removeLanguage(item._id,item.name+"/"+item.nameEs)}}>x</button>
                                                </td>
                                            </tr>
                                        )
                                        })
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded==='panel2'} onChange={handleAccordion('panel2')} >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <h4>Nationality</h4>
                </AccordionSummary>
                <AccordionDetails>
                <div>
                <form onSubmit={handCountry(sendFormNacionality)}>
                    <ImageItem
                        title="Add a Flag"
                        list={[]}
                        preview={true}
                        cropModal={true}
                        aspect={4/3}
                        onChange={changeImages}
                        name="imageRaw"
                        errors={errorCountry}
                        errorsmsn={errorsFormCountry["imageRaw"]}
                        />
                    <InputItem
                            title="Name"
                            name="name"
                            required={true}
                            register={regCountry}
                            errors={errorCountry}
                            errorsmsn={errorsFormCountry["name"]}
                            labelStyle = "input-label-Third"
                            inputStyle = "full-width"
                        />
                    <InputItem
                            title="Name Spanish"
                            name="nameEs"
                            required={true}
                            register={regCountry}
                            errors={errorCountry}
                            errorsmsn={errorsFormCountry["nameEs"]}
                            labelStyle = "input-label-Third"
                            inputStyle = "full-width"
                        />
                        <InputItem
                            title="Currency"
                            name="currency"
                            required={true}
                            register={regCountry}
                            errors={errorCountry}
                            errorsmsn={errorsFormCountry["currency"]}
                            labelStyle = "input-label-Third"
                            inputStyle = "full-width"
                        />
                        <InputItem
                            title="Currency Name"
                            name="currencyName"
                            required={true}
                            register={regCountry}
                            errors={errorCountry}
                            errorsmsn={errorsFormCountry["currencyName"]}
                            labelStyle = "input-label-Third"
                            inputStyle = "full-width"
                        />
                        {listLanguageSelect.length>0 &&

                            <DropItem
                                title="Country Language"
                                name="countryLangId"
                                keyValue="lang"
                                value="_id"
                                view={["name","nameEs"]}
                                separactor=" / "
                                required={true}
                                register={regCountry}
                                labelStyle = "input-label-Third"
                                inputStyle = "full-width"
                                list={listLanguageSelect}
                                iCheck={getValCountry("countryLangId")}
                                />
                        }
                        <InputItem
                            title="Iso 4217"
                            name="iso"
                            register={regCountry}
                            labelStyle = "input-label-Third"
                            inputStyle = "full-width"
                        />
                        <InputItem
                            title="Calling Code"
                            name="callingCode"
                            required={true}
                            register={regCountry}
                            errors={errorCountry}
                            errorsmsn={errorsFormCountry["callingCode"]}
                            labelStyle = "input-label-Third"
                            inputStyle = "full-width"
                        />
                    <div className="row-button" >
                        <button>Add Country</button>
                    </div>
                </form>
                <hr/>
                <h6 className="h6-form" >List Countries</h6>
                    <div className="parent-table" >
                        <table className="table" >
                        <thead>
                            <tr>
                                <th>Image</th>
                                <th>Name</th>
                                <th>Language</th>
                                <th>Calling Code</th>
                                <th>State</th>
                                <th>Currency</th>
                                <th className="controls" ></th>
                            </tr>
                        </thead>
                        <tbody>
                            {listCountries.length>0 &&(
                                listCountries.map(item=>{

                                    item.countryLangId = item.countrylanguage._id;

                                    return(
                                        <tr key={generateKey(item._id)}   >
                                            <td> <img className="img-flag" src={getImage(item.image)} alt={"delujo"} /> </td>
                                            <td>
                                                <label className="sub-title" >English:</label>{item.name} 
                                                <label className="sub-title" >Spanish:</label> {item.nameEs} 
                                            </td>
                                            <td>
                                                <label className="sub-title" >English:</label>{item.countrylanguage.name} 
                                                <label className="sub-title" >Spanish:</label>{item.countrylanguage.nameEs} 
                                            </td>
                                            <td>{item.callingCode}</td>
                                            <td>
                                                {item.visible ? <label className="visible" >Visible</label>:<label className="no-visible" >No Visible</label>}
                                            </td>
                                            <td>
                                                <div>{item.currencyName}</div>
                                                <div>{item.currency}</div>
                                            </td>
                                            <td>
                                                <button className="edit" onClick={()=>{showEditCountry(item)}} >
                                                    <GetIcon type="fas" icon="pen" color="#FFFFFF"/>
                                                    Edit
                                                </button>
                                                <button className="remove" onClick={()=>{removeCountry(item._id,item.name+"/"+item.nameEs)}}>x</button>
                                            </td>
                                        </tr>
                                    )
                                })
                                )
                            }
                        </tbody>
                        </table>
                    </div>
                </div>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded==='panel3'} onChange={handleAccordion('panel3')} >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                >
                    <h4>Municipality/Deparment/State</h4>
                </AccordionSummary>
                <AccordionDetails>
                    <div>
                        <form onSubmit={handDepar(sendFormDeparment)} >
                            <InputItem
                                title="Name"
                                name="name"
                                required={true}
                                register={regDepar}
                                errors={errorDepar}
                                errorsmsn={errorsFormDepart["name"]}
                                labelStyle = "input-label-Third"
                                inputStyle = "full-width"
                            />
                            {listCountries.length>0 &&
                                <DropItem
                                    title="Country"
                                    name="countryId"
                                    keyValue="country"
                                    value="_id"
                                    view={["name","nameEs"]}
                                    separactor=" / "
                                    required={true}
                                    register={regDepar}
                                    labelStyle = "input-label-Third"
                                    inputStyle = "full-width"
                                    list={listCountries}
                                    iCheck={getValDepart("countryId")}
                                    />
                            }

                        <button>Add Municipality/Deparment/State</button>
                        </form>
                        <hr/>
                        <h6 className="h6-form" >List Municipality/Deparment/State</h6>
                        <div className="parent-table" >
                            <table className="table" >
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Country</th>
                                        <th>State</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listDeparments.length>0 &&(
                                        listDeparments.map(item=>{
                                            return(
                                                <tr key={generateKey("depa_"+item._id)}   >
                                                    <td>{item.name}</td>
                                                    <td>{item.country?.name}</td>
                                                    <td>
                                                        {item.visible ? <label className="visible" >Visible</label>:<label className="no-visible" >No Visible</label>}
                                                    </td>
                                                    
                                                    <td>
                                                        <button className="edit" onClick={()=>{showEditDeparment(item)}} >
                                                            <GetIcon type="fas" icon="pen" color="#FFFFFF"/>
                                                            Edit
                                                        </button>
                                                        <button className="remove" onClick={()=>{removeState(item._id+"",item.name)}}>x</button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded==='panel4'} onChange={handleAccordion('panel4')} >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4a-content"
                    id="panel4a-header"
                >
                    <h4>City</h4>
                </AccordionSummary>
                <AccordionDetails>
                    <div>
                        <form  onSubmit={handCity( sendFormCity)} >
                            <InputItem
                                title="Name"
                                name="name"
                                required={true}
                                register={regCity}
                                errors={errorCity}
                                errorsmsn={errorsFormCity["name"]}
                                labelStyle = "input-label-Third"
                                inputStyle = "full-width"
                            />
                            {listCountries.length>0 &&
                                <DropItem
                                    title="Country"
                                    name="countryId"
                                    keyValue="country_city"
                                    value="_id"
                                    view={["name","nameEs"]}
                                    separactor=" / "
                                    required={true}
                                    register={regCity}
                                    labelStyle = "input-label-Third"
                                    inputStyle = "full-width"
                                    list={listCountries}
                                    iCheck={getValCity("countryId")}
                                    />
                            }
                            {listSelectDeparment.length>0 &&
                                <DropItem
                                    title="State"
                                    name="stateId"
                                    keyValue="deparments_city"
                                    value="_id"
                                    view={["name"]}
                                    required={true}
                                    register={regCity}
                                    labelStyle = "input-label-Third"
                                    inputStyle = "full-width"
                                    list={listSelectDeparment}
                                    iCheck={getValCity("stateId")}
                                    />
                            }
                            <button>Add City</button>
                        </form>
                        <hr/>
                        <h6 className="h6-form" >List City</h6>
                        <div className="parent-table" >
                            <table className="table" >
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Country</th>
                                        <th>State</th>
                                        <th>Status</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                    <tbody>
                                    {listCities.length>0 &&(
                                        listCities.map(item=>{
                                            return(
                                                <tr key={generateKey("city_"+item._id)}   >
                                                    <td>{item.name}</td>
                                                    <td>{item.country?.name}</td>
                                                    <td>{item.state?.name}</td>
                                                    <td>
                                                        {item.visible ? <label className="visible" >Visible</label>:<label className="no-visible" >No Visible</label>}
                                                    </td>
                                                    <td>
                                                        <button className="edit" onClick={()=>{showEditCities(item)}} >
                                                            <GetIcon type="fas" icon="pen" color="#FFFFFF"/>
                                                            Edit
                                                        </button>
                                                        <button className="remove" onClick={()=>{removeCityState(item._id+"",item.name)}}>x</button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                        )
                                    }

                                    </tbody>
                            </table>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
            {langEditState && <div className="modal-base" >
                    <div className='background' ></div>
                    <div className="view-edit" >
                    <form  onSubmit={handLangEdit(sendUpdateLanguaje)}>
                        <InputItem
                            title="Name"
                            name="name"
                            required={true}
                            register={regLangEdit}
                            errors={errorLangEdit}
                            errorsmsn={errorsFormLang["name"]}
                            labelStyle = "input-label-Third"
                            inputStyle = "full-width"
                        />
                        <InputItem
                            title="Name Spanish"
                            name="nameEs"
                            register={regLangEdit}
                            labelStyle = "input-label-Third"
                            inputStyle = "full-width"
                        />
                        <InputItem
                            title="Iso 639-1"
                            name="iso"
                            required={true}
                            register={regLangEdit}
                            errors={errorLangEdit}
                            errorsmsn={errorsFormLang["iso"]}
                            labelStyle = "input-label-Third"
                            inputStyle = "full-width"
                        />
                        <InputItem
                            checkTitle="Visible"
                            name="visible"
                            type="checkbox"
                            register={regLangEdit}
                            labelStyle = "input-label-Third"
                            inputStyle = "full-width"
                        />
                        <div>
                            <button>Edit Languaje</button>
                        </div>
                    </form>
                        <hr/>
                        <button onClick={()=>{hiddenEditLanguage()}} >Cancel Shop</button>
                    </div>
                </div>
            }
            {/*edit country */ }
            {countryEditState && <div className="modal-base" >
                    <div className='background' ></div>
                    <div className="view-edit" >
                    <form onSubmit={handEditCountry( sendUpdateCountry)}>
                        <ImageItem
                            title="Add a Flag"
                            list={[]}
                            preview={true}
                            cropModal={true}
                            aspect={4/3}
                            onChange={changeImagesUpdate}
                            name="imageRaw"
                            />
                        <InputItem
                            title="Name"
                            name="name"
                            required={true}
                            register={regEditCountry}
                            errors={errorEditCountry}
                            errorsmsn={errorsFormCountry["name"]}
                            labelStyle = "input-label-Third"
                            inputStyle = "full-width"
                        />
                        <InputItem
                            title="Name Spanish"
                            name="nameEs"
                            required={true}
                            register={regEditCountry}
                            errors={errorEditCountry}
                            errorsmsn={errorsFormCountry["nameEs"]}
                            labelStyle = "input-label-Third"
                            inputStyle = "full-width"
                        />
                        <InputItem
                            title="Currency"
                            name="currency"
                            required={true}
                            register={regEditCountry}
                            errors={errorEditCountry}
                            errorsmsn={errorsFormCountry["currency"]}
                            labelStyle = "input-label-Third"
                            inputStyle = "full-width"
                        />
                        <InputItem
                            title="Currency Name"
                            name="currencyName"
                            required={true}
                            register={regEditCountry}
                            errors={errorEditCountry}
                            errorsmsn={errorsFormCountry["currencyName"]}
                            labelStyle = "input-label-Third"
                            inputStyle = "full-width"
                        />
                        <DropItem
                            title="Country Language"
                            name="countryId"
                            keyValue="lang_country_edit"
                            value="_id"
                            view={["name","nameEs"]}
                            separactor="/"
                            required={true}
                            register={regEditCountry}
                            labelStyle = "input-label-Third"
                            inputStyle = "full-width"
                            list={listLanguageSelect}
                            iCheck={getValEditCountry("countryLangId")}
                        />
                        <InputItem
                            title="Iso 4217"
                            name="iso"
                            register={regEditCountry}
                            labelStyle = "input-label-Third"
                            inputStyle = "full-width"
                        />
                        <InputItem
                            title="Calling Code"
                            name="callingCode"
                            required={true}
                            register={regEditCountry}
                            errors={errorEditCountry}
                            errorsmsn={errorsFormCountry["callingCode"]}
                            labelStyle = "input-label-Third"
                            inputStyle = "full-width"
                        />
                        <InputItem
                            checkTitle="Visible"
                            name="visible"
                            type="checkbox"
                            register={regEditCountry}
                            labelStyle = "input-label-Third"
                            inputStyle = "full-width"
                        />
                            <div>
                                <button>Edit Country</button>
                            </div>
                        </form>
                        <hr/>
                        <button onClick={()=>hiddenEditCountry()} >Cancel Edit</button>
                    </div>
                </div>
            }
            {/*edit deparments*/}
            {deparmentEditState && <div className="modal-base" >
                <div className='background' ></div>
                <div className="view-edit" >
                    <form onSubmit={handEditDepar(sendUpdateDeparment)}>
                        <InputItem
                            title="Name"
                            name="name"
                            required={true}
                            register={regEditDepar}
                            errors={errorEditDepar}
                            errorsmsn={errorsFormDepart["name"]}
                            labelStyle = "input-label-Third"
                            inputStyle = "full-width"
                            />
                        {listCountries.length>0 &&
                                <DropItem
                                    title="Country"
                                    name="countryId"
                                    keyValue="country_deparment"
                                    value="_id"
                                    view={["name","nameEs"]}
                                    separactor=" / "
                                    required={true}
                                    register={regEditDepar}
                                    labelStyle = "input-label-Third"
                                    inputStyle = "full-width"
                                    list={listCountries}
                                    iCheck={getValEditDepart("countryId")}
                                    />
                        }
                        <InputItem
                            checkTitle="Visible"
                            name="visible"
                            type="checkbox"
                            register={regEditDepar}
                            labelStyle = "input-label-Third"
                            inputStyle = "full-width"
                        />
                        <button>Edit Municipality/Deparment/State</button>
                    </form>
                    <hr/>
                    <button onClick={()=>{hiddenEditDeparment()}} >Cancel Edit</button>
                </div>
                </div>
            }
            {/*edit city*/}
            {cityEditState && <div className="modal-base" >
                <div className='background' ></div>
                <div className="view-edit" >
                    <form onSubmit={handEditCity(sendUpdateCity)}>
                        <InputItem
                            title="Name"
                            name="name"
                            required={true}
                            register={regEditCity}
                            errors={errorEditCity}
                            errorsmsn={errorsFormCity["name"]}
                            labelStyle = "input-label-Third"
                            inputStyle = "full-width"
                            />
                        {listCountries.length>0 &&
                            <DropItem
                                title="Country"
                                name="countryId"
                                keyValue="country_city_edit"
                                value="_id"
                                view={["name","nameEs"]}
                                separactor=" / "
                                required={true}
                                register={regEditCity}
                                labelStyle = "input-label-Third"
                                inputStyle = "full-width"
                                list={listCountries}
                                iCheck={getValEditCity("countryId")}
                                />
                        }
                        {listSelectDeparmentEdit.length>0 &&
                            <DropItem
                                title="State"
                                name="stateId"
                                keyValue="depto_city_edit"
                                value="_id"
                                view={["name"]}
                                required={true}
                                register={regEditCity}
                                labelStyle = "input-label-Third"
                                inputStyle = "full-width"
                                list={listSelectDeparmentEdit}
                                iCheck={getValEditCity("stateId")}
                                />
                        }
                        <InputItem
                            checkTitle="Visible"
                            name="visible"
                            type="checkbox"
                            register={regEditCity}
                            labelStyle = "input-label-Third"
                            inputStyle = "full-width"
                        />
                        <button>Edit City</button>
                    </form>
                    <hr/>
                    <button onClick={()=>{hiddenEditCity()}} >Cancel Edit</button>
                </div>
            </div>
            }
        </div>
    )

}