import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { listCategories } from "../../services/admin/Category";
import { listShops } from "../../services/admin/Shop";
import { commonConfirm, commonMsg,  generateKey, GetIcon, getImage } from "../../utils/commons";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { searchBrand } from "../../services/admin/brands";
import { CropImage } from "../../utils/cropImage";
import { addParty, editParties, getListParties, removeParty } from "../../services/admin/Party";
import { IMessage, IMessageConfirm } from "../../interfaces/ICommons";
import { TBrand, TCategory, TShop } from "../../interfaces/IShop";
import { useForm } from "react-hook-form";
import { TParty } from "../../interfaces/IParty";
import { errorsFormParty } from "../../errors/errorsParty";
import { InputItem } from "../commons/FormITems/InputItem";
import { TextAreaItem } from "../commons/FormITems/TextAreaItem";
import { ImageItem } from "../commons/FormITems/ImageItem";

const messageAlert:IMessage={
    icon:'info',
    title:'',
    text:''
}

const messageConfirm:IMessageConfirm={
    icon:'info',
    title:'',
    text:'',
    callback:()=>{}
}

export const PartyComponent = ()=>{

    const {register,handleSubmit,formState:{errors},setValue, getValues,reset  } = useForm<TParty>();
    const {register:regEdit,handleSubmit:handEdit,formState:{errors:errorsEdit},setValue:setValEdit,getValues:getValEdit, reset:rsEdit } = useForm<TParty>();
    const [listShopsData,setListShopsData] = useState<TShop[]>([]);
    const [listCategoriesBase,setListCategoriesBase] = useState<TCategory[]>([]);
    const [listCategoryFilter,setListCategoryFilter] = useState<TCategory[]>([]);
    const [listBrandsData,setListBrandsData ] = useState<TBrand[]>([]);
    const [listBrandsSelect,setListBrandsSelect ] = useState<TBrand[]>([]);
    const [imgUpload,setImgUpload] = useState<any>();
    const [imgUploadType,setimgUploadType] = useState<any>();
    const [imageWeb,setImageWeb] = useState<string>();
    const [imageMobile,setImageMobile] = useState<string>();
    const [imgPreviewEdit,setImgPreviewEdit] = useState<string>();
    const [editCroppedImgWeb,setEditCroppedImgWeb] = useState<any>(null);
    const [editCroppedImgMobile,setEditCroppedImgMbile] = useState<any>(null);
    const [dateStart,setDateStart] = useState<Date>(new Date());
    const [dateEnd,setDateEnd] = useState<Date>(new Date());
    const [wordSearch,setWordSearch] = useState("");
    const [listParties,setListParties ] = useState<TParty[]>([]);

    //edit const 
    const [listShopsDataEdit,setListShopsDataEdit] = useState<TShop[]>([]);
    const [listCategoriesDataEdit,setListCategoriesDataEdit] = useState<TCategory[]>([]);
    const [listBrandsSelectEdit,setListBrandsSelectEdit ] = useState<TBrand[]>([]);
    const [listBrandsDataEdit,setListBrandsDataEdit ] = useState<TBrand[]>([]);
    const [listCategoryFilterEdit,setListCategoryFilterEdit] = useState<TCategory[]>([]);
    const [dateStartEdit,setDateStartEdit] = useState<Date>(new Date());
    const [dateEndEdit,setDateEndEdit] = useState<Date>(new Date());
    const [showPartyEdit,setShowPartyEdit] = useState<boolean>(false)
    const [wordSearchEdit,setWordSearchEdit] = useState("");

    const loadDates = async ()=>{

        const start = dateStart.getFullYear()+"/"+(dateStart.getMonth()+1)+"/"+dateStart.getDate()+" "+dateStart.getHours()+":"+dateStart.getMinutes();
        const end = dateEnd.getFullYear()+"/"+(dateEnd.getMonth()+1)+"/"+dateEnd.getDate()+" "+dateEnd.getHours()+":"+dateEnd.getMinutes();

        setValue("datestart",start)
        setValue("dateend",end)
    }

    const loadShops = async ()=>{

        const listS = await listShops();
        setListShopsData([...listS.data])
    }
 
    const loadCategories = async ()=>{
        const listCa = await listCategories();
        setListCategoriesBase(listCa.data);
    }

    const loadParties = async ()=>{

        const ListParties = await getListParties();
        setListParties(ListParties)
    }

    const changeImageMobile = (data:any)=>{ setValue("imageMobile",data[0]) }
    const changeImageWeb = (data:any)=>{ setValue("imageWeb",data[0]) }
    const changeImageMobileEdit = (data:any)=>{ setValue("imageMobile",data[0]) }
    const changeImageWebEdit = (data:any)=>{ setValue("imageWeb",data[0]) }

    const changeShop = (event:any)=>
    {
        const listBase:TShop[] = [...listShopsData];
        let listPro:any = getValues("shops") || [];
        let currentCategories:TCategory[] = [...listCategoriesBase]; 
        const value =  event.target.value;

        const indexShop =  listBase.findIndex(itemShop=>itemShop._id===value);

        if(event.target.checked)
        {
            listPro.push(listBase[indexShop]);
            listBase[indexShop].checked = true;
        }else
        {
            const iShopFilter = listPro.findIndex((itemSel:string)=>itemSel===listBase[indexShop].name);

            if(iShopFilter>-1){
                listPro.splice(Number(iShopFilter) ,1);
                listBase[indexShop].checked = false;
            }
        }

        let listView:TCategory[] = [];

        for(let indexSh in listBase)
        {
            const shoName = listBase[indexSh]

            if(shoName.checked){
                for(let indexCg in currentCategories)
                {
                    const itemCag =currentCategories[indexCg]

                    const index =  listView.findIndex((viewItem:any)=>viewItem.name===itemCag.name); 

                    if(itemCag.type===shoName.name && index === -1){ 
                        listView.push({
                            _id: itemCag._id,
                            name: itemCag.name,
                            nameEs: itemCag.nameEs,
                            size: itemCag.size,
                            type: itemCag.type,
                            image: itemCag.image
                        }); 
                    }
                }
            }
        }

        setValue("shops",listPro)
        setValue("categories",[]);
        setListCategoryFilter([...listView])
        setListShopsData(listBase);
    }

    const changeCategory = (event:any)=>
    {
        const listBase:any =  listCategoryFilter;
        const listPro:any = getValues("categories") || [];
        const value =  event.target.value;

        
        const indexCat:any = listBase.findIndex((iShop:any)=>iShop._id===value); 
        console.log(" change value category::: ",{value,in:indexCat,listBase});

        if(event.target.checked){
            listPro.push(listBase[indexCat]);
            listBase[indexCat].checked = true;
        }else{

            const iCatgFilter = listPro.findIndex((itemSel:string)=>itemSel===listBase[indexCat].name);

            if(iCatgFilter>-1){
                listPro.splice(Number(iCatgFilter) ,1);
                listBase[iCatgFilter].checked = false;
            }
        }

        setValue("categories",listPro)
        setListCategoryFilter(listBase)
    }

    const changeSearchBrand = async (event:any)=>{

        const word = event.target.value;

        if(word.length>0){
            const result = await searchBrand(word);

            console.log("result brands ");
            console.log(result);

            setListBrandsData(result);

        }else{
            setListBrandsData([]);
        }

        setWordSearch(word);
    }

    const selectBrand = (item:any)=>{

        let currentBrands = listBrandsData;
        let localBrands:any = listBrandsSelect;

        const index = currentBrands.findIndex((itemLocal)=>itemLocal.name===item.name);

        if(index>-1)
        { 
            localBrands.push(item)
            setListBrandsSelect(localBrands)
            setValue("brands",localBrands)
        }

        setListBrandsData([]);
        setWordSearch("");
    }

    const removeBrand = (item:any)=>{

        console.log("removeBrand",{item,listBrandsSelect});
        
        let localBrands:any = listBrandsSelectEdit;

        const index = localBrands.findIndex((itemLocal:any)=>itemLocal.name===item.name);

        if(index>-1){
            localBrands.splice(index,1);
            setListBrandsSelect([...localBrands]);
            setValue("brands",localBrands)
        }
    }

    const changeDate = async (type:string,date:Date)=>{

        console.log(" change date ");
        console.log(type);

        const currentDate = date.getFullYear()+"/"+(date.getMonth()+1)+"/"+date.getDate()+" "+date.getHours()+":"+date.getMinutes();

        if(type==="start")
        {

            setDateStart(date);
            //updateFormCustom("datestart",currentDate);
            setValue("datestart",currentDate);
        }else if(type==="end"){
            setDateEnd(date);
            //updateFormCustom("dateend",currentDate);
            setValue("dateend",currentDate);
        }else if(type==="startEdit")
        {
            //partyEditItem.datestart = currentDate;
            setValEdit("datestart",currentDate);
        }else if(type==="endEdit"){
            //partyEditItem.dateend = currentDate;
            setValEdit("dateend",currentDate);
        }
    } 

   

    const closeModalImg = ()=>setImgUpload('');

    const showEdit = (data:TParty) =>{

        const currentCategories = [...listCategoriesBase]; 
        let listView:TCategory[] = [];
        const listShop =  [];
        const listBase:any =  data.shops;
        const listCatBase = data.categories;

        for(let iShop in listShopsData)
        {
            const item = listShopsData[iShop];
            item.checked = false;
            const inShopEdit = listBase.findIndex((IShopE:any)=>IShopE.name===item.name);

            if(inShopEdit>-1){ item.checked = true; }
            
            listShop.push(item);
        }

        for(let indexSh in listBase){

            const itemShop = listBase[indexSh];

            for(let iCat in currentCategories ){   
                    const item:TCategory = currentCategories[iCat];
                    let checked = false
                    const currentIdex = listView.findIndex(lItem=>lItem.name===item.name)

                    const inCat =  listCatBase.findIndex((catItem:any)=>catItem.name===item.name);

                    if(inCat>-1){ checked = true; }

                    if(item.type===itemShop.name && currentIdex === -1 ){ 
                        listView.push({
                            _id: item._id,
                            name: item.name,
                            nameEs: item.nameEs,
                            size: item.size,
                            type: item.type,
                            image: item.image,
                            checked:checked
                        })  
                    }
                }
        }

        setValEdit("_id",data._id);
        setValEdit("name",data.name);
        setValEdit("description",data.description);
        setValEdit("datestart",data.datestart);
        setValEdit("dateend",data.dateend);
        setValEdit("shops",data.shops);
        setValEdit("categories",data.categories);
        setValEdit("brands",data.brands)
        
        setListBrandsSelectEdit([...data.brands])
        setDateStartEdit(new Date(data.datestart))
        setDateEndEdit(new Date(data.dateend))

        setListCategoryFilterEdit([...listView])
        setListShopsDataEdit(listShop);
        setShowPartyEdit(true);
    }

    const changeShopEdit = (event:any)=>
    {
        const listShopCurrent = [...listShopsDataEdit];
        const listPro:any = getValEdit("shops");
        let currentCategories = [...listCategoriesBase]; 
        const value =  event.target.value;

        const indexShop =  listShopCurrent.findIndex(itemShop=>itemShop._id===value);

        if(event.target.checked){ 
            listPro.push(listShopCurrent[indexShop]);
            listShopCurrent[indexShop].checked = true;
        }else{
            const iShopFilter = listPro.findIndex((itemSel:string)=>itemSel===listShopCurrent[indexShop].name);

            if(iShopFilter>-1){
                listPro.splice(Number(iShopFilter) ,1);
                listShopCurrent[indexShop].checked = false;
            }
        }

        let listView:TCategory[] = [];

        for(let indexSh in listPro)
        {
            const shoName = listPro[indexSh]
            
            if(shoName){ 
                
                let filterData = currentCategories.filter((itemCt)=>itemCt.type===shoName.name);
                
                for(let indexCg in filterData)
                {
                    const itemCag =filterData[indexCg]
                    
                    const index =  listView.findIndex((viewItem:any)=>viewItem.name===itemCag.name); 
                    
                    if(index<0){ listView.push(itemCag); }
                    
                }
            }
        }
    
        setValEdit("shops",listPro)
        setValEdit("categories",[]);
        setListCategoryFilterEdit([...listView])
        //setListCategoriesDataEdit([...listView] );
        setListShopsDataEdit([...listShopCurrent]);

        /*

        let listView:TCategory[] = [];

        for(let indexSh in listBase)
        {
            const shoName = listBase[indexSh]

            if(shoName.checked){
                for(let indexCg in currentCategories)
                {
                    const itemCag =currentCategories[indexCg]

                    const index =  listView.findIndex((viewItem:any)=>viewItem.name===itemCag.name); 

                    if(itemCag.type===shoName.name && index === -1){ 
                        listView.push({
                            _id: itemCag._id,
                            name: itemCag.name,
                            nameEs: itemCag.nameEs,
                            size: itemCag.size,
                            type: itemCag.type,
                            image: itemCag.image
                        }); 
                    }
                }
            }
        }

        setValue("shops",listPro)
        setValue("categories",[]);
        setListCategoryFilter([...listView])
        setListShopsData(listBase);*/ 
    }

    const changeCategoryEdit = (event:any)=>
    {
        const localShopFilter:any = getValEdit("shops");
        const listCatCurrent:any =  getValEdit("categories");
        const value = event.target.value;

        //const indexElement = listCatCurrent.findIndex((itemList:any)=>itemList.name===value);

        for(var indexShop in localShopFilter)
        {
            const shop =localShopFilter[indexShop];
            const elements = listCategoriesBase.filter((itemList:any)=>itemList.name===value&& itemList.type===shop.name);

            if(elements.length>0 && event.target.checked){ listCatCurrent.push(elements[0]);
            }else if(elements.length>0){
                const indexCat = listCatCurrent.findIndex((itemCag:any)=>itemCag.name===value && itemCag.type===shop.name );
                listCatCurrent.splice(indexCat,1);
            }//*/
        }

        setListCategoriesDataEdit([...listCategoriesDataEdit])
    }

    const changeSearchBrandEdit = async (event:any)=>{

        console.log(" selecte upade brands ")

        const word = event.target.value;

        if(word.length>0){
            const result = await searchBrand(word);

            setListBrandsDataEdit(result);//*/

        }else{
            setListBrandsDataEdit([]);
        }

        setWordSearchEdit(word);//*/
    }

    const selectBrandEdit = (item:any)=>{

        let currentBrands = listBrandsDataEdit;
        let localBrands:any =  getValEdit("brands");

        const index = currentBrands.findIndex((itemLocal)=>(itemLocal.name)===item.name);

        if(index>-1)
        { 
            localBrands.push(item)
            setListBrandsSelectEdit(localBrands)
        }

        setListBrandsDataEdit([])
        setWordSearchEdit("");
   }

    const removeBrandEdit = (event:any)=>{

        event.preventDefault();
        console.log("remove Brand edit");
        console.log(event);
        console.log(event.target.name);

        let localBrands:any = getValEdit("brands");

        const index = localBrands.findIndex((itemLocal:any)=>(itemLocal.name || itemLocal.value )===event.target.name);

        console.log(index);
        console.log(localBrands);

        if(index>-1){
            localBrands.splice(index,1);
            setListBrandsSelectEdit([...localBrands]);
        }
    }

    const sendParty = async (data:any)=>{
        
        console.log(" ---- enviar form partie ---- ",data)
        
        const form = new FormData();

        //form.append("image",data.imageRaw.file);
        //imageWeb/imageMobile
        if(data.imageWeb){ form.append("images",data.imageWeb.file); }
        if(data.imageMobile ){ form.append("images",data.imageMobile.file); }

        /*if(!editCroppedImgWeb){ return;}
        else{

            form.append("images",editCroppedImgWeb);
        }*/

        if(editCroppedImgMobile){ form.append("images",editCroppedImgMobile); }

        form.append("shops",JSON.stringify(data.shops));
        form.append("categories",JSON.stringify(data.categories));
        form.append("brands",JSON.stringify(data.brands));
        form.append("name",data.name);
        form.append("description",data.description);
        form.append("datestart",data.datestart);
        form.append("dateend",data.dateend);

        const alertMsg = messageAlert;


        addParty(form)
        .then(data=>{

            alertMsg.icon = 'success'; 
            alertMsg.title = 'Party Added'; 
            alertMsg.text = 'Has been successfully added'; 

            commonMsg(alertMsg);

            reset({
                brands:[],
                categories:[],
                datestart:"",
                dateend:"",
                description:"",
                descriptionEs:"",
                shops:[],
                name:"",
            })
            
            loadParties();

        })
        .catch(error=>{
            alertMsg.icon = 'error'; 
            alertMsg.title = 'Party don´t Added'; 
            alertMsg.text = 'It was not possible to add'; 

            commonMsg(alertMsg);
        });//*/

    }

    const updateparty = async (data:any)=>{
        //event.preventDefault();
        
        const form = new FormData();

        if(data.imageWeb){ form.append("images",data.imageWeb.file); }
        if(data.imageMobile ){ form.append("images",data.imageMobile.file); }

        form.append("id",data._id);
        form.append("name",data.name);
        form.append("description",data.description);
        form.append("brands",JSON.stringify(data.brands));
        form.append("shops",JSON.stringify(data.shops));
        form.append("categories",JSON.stringify(data.categories));
        form.append("datestart",data.datestart);
        form.append("dateend",data.dateend);

        const alertMsg = messageAlert;

        editParties(form)
        .then(result=>{
          
            const proList = [...listParties];
            const currentPos = proList.findIndex((item)=>item._id===data._id);

            alertMsg.icon = 'success'; 
            alertMsg.title = 'Party Edit'; 
            alertMsg.text = 'Has been successfully added'; 

            commonMsg(alertMsg);

            proList[currentPos].name = data.name;
            proList[currentPos].description = data.description;
            proList[currentPos].brands = data.brands;
            proList[currentPos].shops = data.shops;
            proList[currentPos].categories = data.categories;
            proList[currentPos].datestart = data.datestart;
            proList[currentPos].dateend = data.dateend;

            if(result.images.length>0){ proList[currentPos].images.mobile = data.image.mobile; }
            setListParties(proList);
            setShowPartyEdit(false)

            rsEdit({
                _id:"",
                name:"",
                description:"",
                brands:[],
                shops:[],
                categories:[],
                datestart:"",
                dateend:""
            });

            setImgPreviewEdit("");

            loadParties();
        })
        .catch(error=>{ 
            console.log(error);

            alertMsg.icon = 'error'; 
            alertMsg.title = 'Party don´t edited'; 
            alertMsg.text = 'It was not possible to edited'; 

            commonMsg(alertMsg);

        });//*/ 
    }

    const removeCouponAlert = (id:string,name:string)=>{
        const confirm = messageConfirm;

        confirm.icon = 'question';
        confirm.title = 'Remove this party';
        confirm.text = 'Are you sure you want to delete '+name+'?';
        confirm.callback = removePartyEvent;
        confirm.data =  id;

        commonConfirm(confirm)
    }

    const removePartyEvent = (id:string)=>{
        console.log("remove by id")
        console.log(id)
        removeParty(id)
        .then(data=>{

            console.log(data);
            loadParties();

        })
        .catch(error=>{

        })//*/

    }

    useEffect(()=>{

        loadDates();
        loadShops();
        loadCategories();
        loadParties();
        register("imageMobile",{required:true})
        register("imageWeb",{required:true})

    },[])

    return(
        <div className='cnt-general cnt-cms' >
            <div>
                <button>
                    <Link to="/admin/menu" >regresar</Link>
                    </button>
            </div>
            <h4>Add Parties</h4>
            <div>
                <form onSubmit={handleSubmit(sendParty)} >
                    <ImageItem
                        title="Add an Image Mobile"
                        list={[]}
                        preview={true}
                        cropModal={true}
                        aspect={4/3}
                        onChange={changeImageMobile}
                        name="imageRaw"
                        errors={errors}
                        errorsmsn={errorsFormParty["imageMobile"]}
                        customName="_mobile"
                        />
                    <ImageItem
                        title="Add an Image Web"
                        list={[]}
                        preview={true}
                        cropModal={true}
                        aspect={16/9}
                        onChange={changeImageWeb}
                        name="imageRaw"
                        errors={errors}
                        errorsmsn={errorsFormParty["imageWeb"]}
                        customName="_web"
                        />
                    <InputItem
                        title="Name"
                        name="name"
                        required={true}
                        register={register}
                        errors={errors}
                        errorsmsn={errorsFormParty["name"]}
                        labelStyle = "input-label-Third"
                        inputStyle = "full-width"
                        />
                    <TextAreaItem 
                        title="Description"
                        name="description"
                        required={true}
                        register={register}
                        errors={errors}
                        errorsmsn={errorsFormParty["description"]}
                        labelStyle="input-label-Third"
                    />
                    <div className="parent-search" >
                        <InputItem
                            title="Search Brand"
                            name="brand"
                            onChange={changeSearchBrand}
                            labelStyle = "input-label-Third"
                            inputStyle = "full-width"
                            value={wordSearch}
                            />
                        <div className="brand-list" >
                            {listBrandsData.length > 0 &&
                                listBrandsData.map((item:any)=>{

                                    return(
                                        <div key={generateKey(item._id)} className="brand-item"  onClick={()=>selectBrand(item)}  >  
                                            {item.name}
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {listBrandsSelect.length>0 &&
                            <div className="cnt-brands" >
                                {listBrandsSelect.map((itemB:any)=>{

                                    return(
                                            <div className="item" key={generateKey(itemB._id)} >
                                                <span>{itemB.name}</span>
                                                <div className="remove" onClick={()=>removeBrand(itemB)} >x</div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }
                    </div>
                    <h6 className="h6-form" >List Shops</h6>
                    <div className="module-list" >
                        {listShopsData.length>0 &&(
                            listShopsData.map(item=>{

                                return(
                                    <div key={generateKey(item._id)}  className="item" >
                                        <InputItem
                                            name={item.name}
                                            checkTitle={item.name}
                                            type="checkbox"
                                            labelStyle = "input-label"
                                            inputStyle = "full-width"
                                            value={item._id}
                                            onChange={changeShop}
                                            check={item.checked}
                                        />
                                    </div>
                                )
                            })
                            )
                        }
                    </div>
                    <h6 className="h6-form" >List Categories</h6>
                    <div className="module-list" >
                        {listCategoryFilter.length>0 &&(
                            listCategoryFilter.map(item=>{
                            
                                return(
                                    <div key={generateKey(item._id)}  className="item" >
                                        <InputItem
                                            name={item.name}
                                            checkTitle={item.name}
                                            type="checkbox"
                                            labelStyle = "input-label"
                                            inputStyle = "full-width"
                                            value={item._id}
                                            onChange={changeCategory}
                                            check={item.checked}
                                        />
                                    </div>
                                )
                            })
                            )
                        }
                    </div>
                    <h6 className="h6-form" >Start Date</h6>
                    <DatePicker selected={dateStart} showTimeSelect inline onChange={(date:Date)=>{changeDate("start",date)}} />
                    <h6 className="h6-form" >End Date</h6>
                    <DatePicker selected={dateEnd}  showTimeSelect inline onChange={(date:Date)=>{changeDate("end",date)}} />
                    <button>Add Party</button>
                </form>
                <hr/>
            </div>
            <div className="title-module" >
                    <h3>List Parties</h3>
                </div>
            <div className="parent-table" >
                <table className="table" >
                    <thead>
                        <tr>
                            <th>Images</th>
                            <th>Name</th>
                            <th>Shops</th>
                            <th>Categories</th>
                            <th>Brands</th>
                            <th>date</th>
                            <th className="controls" ></th>
                        </tr>
                    </thead>
                    <tbody>
                        {listParties.length > 0 &&
                            listParties.map((itemParty:any)=>{

                                return(
                                    <tr key={generateKey("party_"+itemParty._id)} >
                                        <td>
                                            <h5>Image web</h5>
                                            {
                                                itemParty.images.web !=="" ? <img src={getImage(itemParty.images.web)} alt={"delujo"} /> 
                                                :
                                                <GetIcon type="fas" icon="file-image" color="#aa7845"/>
                                            }
                                            <h5>Image Mobile</h5>
                                            {
                                                itemParty.images.mobile !=="" ? <img src={getImage(itemParty.images.mobile)} alt={"delujo"} /> 
                                                :
                                                <GetIcon type="fas" icon="file-image" color="#aa7845"/>
                                            } 
                                        </td>
                                        <td> {itemParty.name} </td>
                                        <td>
                                            {itemParty.shops.length > 0  &&
                                                itemParty.shops.map((itemShPr:any)=>{
                                                    return(
                                                        <div key={generateKey("party_shop_"+itemShPr._id)}  >
                                                            {itemShPr.name}
                                                        </div>
                                                    )
                                                })
                                            }
                                        </td>
                                        <td>
                                            {itemParty.categories.length > 0 &&
                                                itemParty.categories.map((itemCat:any)=>{
                                                    return(
                                                        <div key={generateKey("party_cat_"+itemCat._id)} >
                                                            {itemCat.name}
                                                        </div>
                                                    )
                                                })
                                            }
                                        </td>
                                        <td>
                                            {itemParty.brands.length > 0 &&
                                                itemParty.brands.map((itemBrand:any)=>{
                                                    return(
                                                        <div key={generateKey("party_bra_"+itemBrand._id)} >
                                                            {itemBrand.value}
                                                        </div>
                                                    )
                                                })
                                            }
                                        </td>
                                        <td>
                                            <div><span>Inicio: </span>{itemParty.datestart}</div>
                                            <div><span>End: </span>{itemParty.dateend}</div>
                                        </td>
                                        <td>
                                            <button className="edit"   onClick={()=>{showEdit(itemParty)}} >
                                                <GetIcon type="fas" icon="pen" color="#FFFFFF"/>
                                                Edit
                                            </button>
                                            <button className="remove" onClick={()=>{removeCouponAlert(itemParty._id,itemParty.name)}}  >x</button>
                                        </td>
                                    </tr>
                                )
                            })

                        }
                    </tbody>
                </table>
            </div>
            {showPartyEdit && 
                <div className="modal-base" > 
                    <div className='background' ></div>
                    <div className="view-edit" >
                        <form onSubmit={handEdit(  updateparty)} >
                        <ImageItem
                            title="Add an Image Mobile"
                            list={[]}
                            preview={true}
                            cropModal={true}
                            aspect={4/3}
                            onChange={changeImageMobileEdit}
                            name="imageMobile"
                            customName="_mobile"
                            />
                        <ImageItem
                            title="Add an Image Web"
                            list={[]}
                            preview={true}
                            cropModal={true}
                            aspect={16/9}
                            onChange={changeImageWebEdit}
                            name="imageWeb"
                            customName="_web"
                            />
                            <InputItem
                                title="Name"
                                name="name"
                                required={true}
                                register={regEdit}
                                errors={errorsEdit}
                                errorsmsn={errorsFormParty["name"]}
                                labelStyle = "input-label-Third"
                                inputStyle = "full-width"
                                />
                            <TextAreaItem 
                                title="Description"
                                name="description"
                                required={true}
                                register={regEdit}
                                errors={errorsEdit}
                                errorsmsn={errorsFormParty["description"]}
                                labelStyle="input-label-Third"
                            />
                            <div className="parent-search" >
                                <InputItem
                                    title="Search Brand"
                                    onChange={changeSearchBrandEdit}
                                    labelStyle = "input-label-Third"
                                    inputStyle = "full-width"
                                    value={wordSearchEdit}
                                    />
                                <div className="brand-list" >
                                    {listBrandsDataEdit.length > 0 &&
                                        listBrandsDataEdit.map((item:any)=>{

                                            return(
                                                <div key={generateKey("edit_"+item._id)} className="brand-item"  onClick={()=>selectBrandEdit(item)}  >  
                                                    {item.name}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                {listBrandsSelectEdit.length>0 &&
                                    <div className="cnt-brands" >
                                        {listBrandsSelectEdit.map((itemB:any)=>{

                                            return(
                                                    <div className="item" key={generateKey(itemB._id)} >
                                                        <span>{itemB.name}</span>
                                                        <div className="remove" onClick={()=>removeBrand(itemB)} >x</div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                }
                            </div>
                            <h6 className="h6-form" >List Shops</h6>
                            <div className="module-list" >
                                {listShopsDataEdit.length>0 &&(
                                    listShopsDataEdit.map(item=>{

                                        return(
                                            <InputItem
                                                name={item.name}
                                                checkTitle={item.name}
                                                type="checkbox"
                                                labelStyle = "input-label"
                                                inputStyle = "full-width"
                                                value={item._id}
                                                onChange={changeShopEdit}
                                                check={item.checked}
                                                />
                                            
                                        )
                                    })
                                    )
                                }
                            </div>
                            <h6 className="h6-form" >List Categories</h6>
                            <div className="module-list" >
                            {listCategoryFilterEdit.length>0 &&(
                                listCategoryFilterEdit.map(item=>{
                                
                                    return(
                                        <div key={generateKey(item._id)}  className="item" >
                                            <InputItem
                                                name={item.name}
                                                checkTitle={item.name}
                                                type="checkbox"
                                                labelStyle = "input-label"
                                                inputStyle = "full-width"
                                                value={item._id}
                                                onChange={changeCategory}
                                                check={item.checked}
                                            />
                                        </div>
                                    )
                                })
                                )
                            }
                            </div>
                            <h6 className="h6-form" >Start Date</h6>
                            <DatePicker selected={dateStartEdit} showTimeSelect inline onChange={(date:Date)=>{changeDate("startEdit",date)}} />
                            <h6 className="h6-form" >End Date</h6>
                            <DatePicker selected={dateEndEdit}  showTimeSelect inline onChange={(date:Date)=>{changeDate("endEdit",date)}} />
                            <button>Edit</button>
                        </form>
                        <hr/>
                        <button onClick={()=>setShowPartyEdit(false)} >Cancel Edit</button>
                    </div>
                </div>
            }
        </div>
    )
}