import axios from 'axios';
import { url_server,header_token } from '../../constants/global';
import { getTokenUser } from '../helper/storage';

export const validateUser = async ()=>{
    
    header_token.headers['x-access-token'] = getTokenUser() || "";
    const result = await axios.get(url_server+"/api/users/admin",header_token);

    return result.data;
}


export const listUsers = async () =>{

    const result = await axios.get(url_server+"/api/users/list/admin",header_token);

    return result.data;
}

export const addRole = async (data:any)=>{
    
    header_token.headers['x-access-token'] = getTokenUser() || "";
    const result = await axios.post(url_server+"/api/users/role/add",data,header_token);

    return result.data;
}

export const listRoles = async () =>{

    header_token.headers['x-access-token'] = getTokenUser() || "";
    const result = await axios.get(url_server+"/api/users/roles",header_token);

    return result.data;
}

export const editRole = async (data:any)=>{
    
    header_token.headers['x-access-token'] = getTokenUser() || "";
    const result = await axios.post(url_server+"/api/users/role/edit",data,header_token);

    return result.data;
}





