export const errorsFormLang = {
    name:"You must add a Name",
    iso:"You must add a Iso",
}

export const errorsFormCountry = {
    name:"You must add a Name",
    nameEs:"You must add a Name in spanish",
    currency:"You must add a Currency",
    currencyName:"You must add a Currency name",
    callingCode:"You must add a Calling Code",
    imageRaw:"You must add an Image"
}

export const errorsFormDepart = {
    name:"You must add a Name",
}

export const errorsFormCity = {
    name:"You must add a Name",
}