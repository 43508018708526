import axios from 'axios'
import { header_token, url_server } from '../../constants/global'

import { ILoginUser } from '../../interfaces/IUser'
import { getTokenRefreshUser, setTokenUser } from '../helper/storage'

export const singin = async (data:ILoginUser)=>{

    const result = await axios.post(url_server+"/api/auth/signin",data)
    console.log(result)

    if(result.status===200)
    {
        return result.data;
    }

    return;

}

export const refreshToken = async ()=>{

    header_token.headers['refresh-token'] = getTokenRefreshUser() || "";
    
    const result =  await axios.get(url_server+"/api/auth/refresh",header_token);

    const data = result.data;

    console.log(" return data token intercertor");

    setTokenUser(data.token);

    return data;
}