import { useReducer } from 'react';
import { FormReducer,EventInput } from '../../utils/commons';
import { ILoginUser } from '../../interfaces/IUser';
import { singin } from '../../services/auth/Auth';
import { setTokenRefreshUser, setTokenUser } from '../../services/helper/storage';
import { useNavigate  } from 'react-router-dom';

const loginUser:ILoginUser = {
    identifier:"",
    password:""
};

export const SigninComponent = ()=>{

    const navigate = useNavigate();
    const [formLogin,setFormLogin] = useReducer(FormReducer,loginUser);

    const updateForm = (event:object)=>{
        setFormLogin(EventInput(event));
    }

    const sendForm = async (event:any)=>{

        event.preventDefault();
        console.log("validate data signin  ")
        console.log(event)
        console.log(formLogin)

        singin(formLogin)
        .then(data=>{
            console.log(data)

            if(data.accessToken)
            {
                setTokenUser(data.accessToken);
                setTokenRefreshUser(data.refreshToken);
                navigate('/admin/menu');
            }

        })
        .catch(error=>{
            console.log(error);
        })//*/

        return false;
    }

    return(
        <div className="ctn-login" >
            <div className="modal-login" >
                <h3>Log In</h3>
                <form onSubmit={sendForm} >
                    <input type="text" name="identifier" placeholder="Username or Email" onChange={updateForm}  />
                    <input type="password" name="password" placeholder="Password" onChange={updateForm}/>
                    <div className="form-bottom" >
                        <span>
                            Forgot Password?
                        </span>
                        <button type="submit" >Log In</button>
                    </div>
                </form>
                <div className="go-to-signup " >
                    Don't have an account? Sign Up »
                </div>
            </div>
        </div>
    )


}