import { AxiosRequestConfig } from "axios"
import { AxiosSecured } from "../constants/AxiosConstants";
// import { API_ENDPOINT } from "../constants/GlobalConstants"
export const API_ENDPOINT = "https://delujoapp.com";

/**
 * Execute request to get all required data to draw the feed screen
 * @param page feed page to load
 * @param product_name product name to filter the feed response
 */
export const GetFeed = (page = 1, product_name: string | undefined = undefined, category: string | undefined = undefined, type: string | undefined, show: string | undefined) => {

    const config: AxiosRequestConfig = {
        params: {
            page,
            word: product_name,
            shop: category,
            type: type,
            show: show
        },
    };

    // console.log("CONFIGURACION", config)

    return AxiosSecured.get(`${API_ENDPOINT}/api/product/feed`, config)
}

export const GetFeedx = (page = 1, product_name: string | undefined = undefined, category: string | undefined = undefined, type: string | undefined, show: string | undefined) => {

    const config: AxiosRequestConfig = {
        params: {
            page,
            word: product_name,
            shop: category,
            type: type,
            show: show
        },
    };

    return AxiosSecured.get(`${API_ENDPOINT}/api/product/feedx`, config)
}


export const GetUserLikesFeed = (page = 1) => {

    const config: AxiosRequestConfig = {
        params: {
            page
        },
    };

    return AxiosSecured.get(`${API_ENDPOINT}/api/product/userLikes`, config)
}

export const GetUserWishFeed = (page = 1) => {

    const config: AxiosRequestConfig = {
        params: {
            page
        },
    };

    return AxiosSecured.get(`${API_ENDPOINT}/api/product/userWish`, config)
}
