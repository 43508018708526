import axios, { AxiosInstance } from 'axios';
import { refreshToken } from '../auth/Auth';
import { getTokenRefreshUser, getTokenUser } from './storage';
import { url_server } from '../../constants/global';
import { redirect } from 'react-router-dom';

const axiosService:AxiosInstance = axios.create({
    baseURL:url_server,
    headers:{
        "Content-Type":"application/json"
    }
})


export const interceptor = () =>{

    axios.interceptors.request.use(
        (req:any)=>{

            req.headers["x-access-token"] = getTokenUser() || ""
            return req;
        },
        (error)=>{
            //console.log("error gt interceptor")
            //console.log(error)
        }
    )

    axios.interceptors.response.use(
        (req)=>{

            //console.log("post interseptor");
            //console.log(req);

            return req;

        },
        async (error)=>{
            let config  = error.config;
            let response  = error.response;
            console.log("error post interceptor",{config,response})
            
            if(response.status === 401 && response.statusText ==="Unauthorized" && config.url.search("refresh")<0 )
            {
                try{

                    const token = await refreshToken();

                    console.log("...token in itercetor----- ",token );

                    if(token.token!==undefined){ 
                        response._retry = true; 
                        axios.defaults.headers.common["x-access-token"] = token.token;
                    }

                    return axios(config);

                    //x-access-token


                }catch(_error)
                {
                    return Promise.reject(_error);
                }//*/
            }else  if(response.status === 403){

                console.log(" data .... 403" )

                window.location.href = '/';
            }

            return Promise.reject(error)
        }
    ) 



    return true;
}