import axios from 'axios';
import { url_server,header_token } from '../../constants/global';
import { getTokenUser } from '../helper/storage';


export const addCoupon = async (data:any) =>{

    header_token.headers['x-access-token'] = getTokenUser() || "";
    const result = await axios.post(url_server+"/api/common/coupons/add",data,header_token);

    return result.data;
}

export const editCoupon = async (data:any) =>{

    header_token.headers['x-access-token'] = getTokenUser() || "";
    const result = await axios.post(url_server+"/api/common/coupons/edit",data,header_token);

    return result.data;
}

export const removeCoupon = async (data:any)=>{

    header_token.headers['x-access-token'] = getTokenUser() || "";
    const result = await axios.post(url_server+"/api/common/coupons/remove",data,header_token);

    return result.data;
}



export const getListCoupons = async ()=>{
    header_token.headers['x-access-token'] = getTokenUser() || "";
    const result = await axios.get(url_server+"/api/common/coupons/list",header_token)
    return result.data;
}

export const getScores = async ()=>{
    header_token.headers['x-access-token'] = getTokenUser() || "";
    const result = await axios.get(url_server+"/api/common/scores",header_token)
    return result.data;
}

export const addLevel = async (data:any) =>{

    header_token.headers['x-access-token'] = getTokenUser() || "";
    const result = await axios.post(url_server+"/api/common/level/add",data,header_token);

    

    return result.data;
}

export const editLevel = async (data:any) =>{

    header_token.headers['x-access-token'] = getTokenUser() || "";
    const result = await axios.post(url_server+"/api//common/level/edit",data,header_token);

    return result.data;
}

export const removeLevel = async (data:any) =>{

    header_token.headers['x-access-token'] = getTokenUser() || "";
    const result = await axios.post(url_server+"/api/common/level/del",data,header_token);

    return result.data;
}

export const addAchievement = async (data:any) =>{

    //header_token.headers['x-access-token'] = getTokenUser() || "";
    const result = await axios.post(url_server+"/api/common/achievements/add",data,header_token);

    return result.data;
}

export const getAchievements = async () =>{

    //header_token.headers['x-access-token'] = getTokenUser() || "";
    const result = await axios.get(url_server+"/api/common/achievements",header_token);

    return result.data;
}

export const editAchievement = async (data:any) =>{

    //header_token.headers['x-access-token'] = getTokenUser() || "";
    const result = await axios.post(url_server+"/api/common/achievements/edit",data,header_token);

    return result.data;
}

export const removeAchievement = async (data:any) =>{

    //header_token.headers['x-access-token'] = getTokenUser() || "";
    const result = await axios.delete(url_server+"/api/common/achievements/remove/"+data,header_token);

    return result.data;
}


