import { url_server} from '../constants/global';
import { library, Library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IIcon,IMessage, IMessageConfirm } from '../interfaces/ICommons';
import { faCamera,faPen,faFileImage } from '@fortawesome/free-solid-svg-icons';
import Swal, { SweetAlertIcon } from 'sweetalert2';

library.add(faCamera,faPen,faFileImage)


export const msgIcons = {success:"success", error:"error", warning:"warning", info:"info", question:"question"}


export const FormReducer = (state:any,event:any)=>{

    return{
        ...state,
        [event.name]:event.value
    }
}

export const EventInput = (event:any)=>{

    let value = event.target.value;
    const target = event.target.name;

    return {name:target,value:value}

}

export const EventInputCustom = (name:string,value:any)=>{

    return  {name:name,value:value}

}

export const EventInputCustomAny = (name:string,value:any)=>{

    return  {name:name,value:value}

}

export const EventClearInput = (name:string)=>{

    return {name:name,value:""}

}

export const EventClearList = (name:string) =>{
    return {name:name,value:[]}
}


export const generateKey = (name:string)=>{

    return `${name}_${new Date().getTime()}`;
}

export const getImage = (image:string) =>{
    return url_server+"/api/image/"+image;
}

export const GetIcon = (props:IIcon) =>{
    return (
        <FontAwesomeIcon icon={[props.type as any,props.icon as any]} size={ props.size as any } color={props.color}  />
        );
}

export function commonMsg(data:IMessage){ //success, error, warning, info, question

    Swal.fire({
        icon: data.icon as SweetAlertIcon,
        title:data.title,
        text:data.text,
    }).then((result)=>{

        //console.log(result);
        //console.log(goback);

        /*if(goback){
            goBackCall()
        }//*/
    })
}

export function commonConfirm(data:IMessageConfirm){

    const icon:any = data.icon || "info";

    Swal.fire({
        title:data.title,
        html:data.text,
        icon:icon,
        showConfirmButton:true,
        confirmButtonText:"Confirm",
        showCancelButton:true,
        cancelButtonText:"Cancel"
    }).then((result)=>{
        
        console.log("result", result );

        if(result.isConfirmed){ data.callback(data.data); }
    });
}
