import axios from "axios"
import { url_server, header_token } from "../../constants/global";
import { getTokenUser } from "../helper/storage";

export const addLanguage = async (data:any)=>{
    header_token.headers['x-access-token'] = getTokenUser() || "";
    const result = await axios.post(url_server+"/api/common/language/add",data,header_token);

    return result.data;
} 

export const editLanguage = async (data:any)=>{

    console.log(" edit language axios");

    header_token.headers['x-access-token'] = getTokenUser() || "";
    const result = await axios.post(url_server+"/api/common/language/edit",data,header_token);

    return result.data;
} 

export const removeLangItem = async (data:any)=>{

    console.log(" remove language axios");

    header_token.headers['x-access-token'] = getTokenUser() || "";
    const result = await axios.get(url_server+"/api/common/language/remove/"+data,header_token);

    return result.data;
} 

export const getlanguages = async () =>{
    const result = await axios.get(url_server+"/api/common/language/1");

    return result.data;
}


export const addCountry = async (data:any)=>{
    header_token.headers['x-access-token'] = getTokenUser() || "";
    const result = await axios.post(url_server+"/api/common/country/add",data,header_token);
    return result.data;
}

export const editCountry = async (data:any)=>{
    header_token.headers['x-access-token'] = getTokenUser() || "";
    const result = await axios.post(url_server+"/api/common/country/edit",data,header_token);
    return result.data;
}

export const getCountriesAdmin = async ()=>{
    const result = await axios.get(url_server+"/api/common/countries/admin");

    return result.data;
}

export const getCountries = async ()=>{
    const result = await axios.get(url_server+"/api/common/countries");

    return result.data;
}

export const removeCountryEvent = async (data:any)=>{
    header_token.headers['x-access-token'] = getTokenUser() || "";
    const result = await axios.get(url_server+"/api/common/country/remove/"+data,header_token);
    return result.data;
}


export const addDeparment = async (data:any)=>{
    header_token.headers['x-access-token'] = getTokenUser() || "";
    const result = await axios.post(url_server+"/api/common/deparment/add",data,header_token);
    return result.data;
}

export const getDeparmentsAdmin = async ()=>{
    const result = await axios.get(url_server+"/api/common/deparments/admin")
    return result.data;
}

export const getDeparments = async ()=>{
    const result = await axios.get(url_server+"/api/common/deparments")
    return result.data;
}

export const editDeparment = async (data:any)=>{
    header_token.headers['x-access-token'] = getTokenUser() || "";
    const result = await axios.post(url_server+"/api/common/deparment/edit",data,header_token);
    return result.data;
}

export const removeDeparment = async (data:any)=>{
    header_token.headers['x-access-token'] = getTokenUser() || "";
    const result = await axios.get(url_server+"/api/common/deparment/remove/"+data,header_token);
    return result.data;
}

export const getListDeparmentByCountry = async (id:string)=>{
    header_token.headers['x-access-token'] = getTokenUser() || "";
    const result = await axios.get(url_server+"/api/common/deparment-country/"+id,header_token)
    return result.data;
}

export const addCity = async (data:any)=>{
    header_token.headers['x-access-token'] = getTokenUser() || "";
    const result = await axios.post(url_server+"/api/common/city/add",data,header_token);
    return result.data;
}

export const getCitiesAdmin = async ()=>{
    const result = await axios.get(url_server+"/api/common/cities/admin")
    return result.data;
}


export const getCities = async ()=>{
    const result = await axios.get(url_server+"/api/common/cities")
    return result.data;
}

export const editCity = async (data:any)=>{
    header_token.headers['x-access-token'] = getTokenUser() || "";
    const result = await axios.post(url_server+"/api/common/city/edit",data,header_token);
    return result.data;
}

export const removeCityItem = async (data:any)=>{
    header_token.headers['x-access-token'] = getTokenUser() || "";
    const result = await axios.get(url_server+"/api/common/city/remove/"+data,header_token);
    return result.data;
}
