import { useEffect, useRef, useState } from "react";
import { GetIcon } from "../../../utils/commons";
import { CropImage } from "../../../utils/cropImage";


interface IImage{
    title?:string,
    type?:string, //single/multiple
    list?:any
    preview?:boolean,
    cropModal?:boolean,
    aspect?:number,
    editId?:string,
    required?:boolean,
    errors?:any
    errorsmsn?:string,
    name?:string,
    multiAmount?:number,
    size?:number | 0,
    sizeMsn?:string,
    customName?:string,
    onChange:(data?:any,type?:any)=>void;
}

export const ImageItem = (props:IImage)=>{

    const inputFile:any = useRef(null);
    const [cropState,setCropState] = useState<boolean>(false);
    const [imgUpload,setImgUpload] = useState<any>(null);
    const [images,setImages] = useState<any>([]);
    const [count,setCount] = useState<number>(0)

    const changeImage = async (event:any) =>{

        const files = event.target.files;

        if(files.length>0){ addImages(files) }
    }

    const addImages = async  (files:any)=>{

        const proList:any = [...images]
        let currentCount = count; 

        if( !validateLimits()){ return; };

        for(let indexFile in  files){
                
            if(!isNaN(Number(indexFile))){
                
                let itemImage = files[indexFile];
                
                if(itemImage.size>0){

                    console.log("data :::.",itemImage)
                    
                    let result:any = await fileToBase(itemImage);

                    const rowImg ={id:currentCount,name:itemImage.name,url:result,file:itemImage,crop:false};

                    if(props.type === 'multiple'){ 
                        currentCount ++; 
                        proList.push(rowImg)
                    }
                    else{ proList[0] = rowImg; } 
                }
            }

            if(props.multiAmount && proList.length>=props.multiAmount){ break; };
        } 

            setCount(currentCount)
            setImages(proList)

            if(props.cropModal){ recursiveCrop(proList);
            }else{ props.onChange(proList,"get") }//*/

            if(inputFile.current){ inputFile.current.value = ""; }
    }

    const validateLimits = ()=>{
        
        let result = true;

        if(props.type ==="multiple" && props.multiAmount && props.multiAmount>0 && props.multiAmount===images.length)
        {
            return false;
        }
        
        return result;
    } 

    const fileToBase  = (file:any)=>new Promise((resolve,reject)=>{
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () =>resolve(reader.result);
        reader.onerror = error =>reject(error);
    });

    const recursiveCrop = (list:any)=>{

        const index = list.findIndex((item:any)=>item.crop===false) 

        console.log("index",index);

        if(index===-1){
            setImages(list)
            props.onChange(list,"get")
            return;
        }

        let name = list[index].name;

        if(props.customName!=="" && props.customName!==undefined){

            const lastPos = name.lastIndexOf(".")
            name = name.slice(0,lastPos)+props.customName+name.slice(lastPos);
        }

        setImgUpload({ img:list[index].url,
            name:list[index].name,
            id:list[index].id})

        setCropState(true)
    }

    const cropImage = (result:any) =>
    {
        console.log({images, result})
        const proList  = [...images]

        const index = proList.findIndex((item:any)=>Number(item.id)===Number(result.id)) 

        proList[index]["file"] = result.file;
        proList[index]["url"] = result.url;
        proList[index]["crop"] = true;

        setCropState(false);
        setImgUpload(null);//*/

        setTimeout(()=>{ recursiveCrop(proList); },1000)
    }

    const removeImage = (event:any,id:number)=>{

        event.preventDefault();

        let listRaw = images;

        for(let indexImg in  listRaw)
        {
            let image = listRaw[indexImg];

            console.log({image,id})

          if(id===image.id){

                console.log(" remover igual",{item:id,image:image.id});

                listRaw.splice(indexImg,1);
                
                break;
            }//*/
        }
        
        props.onChange(listRaw,"get")
        setImages([...listRaw]);
    } 

    const removeAllImages = ()=>{

        setImages([]);
        props.onChange([],"remove")
    }


    const closeModalImg = ()=>{ 
        setCropState(false)
        setImgUpload(null);
    }

    useEffect(()=>{

        if(props.list.length>0){ setImages(props.list) }

    },[])

    return(
        <div className="parent-input-img" >
            {props.name && props.errors && props.errors[props.name]  &&  
                <span className="errorMsn" >* {props.errorsmsn}</span>  
            } 
            <label className="content-input-img" >
                <input 
                    type="file" 
                    onChange={changeImage}
                    multiple={props.type ===  'multiple' ? true : false}
                    ref={inputFile}
                    />
                {props.type === 'multiple'?
                
                    <div>
                        {props.preview ?
                            <div className="dropfiles" >
                                <GetIcon type="fas" icon="camera" color="#aa7845"/>
                                {images.length > 0 && 
                                    images.map((item:any)=>{
                                        return(
                                    
                                            <div className="item-img" >
                                                <img src={item.url } />
                                            </div>
                                            )
                                    })
                                
                                }

                            </div>
                            :
                            <div className="parent-names-img" >
                                {images.map((item:any)=>{

                                    return(
                                        <div key={"multi_img_"+item.id} className="item"  >
                                            <span>{item.name}</span>
                                            <div className="remove"  onClick={(event:any)=>removeImage(event,item.id)} >x</div>
                                        </div>
                                    )

                                })}
                            </div>
                            
                        }   
                        {props.multiAmount !==undefined && props.multiAmount>0 && <p>* Cantidad de archivos {images.length} de {props.multiAmount}</p>}  
                    </div>
                    :
                    <div>
                        {props.preview ?
                            <div>
                                <GetIcon type="fas" icon="camera" color="#aa7845"/>
                                <div className="dropfiles" >
                                {images.length > 0 && (
                                    
                                    <div className="item-img" >
                                        <img src={images[0]?.url } />
                                    </div>
                                    )
                                }
                                </div>
                            </div>
                            :
                            <div>
                                {images.length>0  &&
                                    <div className="display-name" >{images[0].name}</div>
                                }
                            </div>
                        }
                    </div>
                }
                <div className="btn-image-add" >{props.title}</div>    
            </label>
            <div className="btn-image-clear" onClick={removeAllImages}  >Remove</div>
            {props.size !==undefined && props.size>0 && <p>* {props.sizeMsn} {props.size} Mb</p>}
            { cropState && props.cropModal && (
                <CropImage data={imgUpload} cropModal={cropImage} closeModal={closeModalImg} aspect={props.aspect || 1/1} />
                )
            }
        </div>
    )
}