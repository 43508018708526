import { useEffect, useState } from "react";
import { Link } from "react-router-dom"
import { addRole, editRole, listRoles } from "../../services/admin/Users";
import {  commonMsg, generateKey } from "../../utils/commons";
import { useForm } from "react-hook-form";
import { InputItem } from "../commons/FormITems/InputItem";
import { IMessage } from "../../interfaces/ICommons";

type TRole = {
    _id: string,
    name: string,
    value?: number,
};

const errorsForm = {
    name:"You must add a Name",
}

const messageAlert:IMessage={
    icon:'info',
    title:'',
    text:''
}

export const RolesComponent = ()=>{

    const {register,handleSubmit,formState:{errors},setValue, getValues,reset  } = useForm<TRole>();
    const {register:regEdit,handleSubmit:handEdit,formState:{errors:errorsEdit},setValue:setValEdit,getValues:getValEdit, reset:rsEdit } = useForm<TRole>();
    const [listCurrentRoles,setListCurrentRoles] = useState<TRole[]>([]);
    const [langEditRol,setLangEditRol] = useState<boolean>(false);

    const getRoles = async ()=>{

        listRoles()
        .then((data)=>{
            console.log(" data roles",data );
            setListCurrentRoles(data)
        })
        .catch((error)=>{
            console.log(" error roles",error);
        });
    }

    const sendRoles = async (data:any)=>{


        const form = {"name":data.name};

        const alertMsg = messageAlert;

        addRole(form)
        .then(data=>{

            alertMsg.icon = 'success';
            alertMsg.title = 'Language Added';
            alertMsg.text = 'Has been successfully added';

            commonMsg(alertMsg);

            reset({
                name:"",
                value:0
            })
        })
        .catch(error=>{
            alertMsg.icon = 'error'; 
            alertMsg.title = 'Rol don\'t Added'; 
            alertMsg.text = 'It was not possible to add'; 
            commonMsg(alertMsg);
        });//*/

    }

    const showEditRole = (data:any)=>{

        setValEdit("_id",data._id);
        setValEdit("name",data.name);

        setLangEditRol(!langEditRol);
    };

    const hiddenEditRole = ()=>setLangEditRol(!langEditRol);

    const sendUpdateRole = (data:any)=>{

        const form = {id:data._id,name:data.name};
        const alertMsg = messageAlert;

        editRole(form)
        .then(result=>{

            const proList = [...listCurrentRoles];
            const currentPos = proList.findIndex((item)=>item._id===data._id);

            proList[currentPos].name = data.name;

            setListCurrentRoles(proList);
            setLangEditRol(false)

            alertMsg.icon = 'success';
            alertMsg.title = 'Language Added';
            alertMsg.text = 'Has been successfully added';

            commonMsg(alertMsg);

            reset({
                name:"",
                value:0
            })
        })
        .catch(error=>{

        });//*/
    }

    useEffect(()=>{

        getRoles();

    },[])

    return(
        <div className='cnt-general cnt-cms' >
            <div>
                <button>
                    <Link to="/admin/menu" >regresar</Link>
                    </button>
            </div>
            <h4>Roles</h4>
            <div>
                <h6 className="h6-form" >Add New Rol</h6>
                <form onSubmit={handleSubmit(sendRoles)} >
                    <InputItem
                        title="Name"
                        name="name"
                        required={true}
                        register={register}
                        errors={errors}
                        errorsmsn={errorsForm["name"]}
                        labelStyle = "input-label-half"
                        inputStyle = "full-width"
                        />
                    <button>Add Rol</button>
                </form>
            </div>
            <h6 className="h6-form" >List Language</h6>
            <table className="table" >
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>
                    {listCurrentRoles.length>0 &&(
                        listCurrentRoles.map(item=>{
                            let check = false;

                                return(
                                    <tr key={generateKey(item._id)}   >
                                        <td>
                                            <div>{item.name}</div>
                                        </td>
                                        <td>
                                            <button className="edit" onClick={()=>{showEditRole(item)}}  >Edit</button>
                                            <button className="remove" >x</button>
                                        </td>
                                    </tr>
                                )
                            })
                            )
                    }
                </tbody>
            </table>
            {langEditRol && <div className="modal-base" >
                    <div className='background' ></div>
                    <div className="view-edit" >
                    <form  onSubmit={handEdit(sendUpdateRole)}>
                        <InputItem
                            title="Name"
                            name="name"
                            required={true}
                            register={regEdit}
                            errors={errors}
                            errorsmsn={errorsForm["name"]}
                            labelStyle = "input-label-Third"
                            inputStyle = "full-width"
                            />
                        <div>
                            <button>Edit Languaje</button>
                        </div>
                    </form>
                        <hr/>
                        <button onClick={()=>{hiddenEditRole()}} >Cancel Shop</button>
                    </div>
                </div>
            }
        </div>
    )


}