import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom"
import { commonConfirm, commonMsg, generateKey, GetIcon, getImage } from "../../utils/commons";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AccordionDetails from "@mui/material/AccordionDetails";
import { addAchievement, addLevel, editAchievement, editLevel, getAchievements, getScores, removeAchievement, removeLevel } from "../../services/admin/commons";
import { CropImage } from "../../utils/cropImage";
import { listCategories } from "../../services/admin/Category";
import { listShops } from "../../services/admin/Shop";
import { InputItem } from "../commons/FormITems/InputItem";
import { useForm } from "react-hook-form";
import { IMessage, IMessageConfirm } from "../../interfaces/ICommons";
import { DropItem } from "../commons/FormITems/DropItem";
import { type } from "os";
import { TextAreaItem } from "../commons/FormITems/TextAreaItem";
import { ImageItem } from "../commons/FormITems/ImageItem";


type TLevel = {
    _id?: string,
    level: string,
    value?: number,
    type?:string
};

type TAchievement ={
    _id?:string,
    image:string,
    imageRaw:[],
    name:string,
    nameEs:string,
    description:string,
    descriptionEs:string,
    type:string,//shop category
    from:string,//shop category
    typeId?:string
    typeName?:string
    idShop?:string,
    nameShop?:string,
    idCategory?:string,
    nameCategory?:string,
    value:number

}

export const errorsFormLevel = {
    level:"You must add a Level",
    value:"You must add a Value"
}

export const errorsFormAchi = {
    name:"You must add a Name",
    description:"You must add a Description",
    value:"You must add a Value",
    imageRaw:"You must add an Image"
}

const fromList = ["buyer","seller"];
const typeAchi = ["shop","category"];

const messageAlert:IMessage={
    icon:'info',
    title:'',
    text:''
}

const messageConfirm:IMessageConfirm={
    icon:'info',
    title:'',
    text:'',
    callback:()=>{}
}

export const ScoresComponents = ()=>{

    //#region variables
    const {register:regBuyer,handleSubmit:handBuyer,formState:{errors:errorsBuyer},setValue:setValBuyer, reset:rsBuyer  } = useForm<TLevel>();
    const {register:regSeller,handleSubmit:handSeller,formState:{errors:errorsSeller},setValue:setValSeller, reset:rsSeller  } = useForm<TLevel>();
    const {register:regBuyerEdit,handleSubmit:handBuyerEdit,formState:{errors:errorsBuyerEdit},setValue:setValBuyerEdit, reset:rsBuyerEdit } = useForm<TLevel>();
    const {register:regAchi,handleSubmit:handAchi,formState:{errors:errorsAchi},setValue:setValAchi, getValues:getValAchi,reset:rsAchi,watch  } = useForm<TAchievement>();
    const {register:regAchiEdit,handleSubmit:handAchiEdit,formState:{errors:errorsAchiEdit},setValue:setValAchiEdit, getValues:getValAchiEdit,reset:rsAchiEdit,watch:watchEdit } = useForm<TAchievement>();
    const [listBuyer,setListBuyer ] = useState<any>([]);
    const [listSeller,setListSeller ] = useState<any>([]);
    const [listAchiAll,setListAchiAll ] = useState<any>([]);
    const [listShopsData,setListShopsData ] = useState<any>([]);
    const [selectedShop,setSelectedShop] = useState<string>("");
    const [selectedShopEdit,setSelectedShopEdit] = useState<string>("");
    const [listCategoriesData,setListCategoriesData ] = useState<any>([]);
    const [selectedCategory,setSelectedCategory] = useState<string>("");
    const [selectedCategoryEdit,setSelectedCategoryEdit] = useState<string>("");
    const [listCategoriesShow,setListCategoriesShow] = useState<any>([]);
    const [expanded,setExpanded ] = useState<string|false>(false);
    const [expandedAch,setExpandedAch ] = useState<string|false>(false);
    const [showEditLevel,setShowEditLevel] = useState<boolean>(false)
    const [fileValue,] = useState("");
    const [imgUpload,setImgUpload] = useState<any>(null);
    const [imgPreview,setImgPreview] = useState<string>("");
    const [imgPreviewEdit,setImgPreviewEdit] = useState<string>();
    const [editCroppedImg,setEditCroppedImg] = useState<any>(null);
    const [currentCroppedImg,setCurrentCroppedImg] = useState<any>(null);
    const [showEditAchi,setShowEditAchi] = useState<boolean>(false)
    //#endregion

    const initData = async ()=>{

        let list = await getScores();
        let listAchi:any = await getAchievements();

        console.log(" list achivements ",listAchi);

        setListBuyer(list[0].levels.buyer);
        setListSeller(list[0].levels.seller);
        setListAchiAll(listAchi);
        const rawShops = await listShops();
        const rawCagetories = await listCategories();

        setListShopsData(rawShops.data);
        setListCategoriesData(rawCagetories.data);
        setListCategoriesShow(rawCagetories.data)

        setValAchi("from",fromList[0])
        setValAchi("type",typeAchi[0])

        setValBuyer("value",10);
        setValSeller("value",10);
        
        regAchi("imageRaw",{required:true})
    }

    const changeImages = (data:any)=>{ setValAchi("imageRaw",data[0]) }
    const changeImagesUpdate = (data:any)=>{ setValAchiEdit("imageRaw",data[0]) }

    const closeModalImg = ()=>{ 
        console.log("cacelar cierra aqui");
        //formEditCountry._id="";
        setImgUpload('');
    }

    //#region level
    const sendLvlBuyer = (data:any)=>{

        const form = {"level":data.level,type:"buyer",value:data.value};

        console.log(" data level",data);

        const alertMsg = messageAlert;

        addLevel(form)
        .then(data=>{
            alertMsg.icon = 'success'; 
            alertMsg.title = 'Level Buyer Added'; 
            alertMsg.text = 'Has been successfully added'; 

            commonMsg(alertMsg);

            rsBuyer({
                level:"",
                value:10,
            })

            initData();
        })
        .catch(error=>{
            alertMsg.icon = 'error'; 
            alertMsg.title = 'Level Buyer don\'t Added'; 
            alertMsg.text = 'It was not possible to add'; 

            commonMsg(alertMsg);
        })//*/

    }

    const sendLvlSeller = (data:any)=>{

        const form = {"level":data.level,type:"seller",value:data.value};

        const alertMsg = messageAlert;

        addLevel(form)
        .then(data=>{
            alertMsg.icon = 'success'; 
            alertMsg.title = 'Level Seller Added'; 
            alertMsg.text = 'Has been successfully added'; 

            commonMsg(alertMsg);

            rsSeller({
                level:"",
                value:10,
            })

            initData();
        })
        .catch(error=>{
            alertMsg.icon = 'error'; 
            alertMsg.title = 'Level Seller don\'t Added'; 
            alertMsg.text = 'It was not possible to add'; 

            commonMsg(alertMsg);
        })

    }

    const showEditLvl = (data:any,type:string)=>{

        setValBuyerEdit("_id",data._id);
        setValBuyerEdit("level",data.level);
        setValBuyerEdit("value",data.value);
        setValBuyerEdit("type",type);

        setShowEditLevel(!showEditLevel);
    };

    const hiddenEditLvl = ()=>{
        
        setShowEditLevel(!showEditLevel)
    };

    const sendUpdateLvl = (data:any)=>{

        const form = {_id:data._id,"level":data.level,"value":data.value,type:data.type};

        const alertMsg = messageAlert;

        editLevel(form)
        .then(result=>{
            const proList = [...listBuyer];
            const currentPos = proList.findIndex((item)=>item._id===data._id);
            
            proList[currentPos].level = data.name;
            proList[currentPos].value = data.value;

            setListBuyer(proList);

            
            alertMsg.icon = 'success'; 
            alertMsg.title = 'Level Edited'; 
            alertMsg.text = 'Has been successfully edited'; 

            commonMsg(alertMsg);

            setShowEditLevel(false);

            rsBuyerEdit({
                _id:"",
                level:"",
                value:10,
                type:""
            })
        })
        .catch(error=>{
            console.log(error);

            alertMsg.icon = 'error'; 
            alertMsg.title = 'Level don´t edited'; 
            alertMsg.text = 'It was not possible to edit'; 

            commonMsg(alertMsg);
        })
    }

    const removeLevelEvent = (data:any,type:string)=>{

        const form = {_id:data._id,"level":data.level,type:data.type};

        removeLevel(form)
        .then((result)=>{

        })
        .catch((error)=>{

        })
    }
    //#endregion


    const changeCategoriesByShop = (event:any)=>{

        const value = event.target.value;

        let filterSh = listShopsData.find((itemShG:any)=>itemShG._id === value  );


        setValAchi("idShop",value)
        setValAchi("nameShop",filterSh.name+"/"+filterSh.nameEs)

        setSelectedShop(filterSh.name+"/"+filterSh.nameEs)
    }

    const changeCategories = (event:any)=>{
        const value = event.target.value;

        const findCategory = listCategoriesData.find((item:any)=>item._id===value)

        setValAchi("idCategory",value);
        setValAchi("nameCategory",findCategory.name+"/"+findCategory.nameEs)

        setSelectedCategory(findCategory.name+"/"+findCategory.nameEs)
    }

    const handleAccordion = (panel:string)=>(event:React.SyntheticEvent,isExpanded:boolean)=>{
        setExpanded(isExpanded?panel:false);
    }

    const handleAccordionAch = (panel:string)=>(event:React.SyntheticEvent,isExpanded:boolean)=>{
        setExpandedAch(isExpanded?panel:false);
    }

    const sendAchievement = (data:any)=>{

        console.log(" form achievement::: ",data);

        const form = new FormData();

        form.append("image",data.imageRaw.file);
        form.append("name",data.name);
        form.append("nameEs",data.nameEs);
        form.append("description",data.description);
        form.append("descriptionEs",data.descriptionEs);
        form.append("from",data.from);
        form.append("type",data.type);
        form.append("value",data.value);

        if(data.type ==="shop"){
            form.append("typeId",data.idShop);
            form.append("typeName",data.typeName);
            
        }else{
            form.append("typeId",data.idCategory);
            form.append("typeName",data.typeName);
        }

        const alertMsg = messageAlert;

        addAchievement(form)
        .then(data=>{

            alertMsg.icon = 'success'; 
            alertMsg.title = 'Achievement Added'; 
            alertMsg.text = 'Has been successfully added'; 
            commonMsg(alertMsg);

            rsAchi({
                name:"",
                nameEs:"",
                description:"",
                descriptionEs:"",
                from:fromList[0],
                type:typeAchi[0],
                value:1
            })

            setCurrentCroppedImg(null);
            setImgPreview("");
        })
        .catch(error=>{
            alertMsg.icon = 'error'; 
            alertMsg.title = 'Achievement don´t Added'; 
            alertMsg.text = 'It was not possible to add'; 
            commonMsg(alertMsg);
        })//*/

    }

    const showEditAchivement = (data:any)=>{

        console.log(" data update:::  ",data);

        setValAchiEdit("_id",data._id);
        setValAchiEdit("name",data.name);
        setValAchiEdit("nameEs",data.nameEs);
        setValAchiEdit("description",data.description);
        setValAchiEdit("descriptionEs",data.descriptionEs);
        setValAchiEdit("from",data.from);
        setValAchiEdit("value",data.value);
        
        
        if(data.typeCategoryId!==undefined)
        {
            setValAchiEdit("type","category");
            setValAchiEdit("idCategory",data.typeCategoryId._id)
            setValAchiEdit("nameCategory",data.typeCategoryId.name)
            setSelectedCategoryEdit(data.typeName)

        }else{
            setValAchiEdit("type","shop");
            setValAchiEdit("idShop",data.typeShopId)
            setValAchiEdit("nameShop",data.typeName)
            setSelectedShopEdit(data.typeName)
        }
        
        setShowEditAchi(!showEditAchi);
    };

    const hiddenEditAchi = ()=>{
        
        setShowEditAchi(!showEditAchi)
    };

    const changeCategoriesByShopUpdate = (event:any)=>{

        const value = event.target.value;

        let filterSh = listShopsData.find((itemShG:any)=>itemShG._id === value  );

        setValAchiEdit("idShop",value)
        setValAchiEdit("nameShop",filterSh.name+"/"+filterSh.nameEs)

        setSelectedShopEdit(filterSh.name+"/"+filterSh.nameEs)
    }

    const changeCategoriesUpdate = (event:any)=>{


        const value = event.target.value;

        const findCategory = listCategoriesData.find((item:any)=>item._id===value)

        setValAchiEdit("idCategory",value);
        setValAchiEdit("nameCategory",findCategory.name+"/"+findCategory.nameEs)

        setSelectedCategoryEdit(findCategory.name+"/"+findCategory.nameEs)

        /*const currentData = formEditAchievement as any;

        const value = event.target.value;

        console.log(value);


        const findCategory = listCategoriesDataUpdate.find((item:any)=>item._id===value)

        console.log(findCategory)


        currentData.idCategory = value;
        currentData.nameCategory = findCategory.name+"/"+findCategory.nameEs;

        setFormEditLevel ({...formEditAchievement,...currentData});*/
    }

    const sendUpdateAchievemnet = (data:any)=>{

        console.log(" edit ", data)

        const form = new FormData();

        if(data.imageRaw){ form.append("image",data.imageRaw.file); }

        form.append("_id",data._id);
        form.append("name",data.name);
        form.append("nameEs",data.nameEs);
        form.append("description",data.description);
        form.append("descriptionEs",data.descriptionEs);
        form.append("from",data.from);
        form.append("type",data.type);
        form.append("value",data.value);

        if(data.type ==="shop"){
            form.append("typeId",data.idShop);
            form.append("typeName",data.nameShop);
            
        }else{
            form.append("typeId",data.idCategory);
            form.append("typeName",data.nameCategory);
        }


        const alertMsg = messageAlert;

        editAchievement(form)
        .then(result=>{

            alertMsg.icon = 'success'; 
            alertMsg.title = 'Achievement Added'; 
            alertMsg.text = 'Has been successfully added'; 
            commonMsg(alertMsg);

            rsAchiEdit({
                name:"",
                nameEs:"",
                description:"",
                descriptionEs:"",
                from:fromList[0],
                type:typeAchi[0],
                value:1
            })

            setCurrentCroppedImg(null);
            setImgPreview("");//*/
        })
        .catch(error=>{
            alertMsg.icon = 'error'; 
            alertMsg.title = 'Achievement don´t Edited'; 
            alertMsg.text = 'It was not possible to edit'; 
            commonMsg(alertMsg);
        })//*/
    }

    const removeAchivementAlert = (id:string,name:string)=>{
        const confirm = messageConfirm;

        confirm.icon = 'question';
        confirm.title = 'Remove this city';
        confirm.text = 'Are you sure you want to delete '+name+'?';
        confirm.callback = removeItemAchivemnt;
        confirm.data =  id;

        commonConfirm(confirm)
    }


    const removeItemAchivemnt = (id:any)=>{

        removeAchievement(id)
        .then((data)=>{
            initData();
        })
        .catch((error)=>{

        })
    }

    useEffect(()=>{

        initData();

    },[])

    useEffect(()=>{
        
    },[watch("type")])

    return (
        <div className='cnt-general cnt-cms' >
            <div>
                <button>
                    <Link to="/admin/menu" >regresar</Link>
                    </button>
            </div>
            <h4>Scores for Users </h4>
            <Accordion expanded={expanded==='panel1'} onChange={handleAccordion('panel1')}   >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <h4>Levels Buyer</h4>
                </AccordionSummary>
                <AccordionDetails>
                    <div>
                        <h6 className="h6-form" >Add New Level for Buyer</h6>
                        <form onSubmit={handBuyer(sendLvlBuyer)} >
                            <InputItem
                                title="Level"
                                name="level"
                                required={true}
                                register={regBuyer}
                                errors={errorsBuyer}
                                errorsmsn={errorsFormLevel["level"]}
                                labelStyle = "input-label-Third"
                                inputStyle = "full-width"
                            />
                            <InputItem
                                title="Value"
                                name="value"
                                required={true}
                                register={regBuyer}
                                errors={errorsBuyer}
                                errorsmsn={errorsFormLevel["level"]}
                                labelStyle = "input-label-Third"
                                inputStyle = "full-width"
                                type="number"
                                minValue={10}
                            />
                            <button>Add Lvl Buyer</button>
                        </form>
                    </div>
                    <h6 className="h6-form" >List Levels Buyer</h6>
                    <table className="table" >
                        <thead>
                            <tr>
                                <th>Level</th>
                                <th>Value</th>
                                <th className="controls" ></th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            listBuyer.length > 0 &&(
                                listBuyer.map((itemBuyer:any)=>{

                                    return(
                                        <tr key={generateKey("row_buyer_"+itemBuyer._id)} >
                                            <td>{itemBuyer.level}</td>
                                            <td>{itemBuyer.value}</td>
                                            <td>
                                                <button className="edit" onClick={()=>{showEditLvl(itemBuyer,"buyer")}}  >
                                                    <GetIcon type="fas" icon="pen" color="#FFFFFF"/>
                                                    Edit
                                                </button>
                                                <button className="remove" onClick={()=>{removeLevelEvent(itemBuyer,"buyer")}} >x</button>
                                            </td>
                                        </tr>
                                    )
                                }
                                )
                            )
                        }
                        </tbody>
                    </table>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded==='panel2'} onChange={handleAccordion('panel2')}   >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <h4>Levels Seller</h4>
                </AccordionSummary>
                <AccordionDetails>
                    <div>
                        <h6 className="h6-form" >Add New Level for Seller</h6>
                        <form onSubmit={handSeller(sendLvlSeller)} >
                            <InputItem
                                title="Level"
                                name="level"
                                required={true}
                                register={regSeller}
                                errors={errorsSeller}
                                errorsmsn={errorsFormLevel["value"]}
                                labelStyle = "input-label-Third"
                                inputStyle = "full-width"
                            />
                            <InputItem
                                title="Value"
                                name="value"
                                required={true}
                                register={regSeller}
                                errors={errorsSeller}
                                errorsmsn={errorsFormLevel["value"]}
                                labelStyle = "input-label-Third"
                                inputStyle = "full-width"
                                type="number"
                                minValue={10}
                            />
                            <button>Add Lvl Seller</button>
                        </form>
                    </div>
                    <h6 className="h6-form" >List Levels Seller</h6>
                    <table className="table" >
                        <thead>
                            <tr>
                                <th>Level</th>
                                <th>Value</th>
                                <th className="controls" ></th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            listSeller.length > 0 &&(
                                listSeller.map((itemSeller:any)=>{

                                    return(
                                        <tr key={generateKey("row_seller_"+itemSeller._id)} >
                                            <td>{itemSeller.level}</td>
                                            <td>{itemSeller.value}</td>
                                            <td>
                                                <button className="edit" onClick={()=>{showEditLvl(itemSeller,"seller")}}  >
                                                    <GetIcon type="fas" icon="pen" color="#FFFFFF"/>
                                                    Edit
                                                </button>
                                                <button className="remove" onClick={()=>{removeLevelEvent(itemSeller,"buyer")}} >x</button>
                                            </td>
                                        </tr>
                                    )
                                }
                                )
                            )
                        }
                        </tbody>
                    </table>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded==='panel3'} onChange={handleAccordion('panel3')}   >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <h4>Achievements</h4>
                </AccordionSummary>
                <AccordionDetails>
                    <div>
                        <h6 className="h6-form" >Add New Achievement</h6>
                        <form onSubmit={handAchi(sendAchievement)} >
                            <ImageItem
                                title="Add an Image"
                                list={[]}
                                preview={true}
                                cropModal={true}
                                aspect={16/9}
                                onChange={changeImages}
                                name="imageRaw"
                                errors={errorsAchi}
                                errorsmsn={errorsFormAchi["imageRaw"]}
                                />
                            <InputItem
                                title="Name"
                                name="name"
                                required={true}
                                register={regAchi}
                                errors={errorsAchi}
                                errorsmsn={errorsFormAchi["name"]}
                                labelStyle = "input-label-Third"
                                inputStyle = "full-width"
                            />
                            <InputItem
                                title="Name Spanish"
                                name="nameEs"
                                register={regAchi}
                                labelStyle = "input-label-Third"
                                inputStyle = "full-width"
                            />
                            <TextAreaItem 
                                title="Description"
                                name="description"
                                required={true}
                                register={regAchi}
                                errors={errorsAchi}
                                errorsmsn={errorsFormAchi["description"]}
                                labelStyle="input-label-Third"
                            />
                            <TextAreaItem 
                                title="Description Spanish"
                                name="descriptionEs"
                                register={regAchi}
                                labelStyle="input-label-Third"
                            />
                            {fromList.length>0 &&
                                <DropItem
                                    title="From"
                                    name="from"
                                    keyValue="from"
                                    value=""
                                    required={true}
                                    register={regAchi}
                                    labelStyle = "input-label-Third"
                                    inputStyle = "full-width"
                                    list={fromList}
                                    iCheck={getValAchi("from")}
                                    type="index"
                                    />
                            }
                            {fromList.length>0 &&
                                <DropItem
                                    title="Type"
                                    name="type"
                                    keyValue="type"
                                    value=""
                                    required={true}
                                    register={regAchi}
                                    labelStyle = "input-label-Third"
                                    inputStyle = "full-width"
                                    list={typeAchi}
                                    iCheck={getValAchi("type")}
                                    type="index"
                                    />
                            }
                             <InputItem
                                title="Value"
                                name="value"
                                required={true}
                                register={regAchi}
                                errors={errorsAchi}
                                errorsmsn={errorsFormAchi["value"]}
                                labelStyle = "input-label-Third"
                                inputStyle = "full-width"
                                type="number"
                                minValue={1}
                            />
                            {getValAchi("type") ==="shop" ?
                                <div className="custom-accordion" >
                                    <Accordion expanded={expandedAch==='panel4'} onChange={handleAccordionAch('panel4')} >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel4a-content"
                                        id="panel4a-header"
                                        >
                                            <h4>List Shops    </h4>
                                            <div className="title-acco" >Selected:<span>{selectedShop}</span></div>
                                        </AccordionSummary><br/><br/>
                                        <AccordionDetails>
                                            <div className="module-list" >
                                            {listShopsData.length>0 &&(
                                                listShopsData.map((item:any)=>{

                                                    return(
                                                        <div key={generateKey("shop_list_"+item._id)}  className="item" >
                                                            <InputItem
                                                                name={"shop_list"}
                                                                checkTitle={item.name+" / "+item.nameEs}
                                                                type="radio"
                                                                labelStyle = "input-label"
                                                                inputStyle = "full-width"
                                                                value={item._id}
                                                                onChange={changeCategoriesByShop}
                                                                checkValue={getValAchi("idShop")}
                                                            />
                                                        </div>
                                                    )
                                                })
                                                )
                                            }
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            :
                                <div className="custom-accordion" >
                                    <Accordion expanded={expandedAch==='panel5'} onChange={handleAccordionAch('panel5')} >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel5a-content"
                                        id="panel5a-header"
                                        >
                                            <h4>List Categories    </h4>
                                            <div className="title-acco" >Selected:<span>{selectedCategory}</span></div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className="module-list" >
                                                
                                                {listCategoriesShow.length>0 &&(
                                                    listCategoriesShow.map((item:any)=>{

                                                        return(
                                                            <div key={generateKey("shop_list_"+item._id)}  className="item" >
                                                                <InputItem
                                                                    name={"category_list"}
                                                                    checkTitle={item.name+" / "+item.nameEs+"\n \n Shop: "+item.type}
                                                                    type="radio"
                                                                    labelStyle = "input-label"
                                                                    inputStyle = "full-width"
                                                                    value={item._id}
                                                                    onChange={changeCategories}
                                                                    checkValue={getValAchi("idCategory")}
                                                                />
                                                            </div>
                                                        )
                                                        })
                                                    )
                                                }
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            }
                            <button>Add Achievement</button>
                        </form>
                    </div>
                    <h6 className="h6-form" >List Achievement</h6>
                    <div className="parent-table" >
                        <table className="table" >
                            <thead>
                                <tr>
                                    <th>Image</th>
                                    <th>Info</th>
                                    <th>Type</th>
                                    <th>value</th>
                                    <th className="controls" > </th>
                                </tr>
                            </thead>
                            <tbody>
                            {listAchiAll.length > 0 && (
                                    listAchiAll.map((itemAchive:any)=>{

                                        return(
                                            <tr key={generateKey("row_achi_"+itemAchive._id)} >
                                                <td>
                                                {
                                                    itemAchive.image !=="" ? <img src={getImage(itemAchive.image)} className="img-custom" alt={"delujo"} /> 
                                                    :
                                                    <GetIcon type="fas" icon="file-image" size="3x" color="#aa7845"/>
                                                } 
                                                </td>
                                                <td> 
                                                    <div><label className="sub-title" >Name: </label>{itemAchive.name } </div>
                                                    <div><label className="sub-title" >Name Spanish: </label> {itemAchive.nameEs} </div>
                                                    <div><label className="sub-title" >Description :</label> {itemAchive.description } </div>
                                                    <div><label className="sub-title" >Description Spanish:</label> {itemAchive.descriptionEs } </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <label className="sub-title" >Type: </label>
                                                        {itemAchive.typeCategoryId!==undefined ? "Category": "Shop" } 
                                                    </div>
                                                    <div>
                                                        <label className="sub-title" >name element: </label>
                                                        {itemAchive.typeName}
                                                        {itemAchive.typeCategoryId!==undefined && "("+itemAchive.typeCategoryId.type+")" } 
                                                    </div>
                                                    <div><label className="sub-title" >From: </label> {itemAchive.from}</div>
                                                </td>
                                                <td>{itemAchive.value}</td>
                                                <td>
                                                    <button className="edit" onClick={()=>{showEditAchivement(itemAchive)}}  >
                                                        <GetIcon type="fas" icon="pen" color="#FFFFFF"/>
                                                        Edit
                                                    </button>
                                                    <button className="remove" onClick={()=>{removeAchivementAlert(itemAchive._id,itemAchive.name)}} >x</button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                )
                            }
                            </tbody>
                        </table>
                    </div>
                </AccordionDetails>
            </Accordion>
            {showEditLevel &&
                <div className="modal-base" >
                    <div className='background' ></div>
                    <div className="view-edit" >
                        <form  onSubmit={handBuyerEdit(sendUpdateLvl)}>
                            <InputItem
                                title="Level"
                                name="level"
                                required={true}
                                register={regBuyerEdit}
                                errors={errorsBuyerEdit}
                                errorsmsn={errorsFormLevel["level"]}
                                labelStyle = "input-label-Third"
                                inputStyle = "full-width"
                            />
                            <InputItem
                                title="Value"
                                name="value"
                                required={true}
                                register={regBuyerEdit}
                                errors={errorsBuyerEdit}
                                errorsmsn={errorsFormLevel["level"]}
                                labelStyle = "input-label-Third"
                                inputStyle = "full-width"
                                type="number"
                                minValue={10}
                            />
                            <div>
                            <button>Edit Languaje</button>
                        </div>
                        </form>
                        <hr/>
                        <button onClick={()=>{hiddenEditLvl()}} >Cancel Level</button>
                    </div>
                </div>
            }
            {showEditAchi && 
                <div className="modal-base" >
                    <div className='background' ></div>
                    <div className="view-edit" >
                        <form onSubmit={handAchiEdit(sendUpdateAchievemnet)}>
                            <ImageItem
                                title="Add an Image"
                                list={[]}
                                preview={true}
                                cropModal={true}
                                aspect={16/9}
                                onChange={changeImagesUpdate}
                                name="imageRaw"
                                />
                            <InputItem
                                title="Name"
                                name="name"
                                required={true}
                                register={regAchiEdit}
                                errors={errorsAchiEdit}
                                errorsmsn={errorsFormAchi["name"]}
                                labelStyle = "input-label-Third"
                                inputStyle = "full-width"
                            />
                            <InputItem
                                title="Name Spanish"
                                name="nameEs"
                                required={true}
                                register={regAchiEdit}
                                errors={errorsAchi}
                                errorsmsn={errorsFormAchi["name"]}
                                labelStyle = "input-label-Third"
                                inputStyle = "full-width"
                            />
                            <TextAreaItem 
                                title="Description"
                                name="description"
                                required={true}
                                register={regAchiEdit}
                                errors={errorsAchi}
                                errorsmsn={errorsFormAchi["description"]}
                                labelStyle="input-label-Third"
                            />
                            <TextAreaItem 
                                title="Description Spanish"
                                name="descriptionEs"
                                register={regAchiEdit}
                                labelStyle="input-label-Third"
                            />
                            {fromList.length>0 &&
                                <DropItem
                                    title="From"
                                    name="from"
                                    keyValue="from"
                                    value=""
                                    required={true}
                                    register={regAchiEdit}
                                    labelStyle = "input-label-Third"
                                    inputStyle = "full-width"
                                    list={fromList}
                                    iCheck={getValAchiEdit("from")}
                                    type="index"
                                    />
                            }
                            {fromList.length>0 &&
                                <DropItem
                                    title="Type"
                                    name="type"
                                    keyValue="type"
                                    value=""
                                    required={true}
                                    register={regAchiEdit}
                                    labelStyle = "input-label-Third"
                                    inputStyle = "full-width"
                                    list={typeAchi}
                                    iCheck={getValAchiEdit("type")}
                                    type="index"
                                    />
                            }
                            <InputItem
                                title="Value"
                                name="value"
                                required={true}
                                register={regAchiEdit}
                                errors={errorsAchiEdit}
                                errorsmsn={errorsFormAchi["value"]}
                                labelStyle = "input-label-Third"
                                inputStyle = "full-width"
                                type="number"
                                minValue={1}
                            />
                            {getValAchiEdit("type") ==="shop" ?
                                <div className="custom-accordion" >
                                    <Accordion expanded={expandedAch==='panel4'} onChange={handleAccordionAch('panel4')} >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel4a-content"
                                        id="panel4a-header"
                                        >
                                            <h4>List Shops    </h4>
                                            <div className="title-acco" >Selected:<span>{selectedShopEdit}</span></div>
                                        </AccordionSummary><br/><br/>
                                        <AccordionDetails>
                                            <div className="module-list" >
                                            {listShopsData.length>0 &&(
                                                listShopsData.map((item:any)=>{

                                                    return(
                                                        <div key={generateKey("shop_list_edit"+item._id)}  className="item" >
                                                            <InputItem
                                                                name={"shop_list"}
                                                                checkTitle={item.name+" / "+item.nameEs}
                                                                type="radio"
                                                                labelStyle = "input-label"
                                                                inputStyle = "full-width"
                                                                value={item._id}
                                                                onChange={changeCategoriesByShopUpdate}
                                                                checkValue={getValAchiEdit("idShop")}
                                                            />
                                                        </div>
                                                    )
                                                })
                                                )
                                            }
                                            </div>
                                        </AccordionDetails>
                                        </Accordion>
                                </div>
                            :
                                <div className="custom-accordion" >
                                    <Accordion expanded={expandedAch==='panel5'} onChange={handleAccordionAch('panel5')} >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel5a-content"
                                        id="panel5a-header"
                                        >
                                            <h4>List Categories    </h4>
                                            <div className="title-acco" >Selected:<span>{selectedCategoryEdit}</span></div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className="module-list" >
                                                
                                                {listCategoriesShow.length>0 &&(
                                                    listCategoriesShow.map((item:any)=>{

                                                        return(
                                                            <div key={generateKey("shop_list_"+item._id)}  className="item" >
                                                                <InputItem
                                                                    name={"category_list"}
                                                                    checkTitle={item.name+" / "+item.nameEs+"\n \n Shop: "+item.type}
                                                                    type="radio"
                                                                    labelStyle = "input-label"
                                                                    inputStyle = "full-width"
                                                                    value={item._id}
                                                                    onChange={changeCategoriesUpdate}
                                                                    checkValue={getValAchiEdit("idCategory")}
                                                                />
                                                            </div>
                                                        )
                                                        })
                                                    )
                                                    }
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            }
                            <br/>
                            <button>Edit Achievement</button>
                        </form>
                        <hr/>
                        <button onClick={()=>{hiddenEditAchi()}} >Cancel Achievement</button>
                    </div>
                </div>
            }

        </div>
    )


}