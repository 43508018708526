import Axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
// import { Dispatch } from "redux";
import { refreshToken } from "../services/auth/Auth";
// import { SetUserAuthToken } from "../redux/userDucks";
import { handle } from "../utils/PromiseHandler";
import { GetUserLocalRefreshToken } from "./GlobalConstants";

export let AxiosSecured: AxiosInstance = Axios.create();

export const ConfigureAxiosSecured = (
	// dispatch: Dispatch<any>,
	userToken: string
) => {
	// console.log("AXIOS SECURE",userToken);

	const _axiosSecured = Axios.create({
		headers: {
			"x-access-token": userToken,
		},
	});

	_axiosSecured.interceptors.request.use(
		(r) => {
			// console.log("RESPONSE REQUEST-->",r)
			return r
		}, (e) => { console.log("otro error", e) }
	)

	_axiosSecured.interceptors.response.use(
		function (response) {
			// Any status code that lie within the range of 2xx cause this function to trigger
			// Do something with response data
			// console.log("RESPUESTA AXIOS", response)
			return response;
		},
		async function (error: AxiosError) {
			//console.log("Error msg: ", error.response?.data);
			console.log("Error msg: ", error.response?.status);
			// console.log("Error: ", error.response);

			if (error.response?.status == 401) {
				// Try refresh auth
				const refreshToken = await GetUserLocalRefreshToken();
				let forceLogout = refreshToken == null;

				// if (refreshToken) {
				// 	const [response, errorResponse] = await handle(
				// 		// refreshToken(refreshToken)
				// 		refreshToken()
				// 	);

				// 	if (errorResponse) {
				// 		forceLogout = true;
				// 	} else {
				// 		const newToken = (response as AxiosResponse).data.token;

				// 		// console.log("--llego aqui---")
				// 		// All is ok, update and continue
				// 		error.config.headers["x-access-token"] = newToken; // Update previous request
				// 		AxiosSecured.defaults.headers["x-access-token"] = newToken; // Update main instance
				// 		// dispatch(SetUserAuthToken(newToken, refreshToken));

				// 		//console.log("░░░░░░░░ User auth refreshed ░░░░░░░░");

				// 		return AxiosSecured.request(error.config);
				// 	}
				// }

				if (forceLogout) {
					// Error, could not re-login
					// dispatch(SetUserAuthToken(null, null));
					//console.log("Force logout, could not renew token");
					return Promise.reject(error);
				}
			}

			// Any status codes that falls outside the range of 2xx cause this function to trigger
			// Do something with response error
			return Promise.reject(error);
		}
	);

	AxiosSecured = _axiosSecured;
};
