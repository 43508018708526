export const errorsFormShop = {
    name:"You must add a Name",
    imageRaw:"You must add a Name"
}

export const errorsFormCategory = {
    name:"You must add a Name",
    imageRaw:"You must add a Name"
}

export const errorsFormBrands = {
    name:"You must add a Name",
    imageRaw:"You must add a Name"
}

