import { useEffect, useState } from "react"

interface IInput{
    title?:string,
    name?:string,
    type?:string,
    checkTitle?:string,
    required?:boolean,
    register?:any,
    errors?:any
    errorsmsn?:string,
    labelStyle?:string,
    inputStyle?:string,
    value?:any,
    onChange?:(data?:any)=>void;
    check?:boolean,
    checkValue?:string,
    minValue?:number,
    maxValue?:number
}

export const InputItem = (props:IInput)=>{

    const [classLabel,setClassLabel] = useState<string>('input-label');
    const [check,setCheck] = useState<boolean>(false)

    const onChange = (event:any)=>{

        if(props.onChange!==undefined){ props.onChange(event); }
    }

    useEffect(()=>{

        if(props.labelStyle){  
            let classL = classLabel+" "+props.labelStyle
            setClassLabel(classL);
        }

        if(props.type==="radio" && props.checkValue===props.value){ setCheck(true); console.log("check") }
        
    },[])

    return(
        <label className={classLabel}  >
            {props.title && ( props.type !== 'hidden' ) && <span>{props.title} </span> } 
            {props.required && ( props.type !== 'hidden' ) && <span className="requiere" >*</span> }
            <input 
                className={props.inputStyle+" "}
                type={props.type || "text"} 
                {...props.register && props.register(props.name,{required:props.required })} 
            
                defaultValue = {props.value || null}
                onChange={props.onChange!== undefined ? (event:any)=>{ onChange(event)   } : null }
                defaultChecked = {props.type==="checkbox" ? props.check :check}     
               
                min={props.minValue ? props.minValue : 0 }
                max={props.minValue ? props.maxValue : 1000000 }
                name={props.name}
                />
            {/*checked={props.type==="checkbox" ? props.check :check}      */ }
            {props.checkTitle && <span className="check-title" >{props.checkTitle}</span>}
            {props.register &&  props.name && props.name && props.errors && props.errors[props.name]  &&  
                <span className="errorMsn" >* {props.errorsmsn}</span>  
            } 
        </label>
    )

}