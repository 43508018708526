import { useState} from 'react';
import { Link  } from 'react-router-dom';
import icon from '../../assets/img/iconov1.png';

export const HeaderComponent = ()=>{

    const[visibleLogin, ] = useState(true);

    return(

      <nav
      className="navbar navbar-expand-lg navbar-light bg-white w-100 navigation"
      id="navbar"
    >
      <div className="container">
        <Link className="navbar-brand font-weight-bold" to={{ pathname: "/" }}>
              {/* <img className="img-thumbnail" src={icon} alt={"delujo"} /> */}
              <span className="title-icon" >De Lujo</span>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#main-navbar"
          aria-controls="main-navbar"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse " id="main-navbar">
          <ul className="navbar-nav mx-auto">
            <li className="nav-item active">
              <Link className="nav-link" to={{ pathname: "/" }}>
                Inicio
              </Link>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">
                Acerca de 
              </a>
            </li>

            {/* <li className="nav-item dropdown dropdown-slide">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown4"
                role="button"
                data-delay="350"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Pages.
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown4">
                <li>
                  <a href="#">About Us</a>
                </li>
                <li>
                  <a href="#">Blog</a>
                </li>
                <li>
                  <a href="#">Blog Single</a>
                </li>
                <li>
                  <a href="#">Contact</a>
                </li>
                <li>
                  <a href="#">404 Page</a>
                </li>
                <li>
                  <a href="#">FAQ</a>
                </li>
              </ul>
            </li> */}
            <li className="nav-item dropdown dropdown-slide">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown3"
                role="button"
                data-delay="350"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Tienda
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown3">
                <li>
                  <Link to={{ pathname: "/shop" }}>Shop</Link>
                </li>
                <li>
                  <Link to={{ pathname: "/single-product" }}>
                    Detalles de productos
                  </Link>
                </li>
                <li>
                  <Link to={{ pathname: "/checkout" }}>Finalizar compra</Link>
                </li>
                <li>
                  <Link to={{ pathname: "/cart" }}>Carrito</Link>
                </li>
                <li>
                  <Link to={{ pathname: "/feed" }}>Feed</Link>
                </li>
              </ul>
            </li>

            <li className="nav-item dropdown dropdown-slide">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown5"
                role="button"
                data-delay="350"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Mi cuenta
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown5">
                <li>
                  <Link to={{ pathname: "/login" }}>Ingresar</Link>
                </li>
                <li>
                  <Link to={{ pathname: "/signup" }}>Registrarse</Link>
                </li>
                <li>
                  <Link to={{ pathname: "/forgot-password" }}>
                    Recuperar contraseña
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>

        <ul className="top-menu list-inline mb-0 d-none d-lg-block" id="top-menu">
          {/* <li className="list-inline-item">
            <a href="#" className="search_toggle" id="search-icon">
              <i className="tf-ion-android-search"></i>
            </a>
          </li> */}
          <li className="dropdown cart-nav dropdown-slide list-inline-item">
            <a
              href="#"
              className="dropdown-toggle cart-icon"
              data-toggle="dropdown"
              data-hover="dropdown"
            >
              <i className="tf-ion-android-cart"></i>
            </a>
            <div className="dropdown-menu cart-dropdown">
              <div className="media">
                <a href="/product-single">
                  <img
                    className="media-object img- mr-3"
                    src="assets/images/cart-1.jpg"
                    alt="image"
                  />
                </a>
                <div className="media-body">
                  <h6>Ladies Bag</h6>
                  <div className="cart-price">
                    <span>1 x</span>
                    <span>1250.00</span>
                  </div>
                </div>
                <a href="#" className="remove">
                  <i className="tf-ion-close"></i>
                </a>
              </div>

              <div className="media">
                <a href="/product-single">
                  <img
                    className="media-object img-fluid mr-3"
                    src="assets/images/cart-2.jpg"
                    alt="image"
                  />
                </a>
                <div className="media-body">
                  <h6>Skinny Jeans</h6>
                  <div className="cart-price">
                    <span>1 x</span>
                    <span>1250.00</span>
                  </div>
                </div>
                <a href="#" className="remove">
                  <i className="tf-ion-close"></i>
                </a>
              </div>
              <div className="cart-summary">
                <span className="h6">Total</span>
                <span className="total-price h6">$1799.00</span>
                <div className="text-center cart-buttons mt-3">
                  <a href="#" className="btn btn-small btn-transparent btn-block">
                    Ver carrito
                  </a>
                  <a href="#" className="btn btn-small btn-main btn-block">
                    Finalizar compra
                  </a>
                </div>
              </div>
            </div>
          </li>
          <li className="list-inline-item">
          <Link to={{ pathname: "/login" }}><i className="tf-ion-ios-person mr-3"></i></Link>
          </li>
        </ul>
      </div>
    </nav>


        // <header >
        //   <div className="row cnt-header"  >  
        //     <div className="col-sm-2" >
        //       <Link to="/" >
                // <img className="img-icon"  src={icon} alt={"delujo"} />
                // <span className="title-icon" >De Lujo</span>
        //       </Link>
        //     </div>    
        //     {visibleLogin?
        //     <div  className="col-sm-10" >
        //         <div className="cnt-login" >
        //           <div className="btn btn-light">
        //           <Link to="/login">
        //           <i className="fa fa-user"></i>{" "}
        //           <span className="ms-1 d-none d-sm-inline-block">Sign in </span>
        //           </Link>
        //           </div> 
        //           <Link to="/signup" >Sign Up</Link>   
        //         </div>        
        //     </div>
        //     :
        //     <div  className="col-sm">
        //       <div className="search-container">
        //           <form action="/action_page.php">
        //             <input type="text" placeholder="Search listing" name="search" />
        //             <button type="submit"><i className="fa fa-search"></i></button>
        //           </form>
        //       </div>
        //       <div className="ctn-icon1">
        //         <i className="far fa-bell"></i>
        //         <p>News</p>
        //       </div>
        //       <div className="ctn-icon2" >
        //         <i className="far fa-heart"></i>
        //         <p>My Likes</p>
        //       </div>
        //       <div className="ctn-icon3">
        //         <i className="fas fa-shopping-bag"></i>
        //         <p>My Bundles</p>
        //       </div>
        //       <div className="ctn-icon4">
        //         <i className="fas fa-donate"></i>
        //         <p>My Offers</p>
        //       </div> 
        //       <div className="cnt-profile" >
        //         <div className="cnt-user-img" >
        //           <img src="assets/img/userhome.png"  alt={"delujo"} />
        //         </div>
        //         <div className="profile-menu" >
        //           <ul>
        //             <li>Profile</li>
        //             <li>Wall</li>
        //             <hr/>
        //             <li onClick={()=>{ /*"logOut()"*/}} >Logout</li>
        //           </ul>
        //         </div>
        //       </div>
        //     </div>
        //   }
        //   </div>
        //   { !visibleLogin?
        //   <div  className="bottom"  >
        //     <div className="row cnt-header-bottom"  >
        //       <div className="col-8" ></div>
        //       <div className="col-4" >
        //         <Link to="/create"  >
        //           <div className="btn-create" >
        //             <div className="simbol" >
        //               <i className="fas fa-dollar-sign"></i>
        //               <p>SELL ON DE LUJO</p>
        //             </div>
        //           </div> 
        //         </Link>
        //       </div>
        //     </div>
        //   </div>
        //   :<></>
        //   }
        // </header>
    );
}