import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

interface Window{
    view:any
}

export const ConfirmComponent = ()=>{

    const parmansData = useParams(); 
    const [data,setData] = useState("");
    const [isMobile,setIsMobile] = useState(false);


    const checkBrowser = () =>{

        setData(parmansData.data || "");
        
        if(navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i))
        {
            console.log(" chek browser si entra---");
            console.log("paramenter ",parmansData);
            setIsMobile(true);

            document.addEventListener('DOMContentLoaded', function() {
                console.log(" dom");
                window.ReactNativeWebView.postMessage('DOMLoaded');
            });

            //window.location.replace('delujo://link/'+parmansData.type+"/"+parmansData.url);   

             document.addEventListener('click',function(){
                
                window.ReactNativeWebView.postMessage(parmansData.data)

            });

        }else{
            console.log("paramenter ",parmansData);


        }
    }

    useEffect(()=>{
        
        checkBrowser();

    },[])


    return (
        <div  className='cnt-general' >
            <h1>Confirmacion pago pse</h1>
            <h3>estado de Transaccion:{data}</h3>
        </div>
    )
}