import { useEffect,  useState } from "react";
import { getImage, generateKey  } from '../../utils/commons';
import { TShop } from '../../interfaces/IShop';
import { addShop,editShop,listShops, removeShopData } from "../../services/admin/Shop";
import { CropImage} from "../../utils/cropImage";
import { GetIcon, commonMsg } from "../../utils/commons";
import { IMessage } from '../../interfaces/ICommons'
import { Link } from "react-router-dom";
import { InputItem } from "../commons/FormITems/InputItem";
import { useForm } from "react-hook-form";
import { errorsFormShop } from "../../errors/errorsShops";
import { ImageItem } from "../commons/FormITems/ImageItem";

const messageAlert:IMessage={
    icon:'info',
    title:'',
    text:''
}

export const ShopsComponent = ()=>{
    
    const {register,handleSubmit,formState:{errors},setValue, getValues,reset  } = useForm<TShop>();
    const {register:regEdit,handleSubmit:handEdit,formState:{errors:errorsEdit},setValue:setValEdit,getValues:getValEdit, reset:rsEdit } = useForm<TShop>();
    const [imgUpload,setImgUpload] = useState<any>();
    const [imgPreview,setImgPreview] = useState<string>();
    const [currentCroppedImg,setCurrentCroppedImg] = useState<any>();
    const [listShopsData,setListShopsData ] = useState<TShop[]>([]);
    const [stateEdit,setStateEdit] = useState(false);
    const [imgPreviewEdit,setImgPreviewEdit] = useState<string>();
    const [editCroppedImg,setEditCroppedImg] = useState<any>(null);
    const [textTag,setTextTag] = useState("");
    const [textEditTag,setTextEditTag] = useState("");
    const [listTags,setListTags ] = useState<string[]>([]);
    const [editTags,setEditTags] = useState<string[]>([]); 

    const changeImages = (data:any)=>{ setValue("imageRaw",data[0]) }
    const changeImagesUpdate = (data:any)=>{ setValEdit("imageRaw",data[0]) }

    const sendForm = (data:any) =>
    {
        const form = new FormData();

        form.append("image",data.imageRaw.file);
        form.append("name", data.name);
        form.append("nameEs", data.nameEs);
        form.append("tags", JSON.stringify(data.tags));

        const alertMsg = messageAlert;

        addShop(form)
        .then(data=>{

            alertMsg.icon = 'success'; 
            alertMsg.title = 'Shop Added'; 
            alertMsg.text = 'Has been successfully added'; 

            commonMsg(alertMsg);

            reset({
                name:"",
                nameEs:"",
                tags:[]
            })

            setCurrentCroppedImg(null);
            setImgPreview("");
            getShops();
        })
        .catch(error=>{
            alertMsg.icon = 'error'; 
            alertMsg.title = 'Shop don\'t Added'; 
            alertMsg.text = 'It was not possible to add'; 

            commonMsg(alertMsg);

        });    //*/
    }
    
    const getShops = () => {

        setValue("tags",[]);

        listShops()
        .then(data=>{

            console.log("result data shops");
            console.log(data);

            setListShopsData(data.data);


        }).catch(error=>{

        })//*/

    }

    const showEdit = (data:TShop) =>{
        
        console.log(" showe modal edit",data)

        setValEdit("_id",data._id);
        setValEdit("name",data.name);
        setValEdit("nameEs",data.nameEs);
        setValEdit("tags",data.tags);
        setEditTags(data.tags)
        setStateEdit(true);
    }

    const closeEdit = () =>{
        setStateEdit(false);

        rsEdit({
            _id:"",
            name:"",
            nameEs:"",
            tags:[]
        })
        setImgPreviewEdit("");
        setEditCroppedImg(null);
    }

    const updateShop = (data:any)=>{

        const form = new FormData();
        form.append("id", data._id);

        if(data.imageRaw){ form.append("image",data.imageRaw.file); }

        form.append("name",data.name );
        form.append("nameEs",data.nameEs );
        form.append("tags",JSON.stringify(data.tags) );

        const alertMsg = messageAlert;

        editShop(form)
        .then(result=>{

            const proList = [...listShopsData];
            const currentPos = proList.findIndex((item)=>item._id===data._id);
            
            proList[currentPos].name = data.name;

            if(result.image.length>0){ proList[currentPos].image = result.image; }

            alertMsg.icon = 'success'; 
            alertMsg.title = 'Shop Edited'; 
            alertMsg.text = 'Has been successfully edit'; 

            commonMsg(alertMsg);

            setListShopsData(proList);

            setStateEdit(false);

            rsEdit({
                _id:"",
                name:"",
                nameEs:"",
                tags:[]
            })

            setEditTags([]);
            setImgPreviewEdit("");
            setEditCroppedImg(null);

        })
        .catch(error=>{
            console.log(error);

            alertMsg.icon = 'error'; 
            alertMsg.title = 'Shop don´t edited'; 
            alertMsg.text = 'It was not possible to edit'; 

            commonMsg(alertMsg);
        });//*/

    } 

    const removeShop = (id:string)=>{
        console.log("remove by id")
        console.log(id)
        removeShopData(id)
        .then(data=>{

            console.log(data);
            getShops();

        })
        .catch(error=>{

        })
    } 

    const changeTextTag = (data:any)=> {
    
        setTextTag(data.target.value)
    
    };

    const addTag = ()=>{

        let localList:any = getValues("tags");

        console.log("localList :::");

        const filterData = localList.filter((item:string)=>item===textTag);

        if(filterData.length>0)
        {
            setTextTag("");
            return;
        }

        localList.push(textTag);

        setTextTag("");
        setValue("tags",localList)
        setListTags([...localList])
    }

    const removeTag = (selected:string)=>{

        let localList:any = [...getValues("tags")];

        
        const indexData = localList.findIndex((item:string)=>item===selected);
        
        if(indexData>-1){

            localList.splice(indexData,1)
            setValue("tags",localList)
            setListTags([...localList])
        }
    } 

    const changeEditTextTag = (data:any)=> {
    
        setTextEditTag(data.target.value)
    
    };

    const addEditTag = ()=>{
        let localList:any = editTags;

        const filterData = localList.filter((item:string)=>item===textEditTag);

        if(filterData.length>0)
        {
            setTextEditTag("");
            return;
        }

        localList.push(textEditTag);

        setTextEditTag("");
        setValEdit("tags",localList)
        setEditTags([...localList]);
    }

    const removeEditTag = (selected:string)=>{

        let localList:any = editTags;

        const indexData = localList.findIndex((item:string)=>item===selected);

        if(indexData>-1){
            localList.splice(indexData,1)
            setEditTags([...localList]);
            setValEdit("tags",localList)
        }
    } 

    useEffect(()=>{
        console.log("load data shops")
        getShops();
        register("imageRaw",{required:true})

    },[]);

    return(
        <div className='cnt-general cnt-cms' >
             <div>
                <button>
                    <Link to="/admin/menu" >regresar</Link>
                    </button>
            </div>
            <h4>List Shops</h4> 
            <div>
                <form onSubmit={handleSubmit(sendForm)} >
                    <ImageItem
                        title="Add an Image"
                        list={[]}
                        preview={true}
                        cropModal={true}
                        aspect={4/3}
                        onChange={changeImages}
                        name="imageRaw"
                        errors={errors}
                        errorsmsn={errorsFormShop["imageRaw"]}
                        />
                    <InputItem
                            title="Name"
                            name="name"
                            required={true}
                            register={register}
                            errors={errors}
                            errorsmsn={errorsFormShop["name"]}
                            labelStyle = "input-label-Third"
                            inputStyle = "full-width"
                        />
                    <InputItem
                            title="Name Spanish"
                            name="nameEs"
                            register={register}
                            labelStyle = "input-label-Third"
                            inputStyle = "full-width"
                        />
                        <div className="input-label"  >
                            
                            <label className="input-label-Third" >
                                <span>Add tag</span><br/>
                                <input type="text" name="tag" placeholder="Tag" className="full-width"  value={textTag}  onChange={changeTextTag} />
                                <div  className="btn btn-commons" onClick={addTag}>Add Tag</div>
                            </label>
                        
                        {listTags.length>0 &&

                            <div className="tags-content" >

                            {listTags.map(tagNew=>{

                                const prevTagKey = generateKey("tagItem_"+tagNew);

                                return( 
                                        <div key={prevTagKey} className="item"  >
                                            <span>{tagNew}</span>
                                            <div className="remove" onClick={()=>{removeTag(tagNew)}} >
                                                x
                                            </div> 
                                        </div>
                                    )
                                })
                            }
                            </div>
                        }
                    </div>
                    <button>Add Shop</button>
                </form>
                <hr/>
            </div>
            <div>
                <div className="title-module" >
                    <h3>List</h3>
                </div>
                <div className="parent-table" >
                    <table className="table" >
                        <thead>
                            <tr>
                                <th>Image</th>
                                <th>Name</th>
                                <th className="controls" ></th>
                            </tr>
                        </thead>
                        <tbody>
                        {listShopsData.length>0 &&
                            (
                                listShopsData.map((item)=>
                                {
                                    const key = generateKey(item.name)

                                    return(
                                        <tr key={key} >
                                            <td>
                                                {
                                                    item.image !=="" ? <img className="img-custom"  src={getImage(item.image)} alt={"delujo"} /> 
                                                    :
                                                    <GetIcon type="fas" icon="file-image" size="4x" color="#aa7845"/>
                                                }        
                                                    
                                            </td>
                                            <td>
                                                <div>
                                                    <label className="sub-title" >Name:</label> 
                                                    { item.name}
                                                </div>
                                                <div>
                                                <label className="sub-title" >Name Spanish:</label>
                                                    {item.nameEs}</div>
                                                    <label className="sub-title" >Tags List: </label> 
                                                    {item.tags.length>0 &&
                                                    <div className="detail-tags" >

                                                        {item.tags.map(tag=>{

                                                            const keyTagItem = generateKey(tag+"_tag_item")

                                                            return (<div key={keyTagItem} className="item" >{tag}</div>)
                                                        })}
                                                        
                                                    </div>
                                                    }
                                            </td>
                                            <td>
                                                <button className="edit"   onClick={()=>{showEdit(item)}} >
                                                    <GetIcon type="fas" icon="pen" color="#FFFFFF"/>
                                                    Edit
                                                </button>
                                                <button className="remove" onClick={()=>{removeShop(item._id)}}  >x</button>
                                            </td>
                                        </tr>
                                    )
                                })
                            )

                        }

                        </tbody>
                    </table>
                </div>
            </div>
            { stateEdit &&
                <div className="modal-base"  >
                    <div className='background' ></div>
                    <div className="view-edit" >
                        <form onSubmit={handEdit(updateShop)}>
                            <ImageItem
                                title="Add an Image "
                                list={[]}
                                preview={true}
                                cropModal={true}
                                aspect={4/3}
                                onChange={changeImagesUpdate}
                                name="imageRaw"
                                />
                            <InputItem
                                title="Name"
                                name="name"
                                required={true}
                                register={regEdit}
                                errors={errorsEdit}
                                errorsmsn={errorsFormShop["name"]}
                                labelStyle = "input-label-Third"
                                inputStyle = "full-width"
                            />
                            <InputItem
                                title="Name Sapnish"
                                name="nameEs"
                                register={regEdit}
                                labelStyle = "input-label-Third"
                                inputStyle = "full-width"
                            />
                            <div>
                                <div className="input-label-Third" >
                                    <span>Add tag</span><br/>
                                    <input type="text" name="tag" placeholder="Tag"  value={textEditTag}  onChange={changeEditTextTag} />
                                    <div  className="btn btn-commons" onClick={addEditTag}>Add</div>
                                </div>
                                {editTags.length>0 &&
                                    <div className="tags-content" >
                                    {editTags.map(tagNewUdapte=>{

                                        const prevTagKey = generateKey("tagItemEdit_"+tagNewUdapte);

                                        return( 
                                            <div key={prevTagKey} className="item"  >
                                                <span>{tagNewUdapte}</span>
                                                <div className="remove" onClick={()=>{removeEditTag(tagNewUdapte)}} >
                                                    x
                                                </div> 
                                            </div>
                                            )
                                    })}
                                    </div>
                                }
                                    
                                
                            </div>
                            
                            <button>Edit Shop</button>
                        </form>
                        <hr/>
                        <button onClick={closeEdit} >Cancel Shop</button>
                    </div>
                </div>
            }
        </div>
    )
}